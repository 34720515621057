
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      dyList: function () {
        var arr = [];
        for (var i = 0; i < 10; i++) arr.push({});
        return arr;
      }()
    };
  },
  methods: {
    tagTap(idx) {
      var self = this;
      var app = self.$app;
      var tInner = $$(".roolMenuDom .toolbar-inner");
      var aInner = tInner.children("a");
      var a = aInner[idx];
      var inner = tInner[0];
      $$(".roolMenuDom .toolbar-inner>a.active").removeClass("active");
      $$(a).addClass("active");
      var bodyWidth = inner.clientWidth;
      var left = a.getBoundingClientRect().left + a.offsetWidth / 2;
      if (left > bodyWidth / 2) {
        var cValue = left - bodyWidth / 2 + inner.scrollLeft;
        tInner.scrollTo(cValue, 0, 200);
      } else {
        var cValue = inner.scrollLeft - (bodyWidth / 2 - left);
        if (cValue < 0) cValue = 0;
        tInner.scrollTo(cValue, 0, 200);
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.loadAreaData(function () {
        var ldList = $.appData.areaData.take("name", self.$route.params.stage).child;
        self.$setState({
          dyList: ldList.take("name", self.$route.params.room).child
        }, function () {
          app.tab.show("#room-tab-1");
        });
      });
      $.waitObj($, "user", function () {
        $.initData(["room", "user"], function () {
          $.waitObj(self, "dyList", function () {
            for (var i = 0; i < self.dyList.length; i++) {
              var lcList = self.dyList[i].child;
              for (var j = 0; j < lcList.length; j++) {
                var rList = lcList[j].child;
                for (var k = 0; k < rList.length; k++) {
                  var room = rList[k];
                  var roomArr = $.dataFind($.appData.roomData, {
                    "community": self.$route.params.stage
                  });
                  var userRoom = roomArr.take('roomno', room.name);
                  if (!!userRoom) {
                    room.userId = userRoom.uid;
                  }
                }
              }
            }
            var tm = window.setTimeout(function () {
              clearTimeout(tm);
              self.$setState({
                dyList: self.dyList
              });
            }, 500);
          });
        });
      });
    }
  },
  id: '29aaf6dbdf',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n	<div class="page">\r\n		<style>\r\n			.rowDiv {\r\n				justify-content: flex-start;\r\n				flex-wrap: wrap;\r\n				align-items: center;\r\n			}\r\n\r\n			.rowCenter {\r\n				min-width: 20%;\r\n				max-width: 20%;\r\n				text-align: center;\r\n				height: 100%;\r\n				display: block;\r\n				font-size: 20px;\r\n			}\r\n\r\n			.hasUser {\r\n				color: #2196f3;\r\n			}\r\n		</style>\r\n		<div class="navbar">\r\n			<div class="navbar-bg"></div>\r\n			<div class="navbar-inner sliding">\r\n				<div class="left">\r\n					<a href="#" class="link back">\r\n						<i class="icon icon-back"></i>\r\n					</a>\r\n				</div>\r\n				<div class="title">';
      r += c(ctx_1.$route.params.stage, ctx_1);
      r += c(ctx_1.$route.params.room, ctx_1);
      r += '</div>\r\n			</div>\r\n		</div>\r\n\r\n		<div class="toolbar tabbar toolbar-top ';
      r += Template7Helpers.js.call(ctx_1, '@root.dyList.length>6?" tabbar-scrollable roolMenuDom":""', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\r\n			<div class="toolbar-inner">\r\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dyList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n					<a href="#room-tab-';
          r += c(data_2 && data_2.index + 1, ctx_2);
          r += '" @click="tagTap(';
          r += c(data_2 && data_2.index, ctx_2);
          r += ')" class="tab-link">';
          r += c(ctx_2.name, ctx_2);
          r += '</a>\r\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n			</div>\r\n		</div>\r\n		<div class="tabs-animated-wrap">\r\n			<div class="tabs">\r\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dyList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n					<div id="room-tab-';
          r += c(data_2 && data_2.index + 1, ctx_2);
          r += '" class="page-content tab">\r\n						';
          r += Template7Helpers.each.call(ctx_2, ctx_2.child, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n							<div class="row rowDiv">\r\n								<div class="col-20 tablet-15 rowCenter">\r\n									';
              r += c(ctx_3.name, ctx_3);
              r += '\r\n								</div>\r\n								';
              r += Template7Helpers.each.call(ctx_3, ctx_3.child, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n									<a href="';
                  r += Template7Helpers.js.call(ctx_4, 'this.userId?"/userInfo/"+this.userId+"/":"/roomInfo/"+this.name+"/"', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" class="col-20 tablet-15 listIconDiv rowCenter ';
                  r += c(ctx_4.userId ? 'hasUser' : '', ctx_4);
                  r += '">\r\n										<div>\r\n											<i class="material-icons md-only">home</i>\r\n											<i class="f7-icons if-not-md">house</i>\r\n										</div>\r\n										<div class="listIconDivName">';
                  r += c(ctx_4.name.replace(/.+单元\d/, ''), ctx_4);
                  r += '</div>\r\n									</a>\r\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n			\r\n							</div>\r\n						';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n					</div>\r\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n			</div>\r\n		</div>\r\n		\r\n	</div>\r\n';
      return r;
    }(this);
  },
  style: `
			.rowDiv {
				justify-content: flex-start;
				flex-wrap: wrap;
				align-items: center;
			}

			.rowCenter {
				min-width: 20%;
				max-width: 20%;
				text-align: center;
				height: 100%;
				display: block;
				font-size: 20px;
			}

			.hasUser {
				color: #2196f3;
			}
		`,
  styleScoped: false
};
    