
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      userInfo: "",
      isLoding: false
    };
  },
  methods: {
    addProjectDataBtn(e) {
      var self = this;
      var app = self.$app;
      e.preventDefault();

      // self.$router.navigate(`/projectSuccess/`);
      // return;

      app.input.validateInputs($$("#addProjectForm")[0]);
      var isValid = true;
      $$("#addProjectForm input").each(function () {
        if (!$$(this)[0].validity.valid) {
          isValid = false;
          return;
        }
      });
      if (isValid) {
        self.$setState({
          isLoding: true
        });
        var info = app.form.convertToData("#addProjectForm");
        var postData = {};
        postData.data = JSON.stringify(info);
        postData.pid = self.$route.params.id;
        $.globalSignToken(function () {
          ($.globalBaseUrl + "/addGlobalFormData").globalP(function (json) {
            self.$setState({
              isLoding: false
            });
            if (json.code + "" == "1") {
              self.$router.navigate(`/projectSuccess/`);
            } else {
              $.toast(json.data);
            }
          }, postData);
        });
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.waitObj($, "user", function () {
        var userInfo = {};
        if ($.user.sex == "男") {
          userInfo.name = $.user.realname.substring(0, 1) + "先生";
        } else {
          userInfo.name = $.user.realname.substring(0, 1) + "女士";
        }
        userInfo.phone = $.user.phone;
        self.$setState({
          userInfo: userInfo
        });
      });
    }
  },
  id: 'a798c0d58c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page projectDetailsPage" data-name=projectDetails><style></style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>在线预约</div></div></div><div class=page-content><form id=addProjectForm><div class=block-title>请确认您的信息</div><div class="list inline-labels no-hairlines-md"><ul><li class="item-content item-input"><div class=item-media><i class="icon material-icons md-only">&#xe7fd;</i> <i class="icon f7-icons if-not-md">person_alt</i></div><div class=item-inner><div class="item-title item-label">您的称呼</div><div class=item-input-wrap><input name=user_name type=text placeholder=点击此处填写您的称呼 value=';
      r += c(ctx_1.userInfo.name, ctx_1);
      r += ' data-error-message=请填写正确的称呼 type=text required validate pattern=[\u4e00-\u9fa5]{2,5} data-vaildate-on-blur=true> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-media><i class="material-icons md-only">phone</i> <i class="f7-icons if-not-md">phone</i></div><div class=item-inner><div class="item-title item-label">联系电话</div><div class=item-input-wrap><input name=user_phone type=text placeholder=点击此处填写联系电话 value=';
      r += c(ctx_1.userInfo.phone, ctx_1);
      r += ' data-error-message=请填写正确的手机号 type=text required validate pattern=[0-9]{11} data-vaildate-on-blur=true> <span class=input-clear-button></span></div></div></li></ul></div><div class=block>&emsp;&emsp;本页面内所填信息将提交给商家，如您对所要求填写的信息合法性有质疑，请向平台方反馈。</div><div class="block no-hairlines">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoding, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <button class="button button-large button-fill"><span class="preloader color-white"></span></button> ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <button class="button button-large button-fill" @click=addProjectDataBtn>立即预约</button> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div>';
      return r;
    }(this);
  },
  style: `

		`,
  styleScoped: false
};
    