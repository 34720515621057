
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      isLoding: false
    };
  },
  methods: {
    userLogin() {
      var self = this;
      var app = self.$app;
      app.input.validateInputs($$("#userLoginForm")[0]);
      var isValid = true;
      $$("#userLoginForm input").each(function () {
        if (!$$(this)[0].validity.valid) {
          isValid = false;
          return;
        }
      });
      if (isValid) {
        self.$setState({
          isLoding: true
        });
        var info = app.form.convertToData("#userLoginForm");
        app.request.promise.post($.baseUrl + "/userLogin?_=" + new Date().getTime(), info).then(function (res) {
          if (+res.status == 200) {
            var json = JSON.parse(res.data);
            if (+json.code == 1 && json.data.length > 0) {
              var arr = json.data;
              $.user = arr[0];
              if (+$.user.state == 0) return $.alt("请等待管理员审核");
              if (+$.user.state == 999) return $.alt("您的账号已被业主举报封禁,如需实名认证申诉联系QQ826609913");
              app.toast.create({
                text: '欢迎回来!' + $.user.name,
                position: 'center',
                closeTimeout: 2000
              }).open();
              self.$router.back();
              addHTML("userInfo", $.user);
            } else {
              app.toast.create({
                text: '账号或密码错误!',
                position: 'center',
                closeTimeout: 2000
              }).open();
            }
          }
        }).finally(function () {
          self.$setState({
            isLoding: false
          });
        });
      }
    }
  },
  on: {
    pageAfterIn: function () {
      var self = this;
      getHTML("userInfo", function (userInfo) {
        if (!!userInfo) {
          $.user = userInfo;
          self.$router.back();
        }
      });
    },
    pageInit: function () {
      var self = this;
      getHTML("userInfo", function (userInfo) {
        if (!!userInfo) {
          $.user = userInfo;
          self.$router.back();
        }
      });
      $$("#userLoginForm input[type='password']").on("keydown", function (e) {
        if (e.keyCode == 13) {
          self.userLogin();
        }
      });
      try {
        self.$app.sheet.close();
      } catch (e) {}

      // console.log(111);
      // debugger;
      // window.setTimeout(function(){
      // 	app.toolbar.hide('.toolbar');
      // },0);
    }
  },
  id: '091d07dcde',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page login-screen-page"><div class=navbar><div class="navbar-inner sliding"><div class=left><a href=# class="link back" style="color: #999999;"><i class="icon icon-back"></i></a></div></div></div><!-- page-content has additional login-screen content --><div class="page-content login-screen-content"><div class=login-screen-title>业主之家</div><!-- Login form --><form id=userLoginForm><div class=list><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">手机号</div><div class=item-input-wrap><input data-error-message=请填写正确的手机号 type=text required validate pattern=[0-9]{11} data-vaildate-on-blur=true name=phone placeholder=点击此处填写手机号> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">密码</div><div class=item-input-wrap><input data-error-message=密码长度为4-30位 type=password required validate pattern=[0-9a-zA-Z.!@#$%^&*_\\-,]{4,30} data-vaildate-on-blur=true name=pwd placeholder=点击此处填写密码> <span class=input-clear-button></span></div></div></li></ul></div><div class=list><ul><li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoding, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="item-link list-button"><span class=preloader></span></a> ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# @click=userLogin class="item-link list-button">登录</a> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <a href=/newPhonePage/ class="item-link list-button color-red">新业主注册</a> <a href=/forgetPasswordPage/ class="item-link list-button color-gray">忘记密码?</a></li></ul><!-- <div class="block-footer" style="text-align: right;"><a href="#" class="color-blue">忘记密码?</a></div> --></div></form></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    