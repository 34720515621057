
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      activity: {
        cuser: 0,
        stime: "",
        etime: "",
        userarr: ""
      },
      showImgListObj: "",
      isJointly: false,
      joinUserList: [],
      stateDic: {
        "999": "已封禁",
        "1": "业主",
        "2": "准业主"
      },
      isOverdue: false
    };
  },
  methods: {
    loadInfo() {
      var self = this;
      var app = self.$app;
      $.initData(["user", "activity"], function () {
        var activity = $.appData.activityData.take("id", self.$route.params.id);
        var cuser = $.appData.userData.take("id", activity.cuser);
        $.createShare(activity.title, "社区活动", activity.img.split("|")[0], 1, activity.cuser);
        var proImgList = [];
        if (!!activity.img) {
          var objImgArr = activity.img.split("|");
          proImgList = objImgArr;
        }
        activity.ctime = activity.ctime.split(" ")[0];
        activity.proImgList = proImgList;
        var joinUserList = [];
        activity.userarr.split(',').forEach(function (id) {
          var u = $.appData.userData.take("id", id);
          var obj = Object.assign({
            stateName: self.stateDic[u.state]
          }, u);
          joinUserList.push(obj);
        });
        var cuTime = $.getTime();
        self.$setState({
          activity: activity,
          joinUserList: joinUserList,
          isJointly: activity.userarr.split(',').map(Number).indexOf(+$.user.id) > -1,
          isOverdue: $.timeBetween(activity.etime || cuTime, cuTime) < 0
        }, function () {
          $.setImg(".activityDetailsMainContent");
          $$(".activityDetailsListImgDiv>img").on("click", function () {
            var that = $$(this);
            var imgList = that.parent().children("img");
            var imgArr = [];
            function eachImgArr(i) {
              if (imgList.hasOwnProperty(i)) {
                $.loadLookImg($$(imgList[i]).attr("url"), function (path) {
                  imgArr.push(path);
                  eachImgArr(i + 1);
                });
              } else {
                self.showImgListObj = app.photoBrowser.create({
                  photos: imgArr,
                  theme: 'dark',
                  type: 'popup',
                  popupCloseLinkText: "关闭"
                });
                self.showImgListObj.activeIndex = +that.index();
                self.showImgListObj.open();
              }
            }
            eachImgArr(0);
          });
        });
      });
    },
    delActivityBtn() {
      var self = this;
      var app = self.$app;
      app.dialog.confirm("确定删除该活动吗?", function () {
        ($.baseUrl + "/deleteActivity?_=" + new Date().getTime()).p(function (json) {
          if (+json.code == 1) {
            $.appData.activityData.rem("id", self.activity.id);
            self.$router.back();
          } else {
            $.toast(json.data);
          }
        }, {
          id: self.activity.id
        });
      });
    },
    joinActivityBtn() {
      var self = this;
      var app = self.$app;
      ($.baseUrl + "/joinActivity?_=" + new Date().getTime()).p(function (json) {
        if (+json.code == 1) {
          var activity = $.appData.activityData.take("id", self.activity.id);
          if (!activity.userarr) activity.userarr = [];else activity.userarr = activity.userarr.split(",");
          if (activity.userarr.indexOf($.user.id) == -1) {
            activity.userarr.push($.user.id);
          }
          activity.userarr = activity.userarr.join(",");
          self.loadInfo();
        } else {
          $.toast(json.data);
        }
      }, {
        id: self.activity.id
      });
    },
    exitActivityBtn() {
      var self = this;
      var app = self.$app;
      ($.baseUrl + "/exitActivity?_=" + new Date().getTime()).p(function (json) {
        if (+json.code == 1) {
          var activity = $.appData.activityData.take("id", self.activity.id);
          if (!activity.userarr) activity.userarr = [];else activity.userarr = activity.userarr.split(",");
          if (activity.userarr.indexOf($.user.id) > -1) {
            activity.userarr.splice(activity.userarr.indexOf($.user.id), 1);
          }
          activity.userarr = activity.userarr.join(",");
          self.loadInfo();
        } else {
          $.toast(json.data);
        }
      }, {
        id: self.activity.id
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      // debugger;
      self.loadInfo();
    },
    pageAfterIn: function (custom, page) {
      var self = this;
      var app = self.$app;
    }
  },
  id: 'd9f86e92a6',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=activityList><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>活动详情</div><div class=right><a class="link icon-only headerShareBtn"><i class="icon f7-icons if-not-md">square_arrow_up</i> <i class="icon material-icons md-only">share</i></a></div></div></div><div class="page-content activityDetailsMainContent"><div class="card activityListCard"><div class=card-header><h2>';
      r += c(ctx_1.activity.title, ctx_1);
      r += '</h2></div><div class=card-content><p>';
      r += c(ctx_1.activity.content, ctx_1);
      r += '</p><div class=activityDetailsListImgDiv>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.activity.proImgList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <img class=activityListCardImg url=';
          r += c(ctx_2, ctx_2);
          r += '> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=card-footer><span>活动日期：';
      r += c(root.activity.stime || "无", ctx_1);
      r += '</span></div><div class=card-footer><span>截止日期：';
      r += c(root.activity.etime || "无", ctx_1);
      r += '</span></div></div><div class="card marginClear"><div class=card-header><div class=block-title>已参与业主：</div></div><div class=card-content><div class=joinListInfo><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.joinUserList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div><img url=';
          r += c(ctx_2.img, ctx_2);
          r += '> <span>';
          r += c(ctx_2.name, ctx_2);
          r += '</span> <label>';
          r += c(ctx_2.stateName, ctx_2);
          r += '</label></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class=card-footer></div></div><div class=block>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isJointly, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "@root.activity.cuser==$.user.id", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=padding-horizontal><a href=/editActivityPage/';
              r += c(root.activity.id, ctx_3);
              r += '/ class="button button-large button-fill color-orange">编辑</a></div>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=padding-horizontal><a class="button button-large button-fill color-red" @click=exitActivityBtn>退出</a></div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isOverdue, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isOverdue, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=padding-horizontal><a class="button button-large button-fill color-gray">活动已截止</a></div>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=padding-horizontal><a class="button button-large button-fill" @click=joinActivityBtn>参与</a></div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=block-title>';
      r += Template7Helpers.js_if.call(ctx_1, "@root.activity.cuser==$.user.id", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' 删除后无法恢复请谨慎操作 <a @click=delActivityBtn>删除</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },
  style: `
	.activityDetailsMainContent {
		background-color: #ececec;
		padding-bottom: 50px;
	}

	.activityListCard {
		margin-top: 0px;
		margin-left: 0px;
		margin-right: 0px;
		box-shadow: none;
		margin-top: 10px;
	}

	.activityListCard .card-header {
		display: block;
	}


	.activityListCard .card-content img {
		display: block;
		margin-bottom: 10px;
		border-radius: 5px;
		margin-left: 2.5%;
		box-shadow: 0px 0px 3px #c5c5c5;
	}


	.activityListCardImg {
		width: 30%;
		height: auto;
	}

	.activityListCard .card-content .activityDetailsListImgDiv {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 10px;
		padding-left: 0px;
		padding-right: 0px;
	}


	.activityListCard .card-content {
		padding-top: 10px;
		box-sizing: border-box;
	}

	.activityListCard .card-content>p,
	.activityListCard .card-content>div {
		width: 100%;
		text-align: left;
		box-sizing: border-box;
		margin: 0;
		padding-left: 15px;
		padding-right: 15px;
		white-space: initial;
		word-break: break-all;
	}

	.activityListCard .card-header h2 {
		text-align: center;
		margin: 0;
		margin-top: 20px;
	}

	.activityListCard .card-footer {}





	.activityDetailsMainContent .block-title {
		display: flex;
		justify-content: space-between;
	}
	
	.joinListInfo {
	  width: 92%;
	  height: auto;
	  background-color: #ffffff;
	  margin: 0 auto;
	  margin-top: 14px;
	}
	.joinListInfo ul {
	  width: 100%;
	  height: 100%;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  list-style: none;
	  margin: 0px;
	  padding: 0px;
	  justify-content: flex-start;
	  flex-wrap: wrap;
	}
	.joinListInfo ul li {
	  flex-grow: 1;
	  flex-shrink: 0;
	  width: 20%;
	  min-width: 20%;
	  max-width: 20%;
	}
	.joinListInfo ul li div {
	  width: 100%;
	  height: auto;
	  margin: 0 auto;
	}
	.joinListInfo ul li div img {
	  width: 13.3vw;
	  height: 13.3vw;
	  display: block;
	  margin: 0 auto;
	  border-radius: 50%;
	}
	.joinListInfo ul li div span {
	  width: 100%;
	  font-family: PingFangSC-Regular;
	  font-size: 13px;
	  color: #333333;
	  text-align: center;
	  display: block;
	  margin-top: 5px;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	  overflow: hidden;
	  font-weight: bold;
	}
	.joinListInfo ul li div label {
	  width: 100%;
	  font-family: PingFangSC-Medium;
	  font-size: 15px;
	  color: #333333;
	  display: block;
	  text-align: center;
	  margin-top: 3px;
	  margin-bottom: 5px;
	}
	
	.marginClear{
		margin-left: 0px;
		margin-right: 0px;
	}


	
`,
  styleScoped: false
};
    