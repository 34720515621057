import $$ from 'dom7';
// import html2canvas from '../static/js/html2canvas.min.js';
// import "../static/js/qrcode.min.js";
// const QRCode = require("../static/js/qrcode.min.js");
// debugger;
// var s1=document.createElement("script");
// s1.src="https://html.myshopvip.cn/html2canvas.min.js";
// document.body.appendChild(s1);
// var s1=document.createElement("script");
// s1.src="https://html.myshopvip.cn/qrcode.min.js";
// document.body.appendChild(s1);
// fetch("https://html.myshopvip.cn/html2canvas.min.js").then(function(res){
// 	return res.text();
// }).then(function(text){
// 	eval.call(window,text);
// });
// fetch("https://html.myshopvip.cn/qrcode.min.js").then(function(res){
// 	return res.text();
// }).then(function(text){
// 	eval.call(window,text);
// });


window.projectMark = "hdlt";
if (window.location.host.split(".").length == 3) {
	window.projectMark = window.location.host.split(".")[0];
	if(window.projectMark=="www")
		window.projectMark="hdlt"
}
if (window.location.hostname == "localhost") {
	window.projectMark = "localhost";
}
window.proInfoList = {
	"hdlt": {
		// "name": "恒大翡翠龙庭",
		// "ip": "localhost",
		// "pt": (window.location.protocol=="https:"?"8234":"1234"),
		// "soc": (window.location.protocol=="https:"?"8235":"1235"),
		// "floorInfo": "https://json.myshopvip.cn/json/data-hdlt.json",
		// "weightInfo": "json/weight-hdlt.json",
		// "fangNo": "0000011684",
		// "pos": "108.891719,34.287789"
		"name": "恒大翡翠龙庭",
		"ip": "yzzjapp.cn",
		"pt": (window.location.protocol=="https:"?"8234":"1234"),
		"soc": (window.location.protocol=="https:"?"8235":"1235"),
		"floorInfo": "https://json.myshopvip.cn/json/data-hdlt.json",
		"weightInfo": "https://json.myshopvip.cn/json/weight-hdlt.json",
		"fangNo": "0000011684",
		"pos": "108.891719,34.287789"
	},
	"dsgc": {
		"name": "恒大都市广场",
		"ip": "yzzjapp.cn",
		"pt": (window.location.protocol=="https:"?"8236":"1236"),
		"soc": (window.location.protocol=="https:"?"8237":"1237"),
		"floorInfo": "https://json.myshopvip.cn/json/data-dsgc.json",
		"weightInfo": "https://json.myshopvip.cn/json/weight-dsgc.json",
		"fangNo": "0000011684",
		"pos": "108.802363,34.311246"
	},
	"ldxlgg": {
		"name": "绿地新里公馆",
		"ip": "yzzjapp.cn",
		"pt": (window.location.protocol=="https:"?"9238":"2238"),
		"soc": (window.location.protocol=="https:"?"9239":"2239"),
		"floorInfo": "https://json.myshopvip.cn/json/data-ldxlgg.json",
		"weightInfo": "https://json.myshopvip.cn/json/weight-ldxlgg.json",
		"fangNo": "0000011684",
		"pos": "108.78795,34.26995"
	},
	"bgyfhc": {
		"name": "碧桂园凤凰城",
		"ip": "yzzjapp.cn",
		"pt": (window.location.protocol=="https:"?"8240":"2240"),
		"soc": (window.location.protocol=="https:"?"8241":"2241"),
		"floorInfo": "https://json.myshopvip.cn/json/data-bgyfhc.json",
		"weightInfo": "https://json.myshopvip.cn/json/weight-bgyfhc.json",
		"fangNo": "0000011684",
		"pos": "108.802363,34.311246"
	},
	
	"test": {
		"name": "绿地新里公馆",
		"ip": "192.168.149.217",
		"pt": (window.location.protocol=="https:"?"9238":"2238"),
		"soc": (window.location.protocol=="https:"?"9239":"2239"),
		"floorInfo": "https://json.myshopvip.cn/json/data-ldxlgg.json",
		"weightInfo": "https://json.myshopvip.cn/json/weight-ldxlgg.json",
		"fangNo": "0000011684",
		"pos": "108.802363,34.311246"
	},
	"localhost": {
		"name": "恒大翡翠龙庭",
		"ip": "localhost",
		"pt": (window.location.protocol=="https:"?"8234":"1234"),
		"soc": (window.location.protocol=="https:"?"8235":"1235"),
		"floorInfo": "https://json.myshopvip.cn/json/data-hdlt.json",
		"weightInfo": "json/weight-hdlt.json",
		"fangNo": "0000011684",
		"pos": "108.891719,34.287789"
		// "name": "绿地新里公馆",
		// "ip": "localhost",
		// "pt": (window.location.protocol=="https:"?"9238":"2238"),
		// "soc": (window.location.protocol=="https:"?"9239":"2239"),
		// "floorInfo": "https://json.myshopvip.cn/json/data-ldxlgg.json",
		// "weightInfo": "https://json.myshopvip.cn/json/weight-ldxlgg.json",
		// "fangNo": "0000011684",
		// "pos": "108.802363,34.311246"
	}
};
window.$ = {};
// $.user = {};
$.isYZ = false;
$.appData = {};
$.projectName = proInfoList[projectMark].name;
$.baseUrl = window.location.protocol + "//" + proInfoList[projectMark].ip + ":" + proInfoList[projectMark].pt;
$.isOnline = true;

$.toast=function(text){
	window.myApp.toast.create({
		text: text,
		position: 'center',
		closeTimeout: 2000,
	}).open()
}
$.alt=function(text){
	window.myApp.dialog.alert(text);
}

$.createShare=function(title,type,img,ctype,cuser){
	window.loadJs("https://html.myshopvip.cn/html2canvas.min.js");
	window.loadJs("https://html.myshopvip.cn/qrcode.min.js");
	$$(".headerShareBtn").off("click").on("click",function(){
		var action = window.myApp.actions.create({
		  grid: true,
		  buttons: [
		    [
		      {
		        text: '复制链接',
		        icon: `
				<i class="icon f7-icons if-not-md">link</i>
				<i class="icon material-icons md-only">link</i>
				`,
		      },
			  {
			    text: '收藏',
			    icon: `
				<i class="icon f7-icons">heart_circle_fill</i>
				`,
				onClick:function(){
					var newTitle=title;
					if(!!title&&title.length>15){
						newTitle=title.replace(/<[^>]+>/g,"");
						if(newTitle.length>15){
							newTitle=newTitle.substring(0, 15)+"...";
						}
					}
					var newImg=img;
					if(!!img&&img.indexOf("|")>0){
						newImg=img.split("|")[0];
					}
					($.baseUrl+"/addCollection").p(function(json){
						if(+json.code==1){
							$.toast("已收藏");
						}
					},{
						type:1,
						content:JSON.stringify({
							title:newTitle||"",
							type:type||"",
							img:newImg||"",
							ctype:ctype||"",
							cuser:cuser||"",
						}),
						link:window.location.hash,
						ctime:$.getTime()
					});
				}
			  },
			  {
			    text: '分享',
			    icon: `
					<i class="icon f7-icons">paperplane_fill</i>
				`,
				onClick:function(){
					var dialog = window.myApp.dialog.preloader('分享中...');
					var can=document.createElement("canvas");
					can.width=800;
					can.height=2000;
					var con=can.getContext("2d");
					var p1=new Promise(function(resolve){
						$.loadOneImg("https://static.myshopvip.cn/b46c2fdd554e6357da90483e16c7e014.jpg",function(bUrl){
							var img=new Image();
							img.src=bUrl;
							img.onload=function(){
								resolve(img);
							}
						});
					});
					var p2=new Promise(function(resolve){
						window.setTimeout(function(){
							var dom=document.querySelector(".page-current .screenCaptureArea");
							if(!dom)
								dom=document.querySelector(".page-current");
							var ph=610/(656/dom.offsetWidth);
							var windowHeight=dom.scrollHeight;
							html2canvas(dom,{
								allowTaint:true,
								windowHeight:ph,
								height:ph,
								ignoreElements:function(element){
									if( element.classList.contains( 'ptr-preloader' ) ) {
										return true;
									}
								}
							}).then(function(canvas){
								var imgDataURL=canvas.toDataURL();
								var img=new Image();
								img.src=imgDataURL;
								img.onload=function(){
									resolve(img);
								}
							});
						},1000);
					});
					var p3=new Promise(function(resolve){
						var div=document.createElement("div");
						var qrcode = new QRCode(div, {
							text: window.location.href,
							width: 540,
							height: 540,
							colorDark : "#000000",
							colorLight : "#ffffff",
							correctLevel : 2
						});
						var codeCanvas=div.children[0];
						var codeDataURL=codeCanvas.toDataURL();
						var img=new Image();
						img.src=codeDataURL;
						img.onload=function(){
							resolve(img);
						}
					});
					var p4=new Promise(function(resolve){
						$.loadOneImg("https://static.myshopvip.cn/94c0a24e0edc48babbe97aca2436eeb9.jpg",function(bUrl){
							var img=new Image();
							img.src=bUrl;
							img.onload=function(){
								resolve(img);
							}
						});
					});
					Promise.all([p1,p2,p3,p4]).then(function(imgList){
						con.drawImage(imgList[0],0,0,800,2000);
						con.drawImage(imgList[1],71,521,656,610);
						con.drawImage(imgList[2],126,1320,540,540);
						con.drawImage(imgList[3],340,1530,120,120);
						can.toBlob(function(b){
							dialog.close();
							if($.isWX){
								window.location.href=window.URL.createObjectURL(b);
								// window.savePicture(b);
							}
							window.sharePicture(b);
						});
					});
				}
			  },
		    ]
		  ]
		});
		action.open();
		$$(".actions-modal.actions-grid.modal-in .actions-button:nth-of-type(1)").addClass("actionCopyLink");
		document.querySelector(".actionCopyLink").addEventListener("click",function(e){
			$.copyText(".actionCopyLink", window.location.href, function() {
				$.toast("已复制");
			},e);
		},true);
	});
}

// debugger;
// getHTML("userInfo",function(userInfo){
// 	if(!!userInfo){
// 		$.user=userInfo;
// 	}else{
// 		self.$router.navigate('/login/');
// 	}
// });
window.fileVersion={
	
};

window.savePicture=function(blob){
	var url = window.URL.createObjectURL(blob);
	var a = document.createElement('a');
	a.href = url;
	a.download = "yzzj_"+(new Date().getTime())+".png";
	var e = document.createEvent('MouseEvents');
	e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
	a.dispatchEvent(e);
}

window.sharePicture=function(){
	$.toast("请在app内分享");
}
