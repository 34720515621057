
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      myPublishList: []
    };
  },
  methods: {
    initData() {
      var self = this;
      var app = self.$app;
      ($.baseUrl + "/loadMyProblem").g(function (json) {
        if (+json.code == 1) {
          var list = json.data;
          var arr = [];
          var year = new Date().getFullYear();
          list.each(function (obj) {
            obj.markTime = obj.ctime.replace(/-[\d: ]+$/, "").replace(/-0|-/, ".");
            if (!!obj.img && obj.img.indexOf("|") > 0) {
              obj.img = obj.img.split("|")[0];
            }
            var newTitle = obj.content;
            if (!!obj.content && obj.content.length > 15) {
              newTitle = obj.content.replace(/<[^>]+>/g, "");
              if (newTitle.length > 15) {
                newTitle = newTitle.substring(0, 15) + "...";
              }
            }
            obj.title = newTitle;
            var support = 0;
            if (!!obj.support) {
              support = obj.support.split(",").length;
            }
            obj.supportCount = support;
            obj.updateTime = $.formatBeforeTime(obj.utime);
            if (obj.markTime.indexOf(year) == 0) {
              obj.markTime = obj.markTime.replace(year + ".", "") + "月";
            }
            arr.push(obj);
          });
          var timeArr = $.distinctCol(arr, "markTime");
          var resultArr = [];
          for (var i = 0, len = timeArr.length; i < len; i++) {
            resultArr.push({
              time: timeArr[i],
              arr: $.dataFind(arr, {
                markTime: timeArr[i]
              })
            });
          }
          self.$setState({
            myPublishList: resultArr
          }, function () {
            $.setImg(".myPublishList");
          });
        }
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.initData();
    }
  },
  id: 'b8584d7f18',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=myPublishList><style>.myPublishList .item-text {\r\n				text-align: right;\r\n				height: 35px;\r\n				line-height: 35px;\r\n				display: flex;\r\n				justify-content: space-between;\r\n			}\r\n			.myPublishList .timeline-item-content{\r\n				width: 100%;\r\n			}\r\n			.myPublishList .item-text .item-userInfo {\r\n				font-size: 16px;\r\n				display: flex;\r\n				align-items: center;\r\n			}\r\n			.myPublishList .item-text .item-userInfo i{\r\n				font-size: 18px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>我发布的</div></div></div><div class="page-content myPublishList"><div class=timeline><!-- Timeline item --> ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.myPublishList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=timeline-item><div class=timeline-item-date>';
          r += c(ctx_2.time, ctx_2);
          r += '</div><div class=timeline-item-divider></div><div class=timeline-item-content><div class="list media-list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.arr, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href=/problemDetails/';
              r += c(ctx_3.id, ctx_3);
              r += '/';
              r += c(ctx_3.type, ctx_3);
              r += '/ class="item-link item-content">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.img, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-media><img url=';
                  r += c(ctx_4.img, ctx_4);
                  r += ' width=80></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<div class=item-inner><div class=item-title-row><div class=item-title>';
              r += c(ctx_3.title, ctx_3);
              r += '</div><div class=item-after></div></div><div class=item-subtitle><span class="badge color-blue">';
              r += c(ctx_3.type, ctx_3);
              r += '</span></div><div class=item-text><span class=item-userInfo><i class="material-icons md-only">thumb_up_alt</i> <i class="f7-icons if-not-md">hand_thumbsup</i> &nbsp;';
              r += c(ctx_3.supportCount, ctx_3);
              r += ' </span><span>';
              r += c(ctx_3.updateTime, ctx_3);
              r += '</span></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.myPublishList&&this.myPublishList.length==0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("暂无发帖"), ctx_2);
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `
			.myPublishList .item-text {
				text-align: right;
				height: 35px;
				line-height: 35px;
				display: flex;
				justify-content: space-between;
			}
			.myPublishList .timeline-item-content{
				width: 100%;
			}
			.myPublishList .item-text .item-userInfo {
				font-size: 16px;
				display: flex;
				align-items: center;
			}
			.myPublishList .item-text .item-userInfo i{
				font-size: 18px;
			}
		`,
  styleScoped: false
};
    