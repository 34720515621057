export default {
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
    }
  },
  id: '662a1a1c96',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=title>楼盘表</div><div class=right><a href=# class="link icon-only panel-open" data-panel=right><i class="icon f7-icons">menu</i></a></div></div></div><div class=page-content><div class="list links-list"><ul><li><a href=/room/ >Link 1</a></li><li><a href=#>Link 2</a></li><li><a href=#>Link 3</a></li><li><a href=#>Link 1</a></li><li><a href=#>Link 2</a></li><li><a href=#>Link 3</a></li><li><a href=#>Link 1</a></li><li><a href=#>Link 2</a></li><li><a href=#>Link 3</a></li><li><a href=#>Link 1</a></li><li><a href=#>Link 2</a></li></ul></div></div><div class="toolbar tabbar toolbar-bottom"><div class=toolbar-inner><a href=/ data-animate=false class=tab-link data-route-tab-id=tab1><i class="icon f7-icons">compass_fill</i> <span class=tabbar-label>主页</span> </a><a href=/form/ data-animate=false class="tab-link tab-link-active" data-route-tab-id=tab2><i class="icon f7-icons">building_2_fill</i> <span class=tabbar-label>楼盘表</span> </a><a href=/message/ data-animate=false class=tab-link data-route-tab-id=tab3><i class="icon f7-icons">bubble_left_fill</i> <span class=tabbar-label>消息</span> </a><a href=/settings/ data-animate=false class=tab-link data-route-tab-id=tab4><i class="icon f7-icons">person_alt</i> <span class=tabbar-label>我</span></a></div></div><div class="tabs tabs-routable"><div class="page-content tab" id=tab1></div><div class="page-content tab" id=tab2></div><div class="page-content tab" id=tab3></div><div class="page-content tab" id=tab4></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};