
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      userRoomInfo: {},
      selfObj: {
        id: 0,
        rid: 0
      },
      isEdit: false,
      editObj: {},
      stateDic: {
        "999": "已封禁",
        "1": "已认证",
        "2": "准业主"
      },
      stateColor: {
        "0": "",
        "1": "color-green",
        "2": "color-blue"
      }
    };
  },
  methods: {
    editMyInfo() {
      var self = this;
      if (+self.userRoomInfo.userId == +self.selfObj.id) {
        self.$setState({
          isEdit: true
        });
      }
    },
    saveMyInfo() {
      var self = this;
      var app = self.$app;
      app.preloader.show();
      if (!self.editObj.name && !self.editObj.img) {
        app.preloader.hide();
        return self.$router.back();
      }
      var obj = {};
      obj.id = $.user.id;
      if (!!self.editObj.name) obj.name = self.editObj.name;
      if (!!self.editObj.img) {
        uploadFile(self.editObj.img, function (url) {
          obj.img = url;
          self.sendSaveAjax(obj);
        });
      } else {
        self.sendSaveAjax(obj);
      }
    },
    sendSaveAjax(obj) {
      var self = this;
      var app = self.$app;
      $.appData.userData.upd(obj, function (json) {
        app.preloader.hide();
        if (+json.code == 1) {
          if (!!obj.img) $.user.img = obj.img;
          if (!!obj.name) $.user.name = obj.name;
          addHTML("userInfo", $.user);
          $.refreshUserImg = true;
          $.toast("修改完成");
          self.$router.back();
        } else {
          $.toast(json.data);
        }
      });
    },
    fileImgCheck(e) {
      var self = this;
      var input = e.currentTarget;
      var files = input.files;
      if (files.length > 0) {
        var file = files[0];
        $.imgRepairIOS(file, function (base64) {
          var userInfoCutImgUrl = window.URL.createObjectURL(base64);
          input.value = "";
          sessionStorage.setItem("userInfoCutImgUrl", userInfoCutImgUrl);
          self.$router.navigate(`/cutImgPage/`);
        });
      }
    },
    hrefToSetNamePage() {
      var self = this;
      if (self.isEdit) {
        self.$router.navigate("/setNamePage/" + (self.editObj.name || self.userRoomInfo.name) + "/");
      }
    },
    openUserInfoMenu() {
      var self = this;
      var app = self.$app;
      var ac1 = app.actions.create({
        buttons: [[{
          text: '投诉',
          bold: true,
          onClick: function () {
            self.$router.navigate("/addComplaintPage/" + self.$route.params.id + "/");
          }
        }], [{
          text: '取消'
        }]]
      });
      ac1.open();
    },
    loadUserInfo(uInfo) {
      var self = this;
      var rInfo = $.appData.roomData.take("id", uInfo.rid);
      var info = {
        userId: uInfo.id
      };
      Object.assign(info, uInfo);
      Object.assign(info, rInfo);
      $.waitObj($.appData, "areaData", function () {
        $.findAreaObj($.appData.areaData, info.roomno, function (areaObj) {
          Object.assign(info, {
            inside: areaObj.inside,
            share: areaObj.Share,
            shareXS: parseFloat(parseFloat(areaObj.Share) / parseFloat(areaObj.size) * 100).toFixed(2) + "%",
            purpose: areaObj.purpose,
            type: areaObj.type
          });
          info.stateColor = self.stateColor[info.state];
          info.stateName = self.stateDic[info.state];
          info.notAdmin = true;
          if (info.pos == 100) {
            info.roomno = "系统管理员";
            info.notAdmin = false;
          }
          self.$setState({
            userRoomInfo: info
          }, function () {
            $.setImg(".userInfoImgDiv");
          });
        });
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      $.waitObj($, "user", function () {
        self.selfObj = $.user;
      });
      $.loadAreaData();
      $.initData(["user", "room"], function () {
        var uInfo = $.appData.userData.take("id", self.$route.params.id);
        if (!uInfo) {
          delete $.appData.userData;
          $.initData(["user"], function () {
            uInfo = $.appData.userData.take("id", self.$route.params.id);
            self.loadUserInfo(uInfo);
          });
        }
        self.loadUserInfo(uInfo);
      });
    },
    pageAfterIn() {
      var self = this;
      var userInfoCutImgUrlResult = sessionStorage.getItem("userInfoCutImgUrlResult");
      if (!!userInfoCutImgUrlResult) {
        sessionStorage.removeItem("userInfoCutImgUrl");
        sessionStorage.removeItem("userInfoCutImgUrlResult");
        $$(".userInfoImgDiv .userInfoImg").attr("src", userInfoCutImgUrlResult);
        self.editObj.img = userInfoCutImgUrlResult;
      }
      var setName = sessionStorage.getItem("setName");
      if (!!setName) {
        sessionStorage.removeItem("setName");
        $$(".setNameDiv").html(setName);
        self.editObj.name = setName;
      }
    }
  },
  id: '5f72dcbb21',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=userInfo><style lang=less scoped>.userInfoImg{\r\n				border-radius: 10px;\r\n				width: 100px;\r\n				height: 100px;\r\n			}\r\n			.userInfoImgInputDiv{\r\n				width: 100px;\r\n				height: 100px;\r\n				position: absolute;\r\n				left: 0px;\r\n				right: 0px;\r\n				top: 0px;\r\n				bottom: 0px;\r\n				margin: auto;\r\n			}\r\n			.userInfoImgInputDiv .userInfoImgSpan{\r\n				position: absolute;\r\n				left: 0px;\r\n				right: 0px;\r\n				width: 100px;\r\n				height: 30px;\r\n				margin: auto;\r\n				background-color: rgba(0,0,0,0.5);\r\n				line-height: 30px;\r\n				color: #FFFFFF;\r\n				bottom: 0px;\r\n				border-bottom-left-radius: 10px;\r\n				border-bottom-right-radius: 10px;\r\n			}\r\n			.userInfoImgInputDiv input{\r\n				width: 100%;\r\n				height: 100%;\r\n				position: absolute;\r\n				top: 0px;\r\n				left: 0px;\r\n				opacity: 0;\r\n				z-index: 2;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>业主信息</div>';
      r += Template7Helpers.js_if.call(ctx_1, "+this.userRoomInfo.userId==+this.selfObj.id", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=right>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isEdit, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=javascript:void(0) @click=saveMyInfo() class=link>保存 </a>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=javascript:void(0) @click=editMyInfo() class=link><i class="icon f7-icons">square_pencil</i> </a>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=right><a href=javascript:void(0) @click=openUserInfoMenu() class=link><i class="icon f7-icons">ellipsis</i></a></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=page-content><div class="block userInfoImgDiv"><div class="item-content text-align-center"><div class=item-media>';
      r += Template7Helpers.js_if.call(ctx_1, "!this.userRoomInfo.img", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="skeleton-block skeleton-effect-blink" style="width: 100px; height: 100px; border-radius: 10px;display: inline-block;"></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <img class=userInfoImg url=';
          r += c(ctx_2.userRoomInfo.img, ctx_2);
          r += '> ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.isEdit, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=userInfoImgInputDiv><span class=userInfoImgSpan>点此更换头像</span> <input @change=fileImgCheck type=file accept=image/*><!-- <i class="icon f7-icons">plus</i>\r\n									<input @change="fileImgCheck" type="file" accept="image/*" /> --></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><div class=block><div class=list><ul><li><div @click=hrefToSetNamePage class="item-content ';
      r += c(ctx_1.isEdit ? 'item-link' : '', ctx_1);
      r += '"><div class=item-inner><div class=item-title>昵称</div><div class="item-after setNameDiv">';
      r += c(ctx_1.userRoomInfo.name, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>小区</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.community, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>房号</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.roomno, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>状态</div><div class=item-after><span class="badge ';
      r += c(ctx_1.userRoomInfo.stateColor, ctx_1);
      r += '">';
      r += c(ctx_1.userRoomInfo.stateName, ctx_1);
      r += '</span></div></div></div></li></ul></div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.userRoomInfo.notAdmin, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>建筑面积</div><div class=item-after>';
          r += c(ctx_2.userRoomInfo.size, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>实用面积</div><div class=item-after>';
          r += c(ctx_2.userRoomInfo.inside, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>公摊面积</div><div class=item-after>';
          r += c(ctx_2.userRoomInfo.share, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>公摊比例</div><div class=item-after>';
          r += c(ctx_2.userRoomInfo.shareXS, ctx_2);
          r += '</div></div></div></li></ul></div></div><div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>房屋性质</div><div class=item-after>';
          r += c(ctx_2.userRoomInfo.purpose, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>户型</div><div class=item-after>';
          r += c(ctx_2.userRoomInfo.type, ctx_2);
          r += '</div></div></div></li></ul></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "this.selfObj.id>0&&+this.userRoomInfo.userId!=+this.selfObj.id", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="padding-horizontal padding-bottom"><a href=/sendMessage/';
          r += c(ctx_2.userRoomInfo.userId, ctx_2);
          r += '/ class="button button-large button-fill">发送消息</a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    