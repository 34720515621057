
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      isLoding: false
    };
  },
  methods: {
    forgetSetPwdBtn(e) {
      var self = this;
      var app = self.$app;
      e.preventDefault();
      app.input.validateInputs($$("#forgetSetPwdForm")[0]);
      var isValid = true;
      $$("#forgetSetPwdForm input").each(function () {
        if (!$$(this)[0].validity.valid) {
          isValid = false;
          return;
        }
      });
      if (isValid) {
        var info = app.form.convertToData("#forgetSetPwdForm");
        self.$setState({
          isLoding: true
        });
        ($.baseUrl + "/updUserPassword").p(function (json) {
          self.$setState({
            isLoding: false
          });
          if (!!json && json.code == "1") {
            $.user.pwd = info.password;
            addHTML("userInfo", $.user);
            self.$router.navigate("/setPwdSuccessPage/");
          }
        }, info);
      } else {
        $.toast("填写信息有误,请修改后重试");
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
    }
  },
  id: 'f4e414384d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=forgetSetPwd><style></style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>重设密码</div></div></div><div class=page-content><form id=forgetSetPwdForm><div class=block-title>请设置新的密码信息</div><div class="list inline-labels no-hairlines-md"><ul><li class="item-content item-input"><div class=item-media><i class="icon material-icons md-only">lock_open</i> <i class="icon f7-icons if-not-md">lock_open_fill</i></div><div class=item-inner><div class="item-title item-label">登录密码</div><div class=item-input-wrap><input name=password type=text placeholder=点此填写您的新密码 data-error-message=密码格式仅支持数字、字母及下划线 type=text required validate pattern=[0-9a-zA-Z.!@#$%^&*_\\-,]{4,30} data-vaildate-on-blur=true> <span class=input-clear-button></span></div></div></li></ul></div><div class="block no-hairlines">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoding, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <button class="button button-large button-fill"><span class="preloader color-white"></span></button> ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <button class="button button-large button-fill" @click=forgetSetPwdBtn>设置密码</button> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div>';
      return r;
    }(this);
  },
  style: `

		`,
  styleScoped: false
};
    