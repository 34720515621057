(function(){
	var saveJs = [];
	window.loadJs=function(jsPath){
		if(saveJs.indexOf(jsPath)<0){
			getHTML(jsPath,function(jsText){
				if(!!jsText){
					eval.call(window,jsText);
				}else{
					fetch(jsPath).then(function(res){
						return res.text();
					}).then(function(text){
						eval.call(window,text);
						addHTML(jsPath,text);
					});
				}
			});
		}
	}
})();