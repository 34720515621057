import $$ from 'dom7';
export default {
  data: function () {
    return {
      marker: ""
    };
  },
  methods: {
    getMarketPos(e) {
      var self = this;
      var p = self.marker.getPosition();
      var pos = p.lng + "," + p.lat;
      sessionStorage.setItem("setPos_startPos", pos);
      self.$router.navigate("/setEndPosPage/");
    }
  },
  on: {
    pageBeforeIn: function () {
      var self = this;
      var app = self.$app;
      var startPos = sessionStorage.getItem("setPos_startPos");
      window.createMap = function () {
        if (self.$route.name != self.$router.currentRoute.name) {
          return;
        }
        var map = new BMap.Map("carmapStart");
        var lng = proInfoList[projectMark].pos.split(",")[0];
        var lat = proInfoList[projectMark].pos.split(",")[1];
        if (!!startPos) {
          lng = startPos.split(",")[0];
          lat = startPos.split(",")[1];
        }
        var mPoint = new BMap.Point(lng, lat);
        map.enableScrollWheelZoom();
        map.centerAndZoom(mPoint, 18);
        self.marker = new BMap.Marker(mPoint);
        map.addOverlay(self.marker);
        self.marker.enableDragging();
        $.alt("请拖动地图中红点设置出发位置");
      };
      if (typeof BMap == "undefined") {
        var script = document.createElement("script");
        script.src = "https://api.map.baidu.com/api?v=2.0&ak=gVatyMtD0GseVHkt7kcNOLIx6qxeWwTS&callback=createMap";
        document.body.appendChild(script);
      } else {
        createMap();
      }
    }
  },
  id: 'ca65a67445',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page addCarpooling" data-name=addCarpooling><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>选择起点</div><div class=right><a @click=getMarketPos href=# class=link>下一步</a></div></div></div><div class=page-content><div id=carmapStart></div></div></div>';
      return r;
    }(this);
  },
  style: `
	#carmapStart{
		width:100%;
		height:100%;
	}
	#carmapStart .anchorBL{
		display: none;
	}
`,
  styleScoped: false
};