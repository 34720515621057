import Framework7 from 'framework7/framework7.esm.bundle.js';
import SparkMD5 from './spark-md5.js';
import $$ from 'dom7';
(function ($) {
	var c = "bai";
	$.initData = function (list, successFn) {
		var count = list.length;
		if (count == 0) return successFn();
		var index = 0;
		function isOk() {
			if (index == count) {
				if (!!successFn) successFn();
			}
		}
		for (var i in list) {
			if (list.hasOwnProperty(i)) {
				var _ret = function () {
					var tb = list[i];
					var tbList = tb.split("");
					tbList[0] = tbList[0].toUpperCase();
					var newTb = tbList.join("");
					if ($.appData.hasOwnProperty(tb + "Data")) {
						index++;
						isOk();
						return "continue";
					}
					($.baseUrl + "/get" + newTb + "?_=" + new Date().getTime()).g(function (json) {
						// filterError(json);
						if (json.code == "1") {
							var content = json.data;
							$.appData[tb + "Data"] = content;
							$.appData[tb + "Data"].add = function (obj, fn) {
								if (obj.hasOwnProperty("id")) delete obj["id"];
								($.baseUrl + "/add" + newTb + "?_=" + new Date().getTime()).p(function (json) {
									filterError(json);
									if((json.code+"")=="6"&&!!$.altAuto)
										return $.altAuto(json.data);
									if (!!fn) fn(json);
								}, obj);
							};
							$.appData[tb + "Data"].upd = function (obj, fn) {
								($.baseUrl + "/upd" + newTb + "?_=" + new Date().getTime()).p(function (json) {
									filterError(json);
									if((json.code+"")=="6"&&!!$.altAuto)
										return $.altAuto(json.data);
									if (!!fn) fn(json);
								}, obj);
							};
							$.appData[tb + "Data"].del = function (obj, fn) {
								($.baseUrl + "/del" + newTb + "?_=" + new Date().getTime()).p(function (json) {
									filterError(json);
									if (!!fn) fn(json);
								}, obj);
							};
							index++;
							isOk();
						}
					});
				}();

				if (_ret === "continue") continue;
			}
		}
	};
	function filterError(json) {
		if (+json.code == 0 && json.data == "notLogin") {
			// alert("账号信息已过期,请重新登录");
			$.user=null;
			deleteHTML("userInfo");
			window.setTimeout(function(){
				window.myApp.view.main.router.navigate('/login/');
			},100);
			// location.reload();
		}
	}
	$.createSignKey = function () {
		var spark = new SparkMD5();
		// spark.appendBinary($.user.id + $.user.phone + $.user.pwd + $.user.idcard + $.user.docno + $.user.pos + $.user.state + c);
		if(!!$.user)
			spark.appendBinary($.user.id + $.user.phone + $.user.pwd + $.user.pos + $.user.state + c);
		return spark.end();
	};
	var requestIdleCallback=window.requestIdleCallback||function(f){
		if(!!f){
			f({timeRemaining:function(){
				return 50;
			}});
		}
	}
	function delayedTask(fn){
		requestIdleCallback(function(dtm){
			if(dtm.timeRemaining()>40){
				if(!!fn)
					fn();
			}else{
				delayedTask(fn);
			}
		});
	}
	$.delayedTask=delayedTask;
	String.prototype.g = function (fn) {
		let _this=this;
		// $.waitObj($,"user",function(){
			Framework7.request({
				url:_this,
				method:"GET",
				headers:{
					"signKey":$.createSignKey(),
					"signId":$.user&&$.user.id
				},
				success:function(json){
					var result=JSON.parse(json);
					filterError(result);
					if (!!fn) fn(result);
					delayedTask(function(){
						addHTML(_this.replace(/([?]?_=\d+$)|(&?_=\d+&?)/,""),json);
					});
				},
				error:function(){
					if(myApp.online)
						$.toast("服务器异常,加载数据失败!");
					getHTML(_this.replace(/([?]?_=\d+$)|(&?_=\d+&?)/,""),function(jsonHtml){
						if(!!jsonHtml){
							if (!!fn) fn(JSON.parse(jsonHtml));
						}else{
							if(!myApp.online)
								$.toast("当前网络已断开,请检查网络!");
						}
					});
				}
			});
		// });
	};
	String.prototype.p = function (fn, data) {
		let _this=this;
		if(!$.user)
			window.myApp.view.main.router.navigate('/login/');
		$.waitObj($,"user",function(){
			Framework7.request({
				url:_this,
				method:"POST",
				data:!!data ? data : "",
				headers:{
					"signKey":$.createSignKey(),
					"signId":$.user.id
				},
				success:function(json){
					if (!!fn) fn(JSON.parse(json));
				},
				error:function(){
					if(myApp.online)
						$.toast("服务器异常,操作失败!");
					else
						$.toast("当前网络已断开,请检查网络!");
				}
			});
		});
	};
})(window.$);