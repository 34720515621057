
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      skeleton: true,
      showData: [],
      jointlyEmailList: [],
      pageSize: 1,
      pageDataArr: {}
    };
  },
  methods: {
    loadListInfo(fn) {
      var self = this;
      var app = self.$app;
      $.initData(["jointlyEmail"], function () {
        var arr = JSON.parse(JSON.stringify($.jsonSort($.dataFind($.appData.jointlyEmailData, {
          status: "0-350"
        }), "id", "desc")));
        for (var i = 0, len = arr.length; i < len; i++) {
          arr[i].ctime = $.formatBeforeTime(arr[i].ctime);
        }
        self.$setState({
          jointlyEmailList: arr,
          skeleton: false
        });
        if (!!fn) fn();
      });
    }
  },
  on: {
    pageAfterIn: function () {
      var self = this;
      self.loadListInfo();
      var $ptrContent = $$('.jointlyEmailListDiv');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.jointlyEmailData;
          self.loadListInfo(function () {
            $ptrContent[0].f7PullToRefresh.done();
          });
        }, 500);
      });
    }
  },
  id: '95cc556f16',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><style>.marginTopBottom0{\r\n				margin-top: 0px;\r\n				margin-bottom: 0px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>联名信</div><div class=right><a class="link icon-only searchbar-enable" data-searchbar=.popupSearchbar><i class="icon f7-icons if-not-md">search</i> <i class="icon material-icons md-only">search</i></a></div><form class="searchbar searchbar-expandable popupSearchbar searchbar-init" data-search-container=.jointlyEmailListDiv data-search-in=.item-title><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=快速查找> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">取消</span></div></form></div></div><div class="fab fab-extended fab-center-bottom"><a href=/addJointlyEmailPage/ ><i class="icon f7-icons">plus</i><div class=fab-text>发起联名信</div></a></div><div class="page-content infinite-scroll-content ptr-content jointlyEmailListDiv"><div class=ptr-preloader><div class="preloader color-multi"></div><div class=ptr-arrow></div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.skeleton, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list media-list chevron-center"><ul>';
          r += Template7Helpers.each.call(ctx_2, '1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href=# class="item-link item-content"><div class=item-inner><div class=item-title-row><div class=item-title><div style="width: 180px;\r\n											height: 100%; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div><div class=item-after><div style="width: 60px;\r\n											height: 24px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div></div><div class=item-subtitle><div style="width: 100px;\r\n											height: 24px; \r\n											display: inline-block;margin-top: 6px;" class="skeleton-block skeleton-effect-blink"></div></div><div class=item-text><div style="width: 240px;\r\n											height: 24px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "!!this.jointlyEmailList&&this.jointlyEmailList.length>0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="list media-list chevron-center"><ul>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.jointlyEmailList, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><a href=/jointlyEmailDetailsPage/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/ class="item-link item-content"><div class=item-inner><div class=item-title-row><div class=item-title>';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div><div class=item-after>';
                  r += c(ctx_4.ctime, ctx_4);
                  r += '</div></div><div class=item-subtitle>';
                  r += c(ctx_4.name, ctx_4);
                  r += '</div><div class=item-text>';
                  r += c(ctx_4.body, ctx_4);
                  r += '</div></div></a></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "!!this.jointlyEmailList&&this.jointlyEmailList.length==0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += c(ctx_3.$root.emptyTip("暂无联名信"), ctx_3);
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.showData.length>this.pageSize&&this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.showData.length>this.pageSize&&this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="preloader infinite-scroll-preloader">';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=baseline>没有更多了</div>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },
  style: `
			.marginTopBottom0{
				margin-top: 0px;
				margin-bottom: 0px;
			}
		`,
  styleScoped: false
};
    