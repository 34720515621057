// import initCallPhone from '../../static/js/callPhone.js';
import $$ from 'dom7';
export default {
  data: function () {
    return {};
  },
  methods: {
    toHome() {
      var self = this;
      self.$router.navigate(`/`);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      // initCallPhone();
    }
  },
  id: '783902afe2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page projectDetailsPage" data-name=projectDetails><style></style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>测试</div></div></div><div class=page-content>stunAll</div></div>';
      return r;
    }(this);
  },
  style: `
			
		`,
  styleScoped: false
};