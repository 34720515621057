
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      skeleton: true,
      projectList: [],
      pageDataArr: {},
      showData: [],
      pageNo: 1,
      pageSize: 10,
      allowInfinite: true,
      sortType: 1,
      searchText: ""
    };
  },
  methods: {
    searchSubmit(e) {
      var self = this;
      e.preventDefault();
      self.searchText = e.target.querySelector('input').value;
      self.renderData();
    },
    initData(e) {
      var self = this;
      var app = self.$app;
      $.globalInitData(["project", "projectReply"], function () {
        self.renderData && self.renderData();
      });
    },
    renderData(sType) {
      var self = this;
      var app = self.$app;
      self.projectList = [];
      self.pageNo = 1;
      if (!!sType) {
        self.$setState({
          sortType: sType
        });
        var tInner = $$(".projectListTypeContent");
        tInner.scrollTo(0, 0, 200);
      }
      var arr = $.dataFind($.appData.projectData, {
        type: self.$route.params.type,
        "state": "1"
      });
      var dic = {
        1: function () {
          arr = $.jsonSort(arr, "weight", "desc");
        },
        2: function () {
          arr = $.jsonSort(arr, "volume", "desc");
        },
        3: function () {
          arr.each(function (project) {
            project.replyCount = $.dataFind($.appData.projectReplyData, {
              pid: project.id
            }).length;
          });
          arr = $.jsonSort(arr, "replyCount", "desc");
        }
      };
      dic[self.sortType]();
      if (!!self.searchText) {
        arr = $.dataFind(arr, {
          name: "%" + self.searchText + "%"
        });
      }
      // arr=[];
      self.$setState({
        showData: arr
      });
      if (self.showData.length == 0) {
        self.$setState({
          projectList: [],
          skeleton: false
        });
        return;
      }
      self.pageDataArr = $.sliceArrListData(self.showData, self.pageSize);
      var newArr = self.pageDataArr[self.pageNo + ""];
      var projectList = self.projectList.concat(newArr);
      self.$setState({
        projectList: projectList,
        skeleton: false
      }, function () {
        $.setImg(".projectListTypeContent", null, null, true, null);
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      if (!$.appData.hasOwnProperty("projectData")) {
        var tm = window.setTimeout(function () {
          clearTimeout(tm);
          self.initData();
        }, 600);
      } else {
        self.renderData();
      }
      function clearSearch() {
        self.searchText = "";
        self.renderData();
      }
      $$(".projectTypeSearchbar").on("searchbar:clear", clearSearch).on("searchbar:disable", clearSearch);
      var $ptrContent = $$('.projectListTypeContent');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.projectData;
          delete $.appData.projectReplyData;
          $.globalInitData(["project"], function () {
            self.initData();
            $ptrContent[0].f7PullToRefresh.done();
          });
        }, 500);
      });
      $$('.projectListTypeContent').on('infinite', function () {
        if (!self.allowInfinite) return;
        self.allowInfinite = false;
        setTimeout(function () {
          self.allowInfinite = true;
          self.pageNo++;
          if (self.pageDataArr.hasOwnProperty(self.pageNo + "")) {
            var arr = self.pageDataArr[self.pageNo + ""];
            var projectList = self.projectList.concat(arr);
            self.$setState({
              projectList: projectList
            }, function () {
              $.setImg(".projectListTypeContent");
            });
          }
        }, 200);
      });
    }
  },
  id: '7c954b1535',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n	<div class="page projectMainPage" data-name="projectMain">\r\n		<style>\r\n			\r\n			.projectMainPage .media-list .item-media img{\r\n				border-radius: 10px;\r\n			}\r\n			\r\n			.projectListMainMenuBlock{\r\n				margin-top: 0px;\r\n				margin-bottom: 0px;\r\n				padding-top: 20px;\r\n				padding-bottom: 20px;\r\n				background-color: white;\r\n			}\r\n			.projectListTypeMenuDiv{\r\n				width: 100%;\r\n				position: relative;\r\n				position: -webkit-sticky;\r\n				position: sticky;\r\n				left: 0;\r\n				top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));\r\n				z-index: 999;\r\n			}\r\n			.projectListTypeMenuDiv .toolbar{\r\n				background-color: white;\r\n			}\r\n			.projectMainPage .list{\r\n				margin-top: 10px;\r\n				margin-bottom: 0px;\r\n			}\r\n			\r\n			.projectListTypeContent>.block{\r\n				margin-top: 0px;\r\n				padding-top: 0px;\r\n			}\r\n			\r\n			.projectListTypeContent .list ul{\r\n				background: initial;\r\n			}\r\n			\r\n		</style>\r\n		<div class="navbar">\r\n			<div class="navbar-bg"></div>\r\n			<div class="navbar-inner sliding">\r\n				<div class="left back">\r\n					<a href="#" class="link back">\r\n						<i class="icon icon-back"></i>\r\n					</a>\r\n				</div>\r\n				<div class="title">';
      r += c(ctx_1.$route.params.type, ctx_1);
      r += '</div>\r\n				<div class="right">\r\n					<a class="link icon-only searchbar-enable" data-searchbar=".projectTypeSearchbar">\r\n						<i class="icon f7-icons if-not-md">search</i>\r\n						<i class="icon material-icons md-only">search</i>\r\n					</a>\r\n				</div>\r\n				<form class="searchbar searchbar-expandable projectTypeSearchbar searchbar-init" @submit="searchSubmit">\r\n					<div class="searchbar-inner">\r\n						<div class="searchbar-input-wrap">\r\n							<input type="search" placeholder="搜索" />\r\n							<i class="searchbar-icon"></i>\r\n							<span class="input-clear-button"></span>\r\n						</div>\r\n						<span class="searchbar-disable-button">取消</span>\r\n					</div>\r\n				</form>\r\n			</div>\r\n		</div>\r\n		<div class="projectListTypeMenuDiv elevation-3">\r\n			<div class="toolbar tabbar">\r\n			  <div class="toolbar-inner">\r\n				<a @click="renderData(1)" class="link';
      r += Template7Helpers.js.call(ctx_1, 'this.sortType==1?" tab-link-active":""', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">综合</a>\r\n				<a @click="renderData(2)" class="link';
      r += Template7Helpers.js.call(ctx_1, 'this.sortType==2?" tab-link-active":""', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">销量</a>\r\n				<a @click="renderData(3)" class="link';
      r += Template7Helpers.js.call(ctx_1, 'this.sortType==3?" tab-link-active":""', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">评价数</a>\r\n			  </div>\r\n			</div>\r\n		</div>\r\n		<div class="page-content projectListTypeContent infinite-scroll-content ptr-content" data-ptr-distance="55" data-ptr-mousewheel="true">\r\n			<div class="ptr-preloader">\r\n				 <div class="preloader color-multi"></div>\r\n				<div class="ptr-arrow"></div>\r\n			</div>\r\n			<div class="block block-strong no-hairlines">\r\n				';
      r += Template7Helpers.if.call(ctx_1, ctx_1.skeleton, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n					<div class="list media-list no-hairlines" style="margin-bottom: 0px;">\r\n						<ul>\r\n							';
          r += Template7Helpers.each.call(ctx_2, '1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n								<li>\r\n									<a href="#" class="item-link item-content">\r\n										<div class="item-media">\r\n											<div style="width: 80px;\r\n									height: 80px; \r\n									display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n										</div>\r\n										<div class="item-inner">\r\n											<div class="item-title-row">\r\n												<div class="item-title">\r\n													<div style="width: 240px;\r\n											height: 24px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n												</div>\r\n												<div class="item-after"></div>\r\n											</div>\r\n											<div class="item-subtitle">\r\n												<div style="width: 180px;\r\n											height: 24px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n											</div>\r\n											<div class="item-text">\r\n												<div style="width: 280px;\r\n										height: 20px; \r\n										display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n											</div>\r\n										</div>\r\n									</a>\r\n								</li>\r\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n						</ul>\r\n					</div>\r\n				';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n					';
          r += Template7Helpers.js_if.call(ctx_2, "!!this.projectList&&this.showData.length==0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n						';
              r += c(ctx_3.$root.emptyTip("暂无相关服务"), ctx_3);
              r += '\r\n					';
              r += Template7Helpers.js_if.call(ctx_3, "!this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, "this.showData.length>this.pageSize&&this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n						<div class="list media-list no-hairlines">\r\n							<ul>\r\n								';
              r += Template7Helpers.each.call(ctx_3, ctx_3.projectList, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n									<li>\r\n										<a href="/projectDetails/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/" class="item-link item-content">\r\n											<div class="item-media"><img url="';
                  r += c(ctx_4.img.split('|')[0], ctx_4);
                  r += '" width="80" /></div>\r\n											<div class="item-inner">\r\n												<div class="item-title-row">\r\n													<div class="item-title">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</div>\r\n													<div class="item-after"></div>\r\n												</div>\r\n												<div class="item-subtitle">&yen;';
                  r += Template7Helpers.js.call(ctx_4, "!!this.price?this.price:'免费'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div>\r\n												<div class="item-text">';
                  r += c(ctx_4.introduce, ctx_4);
                  r += '</div>\r\n											</div>\r\n										</a>\r\n									</li>\r\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n							</ul>\r\n							';
              r += Template7Helpers.js_if.call(ctx_3, "!this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n								<div class="baseline">没有更多了</div>\r\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n						</div>\r\n						';
              r += Template7Helpers.js_if.call(ctx_3, "this.showData.length>this.pageSize&&this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n							<div class="preloader infinite-scroll-preloader">\r\n						';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n					';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n			</div>\r\n		</div>\r\n	</div>\r\n';
      return r;
    }(this);
  },
  style: `
			
			.projectMainPage .media-list .item-media img{
				border-radius: 10px;
			}
			
			.projectListMainMenuBlock{
				margin-top: 0px;
				margin-bottom: 0px;
				padding-top: 20px;
				padding-bottom: 20px;
				background-color: white;
			}
			.projectListTypeMenuDiv{
				width: 100%;
				position: relative;
				position: -webkit-sticky;
				position: sticky;
				left: 0;
				top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
				z-index: 999;
			}
			.projectListTypeMenuDiv .toolbar{
				background-color: white;
			}
			.projectMainPage .list{
				margin-top: 10px;
				margin-bottom: 0px;
			}
			
			.projectListTypeContent>.block{
				margin-top: 0px;
				padding-top: 0px;
			}
			
			.projectListTypeContent .list ul{
				background: initial;
			}
			
		`,
  styleScoped: false
};
    