export default {
  data: function () {
    return {
      userRoomInfo: {}
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      $.loadAreaData(function () {
        $.findAreaObj($.appData.areaData, self.$route.params.id, function (areaObj) {
          var info = {};
          Object.assign(info, {
            share: areaObj.Share,
            shareXS: parseFloat(parseFloat(areaObj.Share) / parseFloat(areaObj.size) * 100).toFixed(2) + "%"
          });
          Object.assign(info, areaObj);
          self.$setState({
            userRoomInfo: info
          });
        });
      });
    }
  },
  id: '3e0889f355',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=userInfo><style lang=less scoped>.userInfoImg{\r\n				border-radius: 10px;\r\n				width: 100px;\r\n				height: 100px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>房屋信息</div></div></div><div class=page-content><div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>房号</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.name, ctx_1);
      r += '</div></div></div></li></ul></div></div><div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>建筑面积</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.size, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>实用面积</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.inside, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>公摊面积</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.share, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>公摊比例</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.shareXS, ctx_1);
      r += '</div></div></div></li></ul></div></div><div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>房屋性质</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.purpose, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>户型</div><div class=item-after>';
      r += c(ctx_1.userRoomInfo.type, ctx_1);
      r += '</div></div></div></li></ul></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};