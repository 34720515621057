
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      projectList: [],
      menuList: [{
        id: 0,
        name: "全部",
        num: 0
      }, {
        id: 1,
        name: "已预约",
        num: 0
      }, {
        id: 100,
        name: "已接单",
        num: 0
      }, {
        id: 500,
        name: "待评价",
        num: 0
      }, {
        id: 550,
        name: "已评价",
        num: 0
      }, {
        id: 50,
        name: "已取消",
        num: 0
      }],
      showStateType: 0
    };
  },
  methods: {
    initData() {
      var self = this;
      var app = self.$app;
      $.globalSignToken(function () {
        $.globalInitData(["formData", "project"], function () {
          var stateDic = {
            "1": "已预约",
            "50": "已取消",
            "75": "已删除",
            "100": "已接单",
            "200": "已确认",
            "300": "已发货",
            "400": "已收货",
            "500": "待评价",
            "550": "已评价",
            "600": "已结单",
            "700": "已关闭"
          };
          var stateColor = {
            "1": "color-blue",
            "50": "",
            "100": "color-orange",
            "500": "color-red",
            "550": "color-green"
          };
          var arr = [];
          $.appData.formDataData.each(function (fObj) {
            var obj = {};
            var pro = $.appData.projectData.take("id", fObj.pid);
            obj.fid = fObj.id;
            obj.pName = pro.name;
            obj.price = pro.price == 0 ? '免费' : "&yen;" + pro.price;
            obj.stateName = stateDic[fObj.state];
            obj.stateColor = stateColor[fObj.state];
            obj.state = fObj.state;
            obj.pImg = pro.img.split("|")[0];
            obj.time = $.formatBeforeTime(fObj.ctime);
            if (self.showStateType > 0) {
              if (+obj.state != +self.showStateType) return;
            }
            arr.push(obj);
          });
          self.menuList.each(function (obj) {
            if (+obj.id > 0) obj.num = $.dataFind($.appData.formDataData, {
              state: obj.id
            }).length;else obj.num = $.appData.formDataData.length;
          });
          self.$setState({
            projectList: arr
          }, function () {
            $.setImg(".myProjectList", null, null, true);
          });
        });
      });
    },
    tagTap(e) {
      var self = this;
      var app = self.$app;
      var a = e.currentTarget;
      var tInner = $$(a).parent();
      var inner = tInner[0];
      $$(".roolMenuDom .toolbar-inner>a.active").removeClass("active");
      $$(a).addClass("active");
      var bodyWidth = inner.clientWidth;
      var left = a.getBoundingClientRect().left + a.offsetWidth / 2;
      if (left > bodyWidth / 2) {
        var cValue = left - bodyWidth / 2 + inner.scrollLeft;
        tInner.scrollTo(cValue, 0, 200);
      } else {
        var cValue = inner.scrollLeft - (bodyWidth / 2 - left);
        if (cValue < 0) cValue = 0;
        tInner.scrollTo(cValue, 0, 200);
      }
      self.showStateType = $$(a).attr("val");
      self.initData();
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      $$(".roolMenuDom .toolbar-inner>a:nth-of-type(1)").addClass("active");
      if (!!$.appData) {
        delete $.appData.formDataData;
        delete $.appData.projectData;
      }
      self.initData();
      var $ptrContent = $$('.myProjectPageContent');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.formDataData;
          delete $.appData.projectData;
          self.initData();
          $ptrContent[0].f7PullToRefresh.done();
        }, 500);
      });
    },
    pageBeforeIn: function () {
      var self = this;
      var app = self.$app;
      var refreshPage = sessionStorage.getItem("refreshPage");
      if (!!refreshPage && +refreshPage == 1) {
        sessionStorage.removeItem("refreshPage");
        self.initData();
      }
    }
  },
  id: '9ca9c8247c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page myProjectPage" data-name=myProject><style>.myProjectList .item-text {\r\n				text-align: right;\r\n				height: 35px;\r\n				line-height: 35px;\r\n				display: flex;\r\n				justify-content: space-between;\r\n			}\r\n\r\n			.myProjectList .item-text .item-price {\r\n				font-size: 16px;\r\n			}\r\n			.myProjectPage .toolbar-inner a.active{\r\n				color: #2196F3;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>我的预约</div></div></div><div class="toolbar tabbar toolbar-top tabbar-scrollable roolMenuDom"><div class=toolbar-inner>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.menuList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# @click=tagTap val=';
          r += c(ctx_2.id, ctx_2);
          r += ' class=link>';
          r += c(ctx_2.name, ctx_2);
          r += '(';
          r += c(ctx_2.num, ctx_2);
          r += ')</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class="page-content myProjectPageContent ptr-content"><div class=ptr-preloader><div class="preloader color-multi"></div><div class=ptr-arrow></div></div><div class="list media-list myProjectList">';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.projectList&&this.projectList.length>0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.projectList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href=/myProjectDetailsPage/';
              r += c(ctx_3.fid, ctx_3);
              r += '/ class="item-link item-content"><div class=item-media><img url=';
              r += c(ctx_3.pImg, ctx_3);
              r += ' width=80></div><div class=item-inner><div class=item-title-row><div class=item-title>';
              r += c(ctx_3.pName, ctx_3);
              r += '</div><div class=item-after><span class="badge ';
              r += c(ctx_3.stateColor, ctx_3);
              r += '">';
              r += c(ctx_3.stateName, ctx_3);
              r += '</span></div></div><div class=item-subtitle>&nbsp;</div><div class=item-text><span class=item-price>';
              r += c(ctx_3.price, ctx_3);
              r += ' </span><span>';
              r += c(ctx_3.time, ctx_3);
              r += '</span></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.projectList&&this.projectList.length==0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("暂无预约"), ctx_2);
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `
			.myProjectList .item-text {
				text-align: right;
				height: 35px;
				line-height: 35px;
				display: flex;
				justify-content: space-between;
			}

			.myProjectList .item-text .item-price {
				font-size: 16px;
			}
			.myProjectPage .toolbar-inner a.active{
				color: #2196F3;
			}
		`,
  styleScoped: false
};
    