(function(){
	function sendGetAjax(url,fn){
		fetch(url).then(res => {
			return res.json();
		}).then(res => {
			if(!!fn)
				fn(res);
		})
	}
	$.loadAreaData=function(fn){
		if(!!$.appData.areaData){
			if(!!fn)
				fn();
		}else{
			getHTML(proInfoList[projectMark].floorInfo,function(areaData){
				if(!!areaData){
					$.appData.areaData=JSON.parse(areaData);
					if(!!fn)
						fn();
				}else{
					sendGetAjax(proInfoList[projectMark].floorInfo,function(json){
						$.appData.areaData=json;
						addHTML(proInfoList[projectMark].floorInfo,JSON.stringify($.appData.areaData));
						if(!!fn)
							fn();
					});
				}
			});
		}
	}
	$.loadWeightJsonData=function(fn){
		if(!!$.weightJsonData){
			if(!!fn)
				fn();
		}else{
			getHTML(proInfoList[projectMark].weightInfo,function(weightJsonData){
				if(!!weightJsonData){
					$.weightJsonData=JSON.parse(weightJsonData);
					if(!!fn)
						fn();
				}else{
					sendGetAjax(proInfoList[projectMark].weightInfo,function(json){
						$.weightJsonData=json;
						addHTML(proInfoList[projectMark].weightInfo,JSON.stringify($.weightJsonData));
						if(!!fn)
							fn();
					});
				}
			});
		}
	}
	
	$.findAreaObj=function(arr,name,fn){
		for(var i in arr){
			if(arr.hasOwnProperty(i)){
				if(arr[i].name==name)
					fn(arr[i]);
				else
					if(!!arr[i].child)
						$.findAreaObj(arr[i].child,name,fn);
			}
		}
	}
})();