
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      dyList: []
    };
  },
  methods: {
    openRoonInfo(roomno) {
      var self = this;
      self.$router.navigate('/userInfo/' + roomno + '/');
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.loadAreaData(function () {
        var ldList = [];
        $.appData.areaData.each(function (obj) {
          for (var i in obj.child) {
            if (obj.child.hasOwnProperty(i)) {
              var ldObj = obj.child[i];
              ldObj.ld = ldObj.name.replace("幢", "");
              ldList.push(ldObj);
            }
          }
        });
        ldList = $.jsonSort(ldList, "ld", "asc");
        self.$setState({
          dyList: ldList.take("name", self.$route.params.id).child
        });
      });
      $.initData(["room", "user"], function () {
        $.waitObj(self, "dyList", function () {
          for (var i = 0; i < self.dyList.length; i++) {
            var lcList = self.dyList[i].child;
            for (var j = 0; j < lcList.length; j++) {
              var rList = lcList[j].child;
              for (var k = 0; k < rList.length; k++) {
                var room = rList[k];
                var userRoom = $.appData.roomData.take('roomno', room.name);
                if (!!userRoom) {
                  room.userId = userRoom.uid;
                }
              }
            }
          }
          var tm = window.setTimeout(function () {
            clearTimeout(tm);
            self.$setState({
              dyList: self.dyList
            });
          }, 500);
        });
      });
    }
  },
  id: '4183e2b9b4',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n	<div class="page" data-name="room">\r\n		<style lang="less" scoped>\r\n			.roomCardHeight {\r\n				height: 150px;\r\n			}\r\n\r\n			.rowDiv {\r\n				justify-content: flex-start;\r\n				flex-wrap: wrap;\r\n				align-items: center;\r\n			}\r\n\r\n			.rowCenter {\r\n				min-width: 20%;\r\n				max-width: 20%;\r\n				text-align: center;\r\n				height: 100%;\r\n				display: block;\r\n				font-size: 20px;\r\n			}\r\n			.hasUser{\r\n				color: #2196f3;\r\n			}\r\n		</style>\r\n		<div class="navbar">\r\n			<div class="navbar-bg"></div>\r\n			<div class="navbar-inner sliding">\r\n				<div class="left">\r\n					<a href="#" class="link back">\r\n						<i class="icon icon-back"></i>\r\n					</a>\r\n				</div>\r\n				<div class="title">';
      r += c(ctx_1.$route.params.id, ctx_1);
      r += '</div>\r\n			</div>\r\n		</div>\r\n		<div class="page-content">\r\n			';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dyList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n				<div class="card card-expandable roomCardHeight" data-hide-statusbar-on-open="false">\r\n					<div class="card-content">\r\n						<div class="bg-color-blue" style="height: 150px">\r\n							<div class="card-header text-color-white display-block">\r\n								';
          r += c(ctx_2.name, ctx_2);
          r += '\r\n								<br>\r\n								<small style="opacity: 0.7">';
          r += c(root.$route.params.id, ctx_2);
          r += c(ctx_2.name, ctx_2);
          r += '共计';
          r += c(ctx_2.child.length, ctx_2);
          r += '层</small>\r\n							</div>\r\n							<a href="#" class="link card-close card-opened-fade-in color-white" style="position: absolute; right: 15px; top: 15px">\r\n								<i class="f7-icons">multiply_circle_fill</i>\r\n							</a>\r\n						</div>\r\n						<div class="card-content-padding">\r\n							<div class="block block-strong tabs">\r\n								';
          r += Template7Helpers.each.call(ctx_2, ctx_2.child, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n									<div class="row rowDiv">\r\n										<div class="col-20 tablet-15 rowCenter">\r\n											';
              r += c(ctx_3.name, ctx_3);
              r += '\r\n										</div>\r\n										';
              r += Template7Helpers.each.call(ctx_3, ctx_3.child, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n											<a href="';
                  r += Template7Helpers.js.call(ctx_4, 'this.userId?"/userInfo/"+this.userId+"/":"/roomInfo/"+this.name+"/"', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" class="col-20 tablet-15 listIconDiv rowCenter ';
                  r += c(ctx_4.userId ? 'hasUser' : '', ctx_4);
                  r += '">\r\n												<div>\r\n													<i class="material-icons md-only">home</i>\r\n													<i class="f7-icons if-not-md">house</i>\r\n												</div>\r\n												<div class="listIconDivName">';
                  r += c(ctx_4.name.replace(/.+单元\d/, ''), ctx_4);
                  r += '</div>\r\n											</a>\r\n										';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n\r\n									</div>\r\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n							</div>\r\n						</div>\r\n					</div>\r\n				</div>\r\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n\r\n\r\n		</div>\r\n		\r\n	</div>\r\n';
      return r;
    }(this);
  },
  style: `


`,
  styleScoped: false
};
    