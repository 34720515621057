
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      checkImgList: [],
      showImgListObj: "",
      typeList: []
    };
  },
  methods: {
    fileImgCheck(e) {
      var self = this;
      var input = e.currentTarget;
      $.fileImgAdd(input, self.checkImgList);
    },
    addImgBtn() {
      var self = this;
      var app = self.$app;
      var formObj = app.form.convertToData("#addImgForm");
      var checkType = formObj.type;
      var subTime = $.getTime();
      if (self.checkImgList.length == 0) return $.toast("请至少上传一张图");
      $.uploadImgList(self.checkImgList, function (list) {
        $.toast("上传完成");
        $.addImg_state = true;
        self.$router.back();
      }, function (url) {
        var obj = {};
        obj.time = subTime;
        obj.url = url;
        obj.uid = $.user.id;
        obj.type = checkType;
        $.appData.abodeImgData.add(obj);
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.initData(["abodeImg"], function () {
        var typeList = $.distinctCol($.appData.abodeImgData, "type");
        typeList = typeList.sort(function (a, b) {
          var a = parseInt(a);
          a = isNaN(a) ? 0 : a;
          var b = parseInt(b);
          b = isNaN(b) ? 0 : b;
          return a - b;
        });
        self.$setState({
          typeList: typeList
        }, function () {
          $$(".addImgList>ul>li:nth-of-type(1) input").attr("checked", "true");
        });
      });
    }
  },
  id: '9f88edd3df',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><style>.addImgList>ul>li{\r\n				width: 49.2%;\r\n				display: inline-block;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>照片上传</div></div></div><div class=page-content><div class=block-title>请选择要上传到的相册</div><form id=addImgForm><div class="list addImgList"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.typeList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-radio item-content"><input type=radio name=type value=';
          r += c(ctx_2, ctx_2);
          r += '> <i class="icon icon-radio"></i><div class=item-inner><div class=item-title>';
          r += c(ctx_2, ctx_2);
          r += '</div></div></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></form><div class=block style="width: 100%;"><div class=row><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo</i> <i class="f7-icons if-not-md">photo_fill</i> <input class=uploadBtn @change=fileImgCheck type=file multiple=multiple type=file accept=image/*></div></div><div class=sheetIconLabel>相册</div></div><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo_camera</i> <i class="f7-icons if-not-md">camera_fill</i> <input class=uploadBtn @change=fileImgCheck type=file capture=camera type=file accept=image/*></div></div><div class=sheetIconLabel>拍照</div></div><div class=col-25></div></div></div><div class="block block-strong checkImgListDiv" style="display: none;"><div class="list checkImgList media-list sortable sortable-enabled"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.checkImgList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class="padding-horizontal padding-bottom"><a class="button button-large button-fill" @click=addImgBtn>上传</a></div></div></div>';
      return r;
    }(this);
  },
  style: `
			
			.addImgList>ul>li{
				width: 49.2%;
				display: inline-block;
			}
			    
		`,
  styleScoped: false
};
    