import $$ from 'dom7';
export default {
  data: function () {
    return {};
  },
  methods: {
    joinRoom() {
      var self = this;
      var app = self.$app;
      var wxid = self.$route.params.sign;
      if (!!wxid) {
        if (!!$.user) {
          ($.baseUrl + "/bindWX?wxid=" + wxid).g(function (json) {
            if (json.code != 1) {
              $.toast(json.data);
            } else {
              self.$router.navigate(`/joinWXRoomSuccessPage/`);
            }
          });
        } else {
          $.toast("请先登录后申请进群");
          self.$router.navigate(`/login/`);
        }
      } else {
        $.alt("链接已失效,请重新联系小白微信 baiyang2457,回复3获取新加群链接!");
      }
    },
    toBack() {
      var self = this;
      self.$router.back();
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.setImg(".joinWXRoomDiv");
    }
  },
  id: '652d78d3b2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page projectDetailsPage" data-name=projectDetails><!-- 		<div class="navbar">\r\n			<div class="navbar-bg"></div>\r\n			<div class="navbar-inner sliding">\r\n				<div class="title">申请进群</div>\r\n			</div>\r\n		</div> --><div class=page-content><span class=closeJoin @click=toBack>×</span><div class=joinWXRoomDiv><img url=https://static.myshopvip.cn/0821dc34fffb7233acaaa0a3a7e6a866.jpg> <label>恒大翡翠龙庭认证业主群(493)</label><div class=ruleInfo><p>1、该群聊人数较多，为减少新消息给您带来打扰，建议谨慎加入。</p><p>2、该群聊需实名认证后才能申请加入。</p><p>3、为维护平台绿色网络环境,请勿在群内传播违法违规内容。</p></div><button class="joinBtn no-ripple" @click=joinRoom>申请加入</button></div></div></div>';
      return r;
    }(this);
  },
  style: `
	.joinWXRoomDiv{
		width: 80%;
		height: auto;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		padding-top: 100px;
	}
	.joinWXRoomDiv>img{
		width: 70px;
		height: 70px;
		display: block;
		margin: 0 auto;
	}
	.joinWXRoomDiv>label{
		font-family: PingFangSC-Semibold;
		margin-top: 35px;
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		color: #333333;
	}
	.joinWXRoomDiv>.ruleInfo{
		width: 100%;
		margin-top: 35px;
		padding-top: 35px;
		border-top: 1px #e7e7e7 solid;
	}
	.joinWXRoomDiv>.ruleInfo>p{
		font-size: 14px;
		color: #8c8a8a;
	}
	.joinWXRoomDiv>.joinBtn{
		width: 200px;
		height: 45px;
		color: #FFFFFF;
		font-size: 14px;
		margin: 0 auto;
		border: none;
		outline: none;
		font-family: PingFangSC-Semibold;
		background-color: #08C163;
		border-radius: 5px;
		margin-top: 35px;
		position: relative;
		overflow: hidden;
		z-index: 1;
		transition: background-color 0.2s;
	}
	.joinWXRoomDiv>.joinBtn:active{
		background-color: #0aa556;
	}
	.closeJoin{
		width: 30px;
		height: 30px;
		display: block;
		position: absolute;
		left: 10px;
		top: 10px;
		font-size: 32px;
		text-align: center;
		line-height: 30px;
		font-weight: 100;
		color: #696969;
	}
`,
  styleScoped: false
};