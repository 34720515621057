
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      integralList: []
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      $.globalSignToken(function () {
        $.globalInitData(["integralProduct"], function () {
          self.$setState({
            integralList: $.appData.integralProductData
          }, function () {
            $.setImg(".integralMallListShop", null, true);
          });
        });
      });
    }
  },
  id: 'abcdee5a9a',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>积分兑换</div><div class=right><a class="link icon-only searchbar-enable" data-searchbar=.popupSearchbar><i class="icon f7-icons if-not-md">search</i> <i class="icon material-icons md-only">search</i></a></div><form class="searchbar searchbar-expandable popupSearchbar searchbar-init" data-search-container=.integralMallListShop data-search-in=.cardTitleName><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=快速查找> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">取消</span></div></form></div></div><div class="page-content integralMallPageColor"><ul class=integralMallListShop>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.integralList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=card><img url=';
          r += c(ctx_2.img, ctx_2);
          r += ' class="card-header align-items-flex-end"><div class="card-content card-content-padding"><p class=cardTitleName>';
          r += c(ctx_2.name, ctx_2);
          r += '</p><p class=text-color-red>';
          r += c(ctx_2.integral, ctx_2);
          r += '积分</p></div><button class="button button-fill button-small color-blue">兑换</button></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div>';
      return r;
    }(this);
  },
  style: `
	.integralMallListShop{
		width: 100%;
		padding: 2%;
		box-sizing: border-box;
		-webkit-column-count:2;
		column-count:2;
		-webkit-column-gap: 1%;
		column-gap: 1%;
		list-style: none;
	}
	.integralMallListShop>li{
		font-size: 14px;
		height: auto;
		text-align: center;
		margin: 2%;
		box-sizing: border-box;
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
		margin-bottom: 8%;
	}
	
	.integralMallListShop .card{
		padding-top: 20px;
		padding-bottom: 20px;
		box-shadow: 0px 5px 10px #cdcdcd;
	}
	.integralMallListShop .card .card-content p{
		font-weight: bold;
		font-size: 13px;
	}
	.integralMallListShop img{
		/* width: calc(50vw - 24%);
		height: 38vw; */
		width: 100%;
		height: auto;
		margin: 0 auto;
		box-sizing: border-box;
	}
	.integralMallListShop .button{
		width: 80px;
		margin: 0 auto;
	}
	.integralMallPageColor{
		background-color: #f2f2f2;
	}
`,
  styleScoped: false
};
    