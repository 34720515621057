import $$ from 'dom7';
export default {
  data: function () {
    return {
      isLoding: false,
      map: "",
      p1: "",
      p2: ""
    };
  },
  methods: {
    checkStartPos(type) {
      var self = this;
      self.$router.navigate("/submitCarpoolingPage/");
    },
    checkButtonPath(e) {
      var self = this;
      $$(".button-active").removeClass("button-active");
      var type = $$(e.currentTarget).addClass("button-active").attr("val");
      sessionStorage.setItem("routePolicy_type", type);
      var map = self.map;
      var p1 = self.p1;
      var p2 = self.p2;
      map.clearOverlays();
      var routePolicy = [BMAP_DRIVING_POLICY_LEAST_TIME, BMAP_DRIVING_POLICY_LEAST_DISTANCE, BMAP_DRIVING_POLICY_AVOID_HIGHWAYS];
      var route = routePolicy[type];
      var driving = new BMap.DrivingRoute(map, {
        renderOptions: {
          map: map,
          autoViewport: true
        },
        policy: route
      });
      driving.search(p1, p2);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      sessionStorage.setItem("routePolicy_type", "0");
    },
    pageBeforeIn: function () {
      var self = this;
      var app = self.$app;
      var startPos = sessionStorage.getItem("setPos_startPos");
      var endPos = sessionStorage.getItem("setPos_endPos");
      if (!!startPos && !!endPos) {
        window.createMap = function () {
          if (self.$route.name != self.$router.currentRoute.name) {
            return;
          }
          var map = new BMap.Map("xlmap");
          map.centerAndZoom(new BMap.Point(proInfoList[projectMark].pos.split(",")[0], proInfoList[projectMark].pos.split(",")[1]), 15);
          map.enableScrollWheelZoom();
          var p1 = new BMap.Point(startPos.split(",")[0], startPos.split(",")[1]);
          var p2 = new BMap.Point(endPos.split(",")[0], endPos.split(",")[1]);
          var routePolicy = [BMAP_DRIVING_POLICY_LEAST_TIME, BMAP_DRIVING_POLICY_LEAST_DISTANCE, BMAP_DRIVING_POLICY_AVOID_HIGHWAYS];
          var driving = new BMap.DrivingRoute(map, {
            renderOptions: {
              map: map,
              autoViewport: true
            }
          });
          driving.search(p1, p2);
          self.map = map;
          self.p1 = p1;
          self.p2 = p2;
        };
        if (typeof BMap == "undefined") {
          var script = document.createElement("script");
          script.src = "https://api.map.baidu.com/api?v=2.0&ak=gVatyMtD0GseVHkt7kcNOLIx6qxeWwTS&callback=createMap";
          document.body.appendChild(script);
        } else {
          createMap();
        }
      }
    }
  },
  id: '12d1ee8f44',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page addCarpooling" data-name=addCarpooling><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>选择线路</div><div class=right><a @click=checkStartPos href=# class=link>下一步</a></div></div></div><div class=page-content><div class="block block-strong addCarpoolingBlock"><p class="segmented segmented-raised"><button type=button val=0 class="button button-active" @click=checkButtonPath>最少时间</button> <button type=button val=1 class=button @click=checkButtonPath>最短距离</button> <button type=button val=2 class=button @click=checkButtonPath>避开高速</button></p></div><div id=xlmap></div></div></div>';
      return r;
    }(this);
  },
  style: `
	#xlmap{
		width:100%;
		box-shadow: 0px 0px 5px #b7b7b7;
		height: calc( 100vh - (var(--f7-navbar-height) + var(--f7-safe-area-top) + var(--f7-button-height) + var(--f7-block-padding-vertical) + var(--f7-block-padding-vertical)));
	}
	
	#xlmap .anchorBL{
		display: none;
	}
	
	.addCarpoolingBlock{
		margin-bottom: 0px;
		margin-top: 0px;
	}
	.addCarpoolingBlock .row{
		margin-bottom: var(--f7-block-padding-vertical);
	}
`,
  styleScoped: false
};