
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      cuCarpoolingIndex: 0,
      carpooling: {
        cuser: 0,
        userarr: []
      },
      copyArr: [],
      userObj: {
        id: 0
      },
      stateDic: {
        "999": "已封禁",
        "1": "已认证",
        "2": "准业主"
      },
      stateColor: {
        "0": "",
        "1": "color-green",
        "2": "color-blue"
      }
    };
  },
  methods: {
    getArrPrev(arrData, id) {
      for (var i = 0; i < arrData.length; i++) {
        if (arrData[i].id == id) {
          if (i > 0) {
            return arrData[i - 1];
          } else {
            return arrData[arrData.length - 1];
          }
        }
      }
    },
    getArrNext(arrData, id) {
      for (var i = 0; i < arrData.length; i++) {
        if (arrData[i].id == id) {
          if (i + 1 < arrData.length) {
            return arrData[i + 1];
          } else {
            return arrData[0];
          }
        }
      }
    },
    nextCar() {
      var self = this;
      var nObj = self.getArrNext(self.copyArr, self.cuCarpoolingIndex);
      if (!!nObj) {
        self.cuCarpoolingIndex = nObj.id;
        self.carpoolingDataRefresh();
      }
    },
    prevCar() {
      var self = this;
      var pObj = self.getArrPrev(self.copyArr, self.cuCarpoolingIndex);
      if (!!pObj) {
        self.cuCarpoolingIndex = pObj.id;
        self.carpoolingDataRefresh();
      }
    },
    carpoolingDataRefresh() {
      var self = this;
      self.carpooling = self.copyArr.take("id", self.cuCarpoolingIndex);
      if (!!self.carpooling) {
        var map = new BMap.Map("pcmapList");
        var lng = self.carpooling.end.split(",")[0];
        var lat = self.carpooling.end.split(",")[1];
        map.enableScrollWheelZoom();
        var p1 = new BMap.Point(self.carpooling.start.split(",")[0], self.carpooling.start.split(",")[1]);
        var p2 = new BMap.Point(self.carpooling.end.split(",")[0], self.carpooling.end.split(",")[1]);
        var routePolicy = [BMAP_DRIVING_POLICY_LEAST_TIME, BMAP_DRIVING_POLICY_LEAST_DISTANCE, BMAP_DRIVING_POLICY_AVOID_HIGHWAYS];
        map.clearOverlays();
        var route = routePolicy[self.carpooling.routetype];
        var driving = new BMap.DrivingRoute(map, {
          renderOptions: {
            map: map,
            autoViewport: true
          },
          policy: route
        });
        driving.search(p1, p2);
        var cuser = $.appData.userData.take("id", self.carpooling.cuser);
        self.carpooling.name = cuser.name;
        self.carpooling.img = cuser.img;
        self.carpooling.stateColor = self.stateColor[cuser.state];
        self.carpooling.stateName = self.stateDic[cuser.state];
        var cycleDic = {
          "1,2,3,4,5": "工作日（周一至周五）",
          "1,2,3,4,5,6": "除周日外（周一至周六）",
          "1,2,3,4,5,6,7": "每天（周一至周日）"
        };
        self.carpooling.cycleName = cycleDic[self.carpooling.cycle] || function () {
          return self.carpooling.cycle.replace(/1/, "周一").replace(/2/, "周二").replace(/3/, "周三").replace(/4/, "周四").replace(/5/, "周五").replace(/6/, "周六").replace(/7/, "周日");
        }();
        if (!!self.carpooling.userarr) {
          var userarr = self.carpooling.userarr.split(",");
          self.carpooling.userlist = [];
          userarr.each(function (n) {
            var uObj = $.appData.userData.take("id", n);
            self.carpooling.userlist.push(uObj);
          });
        }
        if (self.carpooling.cuser == $.user.id) {
          self.carpooling.edit = true;
        } else if (!!self.carpooling.userarr && self.carpooling.userarr.split(",").indexOf($.user.id) > -1) {
          self.carpooling.exit = true;
        } else {
          self.carpooling.join = true;
        }
        self.$setState({
          carpooling: self.carpooling
        }, function () {
          $.setImg(".carpoolingListCard", null, null, true, null);
        });
      } else {
        $.toast("该拼车信息已被车主删除");
      }
    },
    hrefToEdit() {
      var self = this;
      self.$router.navigate("/editCarpoolingPage/" + self.carpooling.id + "/");
    },
    joinCarpooling() {
      var self = this;
      ($.baseUrl + "/joinCarpooling?id=" + self.carpooling.id + "&_=" + $.getTime()).g(function (json) {
        if (json.code == 1) {
          self.carpooling.exit = true;
          self.carpooling.join = false;
          var userArr = self.carpooling.userarr.split(",");
          if (userArr.indexOf($.user.id) == -1) userArr.push($.user.id);
          self.carpooling.userarr = userArr.join(",");
          self.carpooling.userlist = [];
          userArr.each(function (n) {
            var uObj = $.appData.userData.take("id", n);
            self.carpooling.userlist.push(uObj);
          });
          self.$setState({
            carpooling: self.carpooling
          }, function () {
            $.setImg(".carpoolingListCard", null, null, true, null);
          });
        } else {
          $.toast(json.data);
        }
      });
    },
    exitCarpooling() {
      var self = this;
      ($.baseUrl + "/exitCarpooling?id=" + self.carpooling.id + "&_=" + $.getTime()).g(function (json) {
        if (json.code == 1) {
          self.carpooling.exit = false;
          self.carpooling.join = true;
          var userArr = self.carpooling.userarr.split(",");
          if (userArr.indexOf($.user.id) > -1) userArr.splice(userArr.indexOf($.user.id), 1);
          self.carpooling.userarr = userArr.join(",");
          self.carpooling.userlist = [];
          userArr.each(function (n) {
            var uObj = $.appData.userData.take("id", n);
            self.carpooling.userlist.push(uObj);
          });
          self.$setState({
            carpooling: self.carpooling
          }, function () {
            $.setImg(".carpoolingListCard");
          });
        } else {
          $.toast(json.data);
        }
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      let pms1 = new Promise((resolve, reject) => {
        $.initData(["user", "carpooling"], function () {
          resolve();
        });
      });
      let pms2 = new Promise((resolve, reject) => {
        window.createMap = function () {
          resolve();
        };
        if (typeof BMap == "undefined") {
          var script = document.createElement("script");
          script.src = "https://api.map.baidu.com/api?v=2.0&ak=gVatyMtD0GseVHkt7kcNOLIx6qxeWwTS&callback=createMap";
          document.body.appendChild(script);
        } else {
          createMap();
        }
      });
      self.$setState({
        userObj: $.user
      });
      Promise.all([pms1, pms2]).then(() => {
        if ($.appData.carpoolingData.length > 0) {
          self.copyArr = $.jsonSort($.appData.carpoolingData, "id", "desc");
          self.cuCarpoolingIndex = self.$route.params.id || self.copyArr[0].id;
          self.carpoolingDataRefresh();
        } else {
          self.$setState({
            isNullData: true
          });
        }
      }).catch(error => {});
    }
  },
  id: 'dd2f19287d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page createUser" data-name=createUser><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>邻里拼车</div><div class=right><a href=/setStartPosPage/ class=link>发布</a></div></div></div><div class=page-content>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isNullData, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("暂无拼车"), ctx_2);
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.carpooling.join, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.carpooling.edit, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.carpooling.exit, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="card carpoolingListCard"><div class=card-header><a href=/userInfo/';
          r += c(ctx_2.carpooling.cuser, ctx_2);
          r += '/ ><img url=';
          r += c(ctx_2.carpooling.img, ctx_2);
          r += ' width=34 height=34></a><div class=carpoolingListCardUserName>';
          r += c(ctx_2.carpooling.name, ctx_2);
          r += '</div><div><span class="badge ';
          r += c(ctx_2.carpooling.stateColor, ctx_2);
          r += '">';
          r += c(ctx_2.carpooling.stateName, ctx_2);
          r += '</span></div></div><div class="card-content card-content-padding"><div id=pcmapList></div><div class="block block-strong"><p class=row>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.carpooling.join, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <button @click=prevCar() class="col button button-fill color-blue">上一个</button> <button @click=joinCarpooling() class="col button button-fill color-red">加入</button> <button @click=nextCar() class="col button button-fill color-blue">下一个</button> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.carpooling.edit, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <button @click=prevCar() class="col button button-fill color-blue">上一个</button> <button @click=hrefToEdit() class="col button button-fill color-green">编辑</button> <button @click=nextCar() class="col button button-fill color-blue">下一个</button> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.carpooling.exit, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <button @click=prevCar() class="col button button-fill color-blue">上一个</button> <button @click=exitCarpooling() class="col button button-fill color-orange">退出</button> <button @click=nextCar() class="col button button-fill color-blue">下一个</button> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div></div><div class=card-footer><div class=list style="width: 100%;"><ul><li><div class=item-content><div class=item-inner><div class=item-title>周期</div><div class=item-after>';
          r += c(ctx_2.carpooling.cycleName, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>时间</div><div class=item-after>';
          r += c(ctx_2.carpooling.time, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>车牌号</div><div class=item-after>';
          r += c(ctx_2.carpooling.carno, ctx_2);
          r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>可携带人数</div><div class=item-after>';
          r += c(ctx_2.carpooling.seatnum, ctx_2);
          r += '</div></div></div></li><li>';
          r += Template7Helpers.js_if.call(ctx_2, "!!@root.carpooling.userarr", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="item-content item-link carpoolingListCardUserListDiv"><a href=/joinUserListPage/';
              r += c(ctx_3.carpooling.id, ctx_3);
              r += '/ class=item-inner><div class=item-title>已加入业主</div><div class=item-after>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.carpooling.userlist, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img class=carpoolingListCardUserListImg url=';
                  r += c(ctx_4.img, ctx_4);
                  r += '> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></a></div>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=item-content><div class=item-inner><div class=item-title>已加入业主</div><div class=item-after>暂无</div></div></div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</li></ul></div></div></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `
	#pcmapList {
		width: 100%;
		height: calc(100vh - 450px);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	#pcmapList .anchorBL {
		display: none;
	}

	.carpoolingListCardUserName {
		width: 70%;
		position: absolute;
		left: 60px;
		box-sizing: border-box;
	}

	.carpoolingListCardUserListImg {
		width: 30px;
		height: 30px;
		margin-left: 5px;
	}

	.carpoolingListCardUserListDiv>a {
		color: var(--f7-list-item-title-text-color);
	}
`,
  styleScoped: false
};
    