
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      authentication: {},
      stateDic: {
        "1": "待审核",
        "10": "已通过",
        "20": "未通过"
      },
      stateColor: {
        "1": "color-blue",
        "10": "color-green",
        "20": "color-red"
      }
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      ($.baseUrl + "/checkAuthentication?_=" + new Date().getTime()).g(function (json) {
        if (+json.code == 1) {
          var arr = json.data;
          var sData = arr.take("id", self.$route.params.id);
          sData.stateColor = self.stateColor[sData.state];
          sData.stateName = self.stateDic[sData.state];
          self.$setState({
            authentication: sData
          });
        }
      });
    }
  },
  id: 'ddcafb86c2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=userInfo><style>.businessImgDiv{\r\n				display: flex;\r\n				justify-content: start;\r\n				flex-wrap: wrap;\r\n				flex-direction: row;\r\n			}\r\n			.businessImgDiv>img{\r\n				width: 28%;\r\n				height: 25vw;\r\n				margin-top: 5px;\r\n				margin-bottom: 5px;\r\n				margin-right: 10px;\r\n				border-radius: 5px;\r\n			}\r\n			.marginTop0{\r\n				margin-top: 0px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>认证详情</div></div></div><div class=page-content><div class="block block-strong inset no-hairlines"><div class="list marginTop0"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">备案号</div><div class=item-input-wrap>';
      r += c(ctx_1.authentication.docno, ctx_1);
      r += '</div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">身份证</div><div class=item-input-wrap>';
      r += c(ctx_1.authentication.idcard, ctx_1);
      r += '</div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">认证时间</div><div class=item-input-wrap>';
      r += c(ctx_1.authentication.ctime, ctx_1);
      r += '</div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">认证状态</div><div class=item-input-wrap><span class="badge ';
      r += c(ctx_1.authentication.stateColor, ctx_1);
      r += '">';
      r += c(ctx_1.authentication.stateName, ctx_1);
      r += '</span></div></div></li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.authentication.state > 10, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">驳回原因</div><div class=item-input-wrap>';
          r += c(ctx_2.authentication.reason, ctx_2);
          r += '</div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div>';
      return r;
    }(this);
  },
  style: `
			.businessImgDiv{
				display: flex;
				justify-content: start;
				flex-wrap: wrap;
				flex-direction: row;
			}
			.businessImgDiv>img{
				width: 28%;
				height: 25vw;
				margin-top: 5px;
				margin-bottom: 5px;
				margin-right: 10px;
				border-radius: 5px;
			}
			.marginTop0{
				margin-top: 0px;
			}
		`,
  styleScoped: false
};
    