import $$ from 'dom7';
(function($){
	$.uploadImgList=function(checkImgList,fn,oneFn){
		var imgDomList = $$(".checkImgList img");
		var imgList = [];
		imgDomList.each(function(){
			var url =$$(this).attr("src");
			var imgObj = checkImgList.take("url", url);
			imgList.push(imgObj);
		});
		var dialog = window.myApp.dialog.progress('上传',0);
		var arr=[];
		function uploadImg(i) {
			if (imgList.hasOwnProperty(i)) {
				var imgObj = imgList[i];
				dialog.setText('照片-' + (i + 1) + '-正在上传中..');
				uploadFile(imgObj.file, function(url) {
					arr.push(url);
					if(!!oneFn)
						oneFn(url);
					uploadImg(i + 1);
				}, function(e) {
					var num = e.loaded / e.total;
					var numval = parseInt(num * 100);
					if (numval >= 100)
						numval = 100;
					dialog.setProgress(numval);
				});
			} else {
				dialog.close();
				if(!!fn)
					fn(arr);
			}
		}
		uploadImg(0);
	}
	$.fileImgAdd=function(input,checkImgList,delFn){
		var files = input.files;
		$$(".checkImgListDiv").show();
		var dialog = window.myApp.dialog.preloader('照片审核');
		function each(i,fn){
			if(files.hasOwnProperty(i)){
				dialog.setText('照片-' + (i + 1) + '-检测中..');
				$.imgExamine(files[i]).then(function(examine){
					if(examine){
						$.imgRepairIOS(files[i], function(file) {
							if(file==null){
								dialog.close();
								return $.toast('该图片过大请更换图片');
							}
							var imgBlob = window.URL.createObjectURL(file);
							$$(".checkImgList>ul")[0].innerHTML +=
								`
								<li>
									<div class="item-content">
										<div class="item-media">
											<img src="${imgBlob}" width="80" />
										</div>
										<div class="item-inner">
											<div class="addImgPreviewImg">
												<button type="button" class="button button-raised color-blue button-fill">预览</button>
											</div>
											<div class="addImgDelImg">
												<button type="button" class="button button-raised color-red button-fill">移除</button>
											</div>
										</div>
									</div>
									<div class="sortable-handler"></div>
								</li>
							`;
							checkImgList.push({
								url: imgBlob,
								file: file
							});
							each(i+1,fn);
						});
					}else{
						$.toast('照片-' + (i + 1) + '-审核未通过,请勿上传违规图片,已跳过该图片');
						each(i+1,fn);
					}
				})
			}else{
				if(checkImgList.length==0)
					$$(".checkImgListDiv").hide();
				dialog.close();
				if(!!fn)
					fn();
			}
		}
		each(0,function(){
			input.value = "";
			$$(".checkImgList .addImgPreviewImg>button").on("click", function() {
				var li = $$(this).parent().parent().parent().parent();
				var imgArr = [];
				$$(".checkImgList img").each(function() {
					imgArr.push($$(this).attr("src"));
				});
				var showImgListObj = window.myApp.photoBrowser.create({
					photos: imgArr,
					theme: 'dark',
					type: 'popup',
					popupCloseLinkText: "关闭",
				});
				showImgListObj.activeIndex = li.index();
				showImgListObj.open();
			});
			$$(".checkImgList .addImgDelImg>button").on("click", function() {
				var li = $$(this).parent().parent().parent().parent();
				checkImgList.splice(li.index(),1);
				li.addClass("remove");
				var tm = window.setTimeout(function() {
					clearTimeout(tm);
					li.remove();
					if ($$(".checkImgList img").length == 0) {
						$$(".checkImgListDiv").hide();
						if(!!delFn)
							delFn();
					}
				}, 500);
			});
		});
	}
})(window.$);