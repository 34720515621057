
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      checkImgList: [],
      showImgListObj: "",
      hasActivityTime: false,
      hasEndTime: false
    };
  },
  methods: {
    fileImgCheck(e) {
      var self = this;
      var input = e.currentTarget;
      $.fileImgAdd(input, self.checkImgList);
    },
    addProblemInfo() {
      var self = this;
      var app = self.$app;
      app.input.validateInputs($$("#addActivityForm")[0]);
      var isValid = true;
      $$("#addActivityForm input").each(function () {
        if (!$$(this)[0].validity.valid) {
          isValid = false;
          return;
        }
      });
      if (isValid) {
        var val = self.textEditor.getValue();
        if (!val) {
          return $.toast("介绍一下活动内容吧");
        }
        if (self.checkImgList.length > 9) return $.toast("最多可上传9张照片");
        if (val.length > 8000) return $.toast("活动介绍最多8000个字!");
        var obj = app.form.convertToData("#addActivityForm");
        obj.startnum = 1;
        obj.remark = "";
        obj.userarr = $.user.id;
        obj.content = val;
        $.initData(["activity"], function () {
          $.uploadImgList(self.checkImgList, function (imgList) {
            obj.img = imgList.join("|");
            obj.cuser = $.user.id;
            obj.ctime = $.getTime();
            app.dialog.preloader("发起中...");
            if (!!obj.stime) obj.stime = obj.stime.replace("T", " ");
            if (!!obj.etime) obj.etime = obj.etime.replace("T", " ");
            $.appData.activityData.add(obj, function (json) {
              app.dialog.close();
              if (+json.code == 1) {
                delete $.appData.activityData;
                self.$router.navigate("/activitySuccessPage/");
              } else {
                $.toast(json.data);
              }
            });
          });
        });
      }
    },
    changeActivityTime(e, t, d) {
      var self = this;
      self.$setState({
        hasActivityTime: e.currentTarget.checked
      });
    },
    changeEndTime(e, t, d) {
      var self = this;
      self.$setState({
        hasEndTime: e.currentTarget.checked
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      self.textEditor = app.textEditor.create({
        el: '#activityEditor',
        placeholder: `在这里填写活动介绍...`,
        buttons: [["bold", "italic", "underline", "strikeThrough"], ["orderedList", "unorderedList"]]
      });
    }
  },
  id: '2f1302a837',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=catalog><style>#activityEditor>.text-editor-content{\r\n			  min-height: 120px;\r\n		  }\r\n		  .addActivityListDiv{\r\n			  margin-bottom: 0px;\r\n		  }\r\n		  .addActivityBlockTitle{\r\n			  margin-top: var(--f7-list-item-padding-vertical);\r\n		  }</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>发起活动</div></div></div><div class=page-content><form id=addActivityForm><div class="list no-hairlines-md addActivityListDiv"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">活动主题</div><div class=item-input-wrap><input required type=text name=title placeholder=请填写活动主题> <span class=input-clear-button></span></div></div></li></ul></div><div class="block-title addActivityBlockTitle">活动介绍</div><div id=activityEditor class="text-editor text-editor-resizable"><div class=text-editor-content contenteditable></div></div><div class="list simple-list no-margin"><ul><li><span>活动日期</span> <label class="toggle toggle-init color-blue"><input @change=changeActivityTime type=checkbox> <span class=toggle-icon></span></label></li></ul></div><div>';
      r += Template7Helpers.if.call(ctx_1, root.hasActivityTime, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list no-margin"><ul><li class="item-content item-input"><div class=item-inner><div class=item-input-wrap><input data-error-message=请选择活动时间 title=活动时间 required name=stime type=datetime-local placeholder=请选择活动日期 style="max-width: 100%;"></div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="list simple-list no-margin"><ul><li><span>截止日期</span> <label class="toggle toggle-init color-blue"><input @change=changeEndTime type=checkbox> <span class=toggle-icon></span></label></li></ul></div><div>';
      r += Template7Helpers.if.call(ctx_1, root.hasEndTime, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list no-margin"><ul><li class="item-content item-input"><div class=item-inner><div class=item-input-wrap><input data-error-message=请选择截止日期 title=截止时间 required name=etime type=datetime-local placeholder=请选择截止日期 style="max-width: 100%;"></div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=block style="width: 100%;"><div class=row><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo</i> <i class="f7-icons if-not-md">photo_fill</i> <input class=uploadBtn @change=fileImgCheck type=file multiple=multiple type=file accept=image/*></div></div><div class=sheetIconLabel>相册</div></div><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo_camera</i> <i class="f7-icons if-not-md">camera_fill</i> <input class=uploadBtn @change=fileImgCheck type=file capture=camera type=file accept=image/*></div></div><div class=sheetIconLabel>拍照</div></div><div class=col-25></div></div></div><div class="block block-strong checkImgListDiv" style="display: none;"><div class="list checkImgList media-list sortable sortable-enabled"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.checkImgList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class="padding-horizontal padding-bottom"><a class="button button-large button-fill" @click=addProblemInfo>发起</a></div></form></div></div>';
      return r;
    }(this);
  },
  style: `
		  #activityEditor>.text-editor-content{
			  min-height: 120px;
		  }
		  .addActivityListDiv{
			  margin-bottom: 0px;
		  }
		  .addActivityBlockTitle{
			  margin-top: var(--f7-list-item-padding-vertical);
		  }
		  
	  `,
  styleScoped: false
};
    