import Framework7 from 'framework7/framework7.esm.bundle.js';
(function(){
	if(window.WebSocket){
		var msgKeyList=[];
		var lastConnTime=0;
		var timer="";
		var sokId="";
		var pCount=0;
		
		function sendTxt(ws,txt){
			try{
				if(ws.readyState==1)
					ws.send(txt);
			}catch(e){}
		}
		
		function showNotification(img,name,ctime,content,clickFn){
			var notificationCallbackOnClose = window.myApp.notification.create({
			  icon: `<img class="notificationPhoto" url="${img}" />`,
			  title: name,
			  titleRightText: ctime.replace(/^[\w-]+ /,"").replace(/:[\d]+$/,""),
			  subtitle: content,
			  text: '点击查看消息',
			  on: {
				click: function () {
					if(Framework7.device.desktop){
						notificationCallbackOnClose.$el.attr("style","display: block;");
					}
					notificationCallbackOnClose.close();
					if(!!clickFn)
						clickFn();
				},
			  },
			});
			window.setTimeout(function(){
				notificationCallbackOnClose.open();
				$.setImg(".notification");
			},0);
			
			
			// function showNotification() {
			// 	if(window.Notification) {
			// 		if(window.Notification.permission == "granted") {
			// 			var notification = new Notification('你有一条新消息', {
			// 				body: name+":"+content,
			// 				icon: img
			// 			});
			// 			setTimeout(function() { notification.close(); }, 5000);
			// 		} else {
			// 			window.Notification.requestPermission();
			// 		}
			// 	}
			// };
			// showNotification()
		}
		
		// if(window.Notification) {
		// 	if(window.Notification.permission != "granted") {
		// 		window.Notification.requestPermission();
		// 	}
		// }
		
		function connectWS(){
			if(window.myApp.online==false)
				return;
			if(!!timer)
				clearInterval(timer);
			var ws = null;
			try{
				ws = new WebSocket((window.location.protocol=="https:"?"wss":"ws")+"://"+proInfoList[projectMark].ip+":"+proInfoList[projectMark].soc);
				window.sockWs=ws;
			
				ws.onopen = function(e){
					if(!$.user)
						return;
					var obj={};
					obj.type="bind";
					obj.time=new Date().getTime();
					obj.id=obj.time+""+(Math.random()+"").replace(".","");
					sokId=obj.id;
					obj.uid=$.user.id;
					obj.username=$.user.phone;
					obj.password=$.user.pwd;
					sendTxt(ws,JSON.stringify(obj));
					timer=window.setInterval(function(){
						if(pCount>2){
							pCount=0;
							connectWS();
							return;
						}
						sendTxt(ws,JSON.stringify({
							id:sokId,
							type:"ping"
						}));
						pCount++;
					},3000);
				}
				ws.onclose = function(e){
					// lastConnTime=(new Date().getTime());
					// $.createConfirmBoxOK({"title":"网络中断","content":"网络已断开,正在重连...","click":function(){
					// 	if(((new Date().getTime())-lastConnTime)>20*1000){
					// 		window.location.reload();
					// 		return;
					// 	}
					// 	connectWS();
					// }});
				}
				ws.onerror = function(){
					// lastConnTime=(new Date().getTime());
					// $.createConfirmBoxOK({"title":"连接出错","content":"连接出错,正在重连...","click":function(){
					// 	if(((new Date().getTime())-lastConnTime)>20*1000){
					// 		window.location.reload();
					// 		return;
					// 	}
					// 	connectWS();
					// }});
				}
				ws.onmessage = function(e){
					if(e.data=="pong"){
						pCount--;
						return;
					}
					var msgData=JSON.parse(e.data);
					var obj=msgData.data;
					sendTxt(ws,JSON.stringify({
						type:"msgSuccess",
						msgMark:msgData.mk,
					}));
					var dName=obj.name+"Data";
					if(!!$.appData.hasOwnProperty(dName)){
						var bol=true;
						if(obj.type=="add"){
							if(!$.appData[dName].take("id",obj.obj["id"]))
								$.appData[dName].push(obj.obj);
							else
								bol=false;
						}else if(obj.type=="upd"){
							var oldObj=$.appData[dName].take("id",obj.obj["id"]);
							if(!!oldObj)
								Object.assign(oldObj,obj.obj);
							else
								bol=false;
						}else if(obj.type=="del"){
							var delObj=$.appData[dName].take("id",obj.obj["id"]);
							if(!!delObj)
								$.appData[dName].rem("id",obj.obj["id"]);
							else
								bol=false;
						}
						if(!!$.appData[dName].refresh&&bol)
							$.appData[dName].refresh(obj.obj,obj.type);
					}
					if(dName=="messageData"){
						$.initData(["message","user"],function(){
							var url=window.myApp.view.main.router.url;
							var name=url.match(/[a-zA-Z]+/)
							if(!!name)
								name=name[0];
							else
								name="";
							var uid=url.match(/\d+/);
							if(!!uid)
								uid=uid[0];
							else
								uid=0;
							if(name=="sendMessage"&&uid==obj.obj.cid)
								return;
							if($.user.id==obj.obj.sid){
								var sUser=$.appData.userData.take("id",obj.obj.cid);
								var content=obj.obj.content;
								if(+obj.obj.type==2){
									content="[图片]";
								}else if(+obj.obj.type==4){
									content="[表情]";
								}else if(+obj.obj.type==5){
									content="[赞]";
								}else if(+obj.obj.type==6){
									content="[视频]";
								}		
								showNotification(sUser.img,sUser.name,obj.obj.ctime,content,function(){
									var uObj=$.appData.userData.take("id",obj.obj.cid);
									if(uObj.id==$.user.id)
										return;
									window.myApp.view.main.router.navigate('/sendMessage/'+uObj.id+'/');
								});
							}
						});
					}else if(dName=="squareData"){
						var url=window.myApp.view.main.router.url;
						var name=url.match(/[a-zA-Z]+/)
						if(!!name)
							name=name[0];
						else
							name="";
						if(name=="roomMessage")
							return;
						if($.user.id==obj.obj.sid){
							var sUser=$.appData.userData.take("id",obj.obj.cid);
							var content=obj.obj.content;
							if(+obj.obj.type==2){
								content="[图片]";
							}else if(+obj.obj.type==4){
								content="[表情]";
							}else if(+obj.obj.type==5){
								content="[赞]";
							}	
							showNotification(sUser.img,sUser.name,obj.obj.ctime,content,function(){
								var uObj=$.appData.userData.take("id",obj.obj.cid);
								if(uObj.id==$.user.id)
									return;
								window.myApp.view.main.router.navigate('/roomMessage/');
							});
						}
					}
					// else if(dName=="squareData"){
					// 	if(window.history.state.href.match(/^[^?]+/)[0]=="page/square/square.html")
					// 		return;
					// 	if($.user.id==obj.obj.sid){
					// 		var sUser=$.userData.take("id",obj.obj.cid);
					// 		var content=obj.obj.content;
					// 		if(obj.obj.type==2){
					// 			content="[图片]";
					// 		}
					// 		$.showMsg(sUser.img,sUser.name,content,function(){
					// 			$.toHref("page/square/square.html");
					// 		});
					// 	}
					// }else if(dName=="circleReplyData"){
					// 	var args=$.getArgs();
					// 	if(window.history.state.href.match(/^[^?]+/)[0]=="page/circle/circleDetails.html"&&args.cid==obj.obj.cid)
					// 		return;
					// 	if(!!$.circleData){
					// 		var cObj=$.circleData.take("id",obj.obj.cid);
					// 		if((cObj.cuser==$.user.id&&obj.obj.tuser=="")||~~obj.obj.tuser==$.user.id){
					// 			var sUser=$.userData.take("id",obj.obj.cuser);
					// 			$.showMsg(sUser.img,sUser.name,obj.obj.content,function(){
					// 				$.toHref("page/circle/circleDetails.html?cid="+cObj.id);
					// 			});
					// 		}
					// 	}
					// }
					if(dName=="onlineData"){
						$.onlineUserList=obj.obj;
						if(!!$.onlineUserListChange){
							$.onlineUserListChange();
						}
					}
				}
			}catch(e){}
		}
		window.connectWS=connectWS;
    }
})();
