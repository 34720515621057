
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      checkImgList: [],
      showImgListObj: "",
      currentProject: {}
    };
  },
  methods: {
    fileImgCheck(e) {
      var self = this;
      var input = e.currentTarget;
      $.fileImgAdd(input, self.checkImgList);
    },
    addProjectReply() {
      var self = this;
      var app = self.$app;
      var val = self.textEditor.getValue();
      if (!val) {
        return $.toast("说点什么吧...");
      }
      if (self.checkImgList.length == 0) return $.toast("至少传一张做好的效果图吧!");
      if (self.checkImgList.length > 9) return $.toast("最多可上传9张照片!");
      if (val.length > 1200) return $.toast("评价最多1200个字!");
      var obj = {
        content: val
      };
      $.initData(["project"], function () {
        $.uploadImgList(self.checkImgList, function (imgList) {
          app.dialog.preloader("发布中...");
          obj.pid = self.currentProject.id;
          obj.replyimg = imgList.join("|");
          obj.name = $.user.name;
          $.user.img = $.user.img.replace(/http:/, "https:");
          obj.img = $.user.img;
          obj.tuser = "";
          ($.globalBaseUrl + "/addGlobalProjectReply").globalP(function (json) {
            if (+json.code == 1) {
              if ($.appData.hasOwnProperty("projectReplyData")) $.appData.projectReplyData.push(json.data);
            }
          }, obj);
          ($.globalBaseUrl + "/replyGlobalOrder").globalP(function (json) {
            if (+json.code == 1) {
              if ($.appData.hasOwnProperty("formDataData")) var fObj = $.appData.formDataData.take("id", self.currentProject.fid);
              fObj.state = 550;
              app.dialog.close();
              self.$router.navigate(`/ProjectReplySuccessPage/`);
            }
          }, {
            "fid": self.currentProject.fid
          });
        });
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      self.textEditor = app.textEditor.create({
        el: '#projectReplyEditor',
        placeholder: `说点什么吧...`,
        buttons: [["bold", "italic", "underline", "strikeThrough"], ["orderedList", "unorderedList"]]
      });
      $.globalSignToken(function () {
        $.globalInitData(["formData", "project"], function () {
          var fObj = $.appData.formDataData.take("id", self.$route.params.id);
          var pro = $.appData.projectData.take("id", fObj.pid);
          var obj = JSON.parse(JSON.stringify(pro));
          obj.img = pro.img.split("|")[0];
          obj.price = pro.price == 0 ? '免费' : "&yen;" + pro.price;
          obj.fid = fObj.id;
          self.$setState({
            currentProject: obj
          }, function () {
            $.setImg(".addProjectReplyInfo");
          });
        });
      });
    }
  },
  id: 'b1421e1396',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=addProjectReply><style>#projectReplyEditor>.text-editor-content{\r\n			  min-height: 120px;\r\n		  }</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>评价服务</div></div></div><div class=page-content><div class="list media-list addProjectReplyInfo inset"><ul><li><a href=/projectDetails/';
      r += c(ctx_1.currentProject.id, ctx_1);
      r += '/ class="item-link item-content"><div class=item-media><img url=';
      r += c(ctx_1.currentProject.img, ctx_1);
      r += ' width=70></div><div class=item-inner><div class=item-title-row><div class=item-title>';
      r += c(ctx_1.currentProject.name, ctx_1);
      r += '</div><div class=item-after>销量：';
      r += c(ctx_1.currentProject.volume, ctx_1);
      r += '</div></div><div class=item-subtitle>';
      r += c(ctx_1.currentProject.price, ctx_1);
      r += '</div><div class=item-text>';
      r += c(ctx_1.currentProject.introduce, ctx_1);
      r += '</div></div></a></li></ul></div><div id=projectReplyEditor class="text-editor text-editor-resizable"><div class=text-editor-content contenteditable></div></div><div class=block style="width: 100%;"><div class=row><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo</i> <i class="f7-icons if-not-md">photo_fill</i> <input class=uploadBtn @change=fileImgCheck type=file multiple=multiple type=file accept=image/*></div></div><div class=sheetIconLabel>相册</div></div><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo_camera</i> <i class="f7-icons if-not-md">camera_fill</i> <input class=uploadBtn @change=fileImgCheck type=file capture=camera type=file accept=image/*></div></div><div class=sheetIconLabel>拍照</div></div><div class=col-25></div></div></div><div class="block block-strong checkImgListDiv" style="display: none;"><div class="list checkImgList media-list sortable sortable-enabled"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.checkImgList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class="padding-horizontal padding-bottom"><a class="button button-large button-fill" @click=addProjectReply>提交评价</a></div></div></div>';
      return r;
    }(this);
  },
  style: `
			#projectReplyEditor>.text-editor-content{
			  min-height: 120px;
		  }
		  
	  `,
  styleScoped: false
};
    