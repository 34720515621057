import $$ from 'dom7';
export default {
  data: function () {
    return {};
  },
  methods: {
    saveName: function (e) {
      var self = this;
      var app = self.$app;
      e.preventDefault();
      app.input.validateInputs($$("#setNameForm")[0]);
      var isValid = true;
      $$("#setNameForm input").each(function () {
        if (!$$(this)[0].validity.valid) {
          isValid = false;
          return;
        }
      });
      if (isValid) {
        var obj = app.form.convertToData("#setNameForm");
        sessionStorage.setItem("setName", obj.name);
        self.$router.back();
      } else {
        $.toast("填写信息有误,请修改后重试");
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      $$("#setNameForm input[name='name']").val(self.$route.params.name);
    }
  },
  id: '83f0d023a3',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=userInfo><style lang=less scoped>.userInfoImg{\r\n				border-radius: 10px;\r\n				width: 100px;\r\n				height: 100px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>设置昵称</div></div></div><div class=page-content><form id=setNameForm><div class="list inline-labels no-hairlines-md"><ul><li class="item-content item-input"><div class=item-media><!-- <i class="icon material-icons md-only">lock</i> --> <i class="icon f7-icons">person_alt_circle</i></div><div class=item-inner><div class="item-title item-label">昵称</div><div class=item-input-wrap><input name=name type=text placeholder=点击此处填写昵称 data-error-message=请填写一个你喜欢的昵称 type=text required validate pattern=[\u4e00-\u9fa5a-zA-Z0-9~～!@#$%&*()！￥……（）]+ data-vaildate-on-blur=true> <span class=input-clear-button></span></div></div></li></ul></div><div class="block no-hairlines"><button class="button button-large button-fill" @click=saveName>确定</button></div></form></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};