(function($) {
	var dbName = "data";
	var dbVersion = 1.0;
	var tablename = "HTML";
	var H5AppDB = {};
	var fileVersion = {};
	var indexedDB = window.indexedDB || window.webkitIndexedDB;
	if ('webkitIndexedDB' in window) {
	    window.IDBTransaction = window.webkitIDBTransaction;
	    window.IDBKeyRange = window.webkitIDBKeyRange;
	}
	H5AppDB.indexedDB = {};
	H5AppDB.indexedDB.db = null;
	H5AppDB.indexedDB.open = function() {
	    var request = indexedDB.open(dbName, dbVersion);
	    request.onsuccess = function(e) {
	        H5AppDB.indexedDB.db = e.target.result;
			var db = H5AppDB.indexedDB.db;
			if (db.setVersion) {
				if (db.version != dbVersion) {
	                var req = db.setVersion(dbVersion);
	                req.onsuccess = function() {
	                	if (!db.objectStoreNames.contains(tablename)) {
	                        var store = db.createObjectStore(tablename, {
		                        keyPath: "id"
		                    });
	                    }
	                    var trans = req.result;
	                    trans.oncomplete = function(e) {
	                        
	                    }
	                };
	            }
			}
	    };
		request.onupgradeneeded=function(e){
	    	var db = e.target.result;
			if (!db.objectStoreNames.contains(tablename)) {
	            var store = db.createObjectStore(tablename, {
	                keyPath: "id"
	            });
	        }
		};
	}
	H5AppDB.indexedDB.open();
	function getHTML(name,fn){
		name=name.replace(window.dbBasePath,"");
		var db = H5AppDB.indexedDB.db;
		if(db==null){
			var tm=setTimeout(function(){
				clearTimeout(tm);
				getHTML(name,fn)
			},0);
			return;
		}
	    var trans = db.transaction([tablename], "readwrite"); //通过事物开启对象
	    var store = trans.objectStore(tablename);
		var request = store.get(name);
		request.onsuccess = function(e) {
	        var obj=e.target.result; 
	        var text="";
	        if(!!obj){
	        	text=obj.text;
	        	if(obj.hasOwnProperty("v")){
		        	var v=obj.v;
		        	var fv=fileVersion[obj.id];
		        	if(~~fv>~~v){
		        		text=null;
		        	}
		        }
	        }
	        if(!!fn)
	        	fn(text);
	    };
	    request.onerror = function(e) {
	    	console.log("添加出错");
	    };
	}
	function addHTML(key,value,fn){
		key=key.replace(window.dbBasePath,"");
		var v=1;
		if(fileVersion.hasOwnProperty(key))
			v=fileVersion[key];
		var db = H5AppDB.indexedDB.db;
	    var trans = db.transaction([tablename], "readwrite");
	    var store = trans.objectStore(tablename);
	    var data = {
	        "text": value,
	        "id": key,
	        "v": v
	    };
	    var request = store.put(data);
	    request.onsuccess = function(e) {
			if(!!fn)
				fn();
		};
	    request.onerror = function(e) {
	    	console.log("添加出错");
	    };
	}
	function updateHTML(key,value,fn){
		key=key.replace(window.dbBasePath,"");
		var db = H5AppDB.indexedDB.db;
	    var trans = db.transaction([tablename], "readwrite");
	    var store = trans.objectStore(tablename);
	    var data = {
	        "text": value,
	        "id": key
	    };
	    var request = store.put(data);
	    request.onsuccess = function(e) {
			if(!!fn)
				fn();
		};
	    request.onerror = function(e) {
	    	console.log("添加出错");
	    };
	}
	function deleteHTML(name,fn){
		var db = H5AppDB.indexedDB.db;
	    var trans = db.transaction([tablename], "readwrite");
	    var store = trans.objectStore(tablename);
		if(db==null){
			var tm=setTimeout(function(){
				clearTimeout(tm);
				deleteHTML(name,fn)
			},0);
			return;
		}
	    try{
	    	var request = null;
	    	if(!name)
	    		request = store.clear();
		    else
		    	request = store.delete(name);
			request.onsuccess = function(e) {
		        if(!!fn)
		        	fn();
		    };
		    request.onerror = function(e) {
		    	console.log("删除出错");
		    };
	    }catch(e){}
	}
	function getCount(fn){
		var db = H5AppDB.indexedDB.db;
	    var trans = db.transaction([tablename], "readwrite"); //通过事物开启对象
	    var store = trans.objectStore(tablename); //获取到对象的值
		var count=0;
		var keyRange = IDBKeyRange.lowerBound(0);
		var cursorRequest = store.openCursor(keyRange); //开启索引为0的表
	    cursorRequest.onsuccess = function(e) {
	        var result = e.target.result;
	        if ( !! result == false){
	        	if(!!fn)
	        		fn(count);
	        	cursorRequest.onsuccess=function(){};
	        	return;
	        }
			count++;
	    };
	}
	$.addHTML=addHTML;
	$.updateHTML=updateHTML;
	$.getHTML=getHTML;
	$.deleteHTML=deleteHTML;
	$.getCount=getCount;
	
	
	$.getDb=function(){
		var db = H5AppDB.indexedDB.db;
		return db;
	}
})(window);
