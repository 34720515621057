
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      walletRecord: []
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      $.globalSignToken(function () {
        $.globalInitData(["walletRecordBonus"], function (json) {
          self.$setState({
            walletRecord: $.appData.walletRecordBonusData
          });
        });
      });
    }
  },
  id: '69ac5e2c14',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=settings><style></style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>奖励金明细</div></div></div><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.walletRecord&&this.walletRecord.length>0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="block block-strong no-hairlines"><div class=card><div class="list inset"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.walletRecord, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><div class=item-content><div class=item-inner><div class=item-title><div class=item-header>';
              r += c(ctx_3.ctime, ctx_3);
              r += '</div>';
              r += c(ctx_3.remark, ctx_3);
              r += '</div><div class=item-after><span class="badge color-green">';
              r += Template7Helpers.js.call(ctx_3, "this.oval-this.nval>=0?'+':''", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js.call(ctx_3, "this.oval-this.nval", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span></div></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.walletRecord&&this.walletRecord.length==0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("暂无明细"), ctx_2);
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `

		`,
  styleScoped: false
};
    