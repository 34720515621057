import Framework7 from 'framework7/framework7.esm.bundle.js';
(function ($) {
	$.globalSignToken=function(fn){
		if(!$.globalSign){
			($.baseUrl+"/createToken").g(function(json){
				if((json.code+"")=="1"){
					$.globalSign=json.data;
					if(!!fn)
						fn();
				}
			});
		}else{
			if(!!fn)
				fn();
		}
	}
	var requestIdleCallback=window.requestIdleCallback||function(f){
		if(!!f){
			f({timeRemaining:function(){
				return 50;
			}});
		}
	}
	function delayedTask(fn){
		requestIdleCallback(function(dtm){
			if(dtm.timeRemaining()>40){
				if(!!fn)
					fn();
			}else{
				delayedTask(fn);
			}
		});
	}
	String.prototype.globalG = function (fn) {
		let _this=this;
		Framework7.request({
			url:_this,
			method:"GET",
			headers:{
				"signToken":encodeURIComponent($.globalSign)
			},
			success:function(json){
				if (!!fn) fn(JSON.parse(json));
				delayedTask(function(){
					addHTML(_this.replace(/([?]?_=\d+$)|(&?_=\d+&?)/,""),json);
				});
			},
			error:function(){
				if(myApp.online)
					$.toast("服务器异常,加载数据失败!");
				getHTML(_this.replace(/([?]?_=\d+$)|(&?_=\d+&?)/,""),function(jsonHtml){
					if(!!jsonHtml){
						if (!!fn) fn(JSON.parse(jsonHtml));
					}else{
						if(!myApp.online)
							$.toast("当前网络已断开,请检查网络!");
					}
				});
			}
		});
	};
	
	String.prototype.globalP = function (fn, data) {
		let _this=this;
		Framework7.request({
			url:_this,
			method:"POST",
			data:!!data ? data : "",
			headers:{
				"signToken":encodeURIComponent($.globalSign)
			},
			success:function(json){
				if (!!fn) fn(JSON.parse(json));
			},
			error:function(){
				if(myApp.online)
					$.toast("服务器异常,操作失败!");
				else
					$.toast("当前网络已断开,请检查网络!");
			}
		});
	};
	
	$.globalBaseUrl = window.location.protocol+"//"+proInfoList[projectMark].ip+":"+(window.location.protocol=="https:"?"8231":"1231");
	
	$.globalInitData = function (list, successFn) {
		var count = list.length;
		if (count == 0) return successFn();
		var index = 0;
		function isOk() {
			if (index == count) {
				if (!!successFn) successFn();
			}
		}
		for (var i in list) {
			if (list.hasOwnProperty(i)) {
				var _ret = function () {
					var tb = list[i];
					var tbList = tb.split("");
					tbList[0] = tbList[0].toUpperCase();
					var newTb = tbList.join("");
					if ($.appData.hasOwnProperty(tb + "Data")) {
						index++;
						isOk();
						return "continue";
					}
					($.globalBaseUrl + "/getGlobal" + newTb + "?_=" + new Date().getTime()).globalG(function (json) {
						filterError(json);
						if (json.code == "1") {
							var content = json.data;
							$.appData[tb + "Data"] = content;
							index++;
							isOk();
						}
					});
				}();
	
				if (_ret === "continue") continue;
			}
		}
	};
	function filterError(json) {
		if (json.code == "0" && json.data == "error") {
			alert("无权限");
			location.reload();
		}
	}
	
})(window.$);