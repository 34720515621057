import $$ from 'dom7';
export default {
  data: function () {
    return {
      checkImgList: [],
      showImgListObj: ""
    };
  },
  methods: {},
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var img = $$("#myCanvas");
      var url = sessionStorage.getItem("userInfoCutImgUrl");
      var myCanvas = document.getElementById("myCanvas");
      var con = myCanvas.getContext("2d");
      var imgLS = document.getElementById("img");
      imgLS.src = url;
      imgLS.onload = function () {
        var bl = bodyWidth / imgLS.naturalWidth;
        var w = bodyWidth;
        var h = imgLS.naturalHeight * bl;
        myCanvas.width = w;
        myCanvas.height = h;
        con.drawImage(imgLS, 0, 0, myCanvas.width, myCanvas.height);
        myCanvas.style.top = (sectionHeight - bodyWidth) / 2 + "px";
        myCanvas.style.left = "0px";
      };
      function resize(w, h) {
        var imgData = con.getImageData(0, 0, myCanvas.width, myCanvas.height);
        myCanvas.width = w;
        myCanvas.height = h;
        con.putImageData(imgData, 0, 0);
      }
      $$(".cutImgSubmit").on("click", function () {
        $$(this).html("裁切中...");
        app.preloader.show();
        var x = $$(".sectioncutImg>.centerDiv").offset().left - img.offset().left;
        var y = $$(".sectioncutImg>.centerDiv").offset().top - img.offset().top;
        var w = $$(".sectioncutImg>.centerDiv").width();
        var h = $$(".sectioncutImg>.centerDiv").height();
        x = parseFloat(x / matrix[0]);
        y = parseFloat(y / matrix[0]);
        w = parseFloat(w / matrix[0]);
        h = parseFloat(h / matrix[0]);
        var pixels = con.getImageData(x, y, w, h);
        con.clearRect(0, 0, myCanvas.width, myCanvas.height);
        myCanvas.width = w;
        myCanvas.height = h;
        con.putImageData(pixels, 0, 0);
        matrix[4] = bodyWidth / 2 - w / 2;
        matrix[5] = bodyWidth / 2 - h / 2;
        img.css({
          "transform": "matrix(" + matrix.toString() + ")",
          "-webkit-transform": "matrix(" + matrix.toString() + ")"
        });
        var imgUrl = myCanvas.toDataURL("image/png");
        sessionStorage.setItem("userInfoCutImgUrlResult", imgUrl);
        var tm = window.setTimeout(function () {
          clearTimeout(tm);
          app.preloader.hide();
          self.$router.back();
        }, 1000);
      });
      function rotateCanavs(imageRotate) {
        var w = myCanvas.width;
        var h = myCanvas.height;
        var rotation = Math.PI * imageRotate / 180;
        var c = Math.round(Math.cos(rotation) * 1000) / 1000;
        var s = Math.round(Math.sin(rotation) * 1000) / 1000;
        //旋转后canvas标签的大小
        canvasWidth = myCanvas.height = Math.abs(c * h) + Math.abs(s * w);
        canvasHeight = myCanvas.width = Math.abs(c * w) + Math.abs(s * h);
        //改变中心点
        if (rotation <= Math.PI / 2) {
          con.translate(s * h, 0);
        } else if (rotation <= Math.PI) {
          con.translate(canvasWidth, -c * h);
        } else if (rotation <= 1.5 * Math.PI) {
          con.translate(-c * w, canvasHeight);
        } else {
          con.translate(0, -s * w);
        }
        con.rotate(rotation);
        con.drawImage(imgLS, 0, 0, w, h);
        con.restore();
      }
      /*触摸*/
      var matrix = [1, 0, 0, 1, 0, 0];
      img.css({
        "transform": "matrix(" + matrix.toString() + ")",
        "-webkit-transform": "matrix(" + matrix.toString() + ")"
      });
      function eventScalcDiv() {
        var scalOld = 1;
        var sectionZ = 0;
        var initX = 0;
        var initY = 0;
        var matrX = 0;
        var matrY = 0;
        var bol = true;
        var bdeg = 0;
        var rotateDeg = 0;
        var bolRotate = true;
        $$(".sectioncutImg").off("touchstart").on("touchstart", function (e) {
          var e1 = event.targetTouches[0];
          var e2 = event.targetTouches[1];
          if (!!e1 && !!e2 && !!e1.pageX && !!e2.pageX) {
            var sectionX = Math.abs(e1.pageX - e2.pageX);
            var sectionY = Math.abs(e1.pageY - e2.pageY);
            sectionZ = Math.sqrt(Math.pow(sectionX, 2) + Math.pow(sectionY, 2));
            var a = 0;
            var b = 0;
            var c = 0;
            a = Math.abs(e1.pageY - e2.pageY);
            b = Math.abs(e1.pageX - e2.pageX);
            c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
            var bd = Math.acos((Math.pow(c, 2) + Math.pow(b, 2) - Math.pow(a, 2)) / (2 * c * b));
            bdeg = 180 / Math.PI * bd;
          } else if (!!e1 && !!e1.pageX) {
            initX = e1.pageX;
            initY = e1.pageY;
          }
        });
        $$(".sectioncutImg").off("touchmove").on("touchmove", function (e) {
          var e1 = event.targetTouches[0];
          var e2 = event.targetTouches[1];
          if (!!e1 && !!e2 && !!e1.pageX && !!e2.pageX) {
            var x = Math.abs(e1.pageX - e2.pageX);
            var y = Math.abs(e1.pageY - e2.pageY);
            var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            var scal = z / sectionZ;
            matrix[0] = scal * scalOld;
            matrix[3] = scal * scalOld;
            var a = 0;
            var b = 0;
            var c = 0;
            a = Math.abs(e1.pageY - e2.pageY);
            b = Math.abs(e1.pageX - e2.pageX);
            c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
            var bd = Math.acos((Math.pow(c, 2) + Math.pow(b, 2) - Math.pow(a, 2)) / (2 * c * b));
            var deg = 180 / Math.PI * bd;
            var rotate = deg - bdeg;
            if (rotate > 25 && bolRotate) {
              rotateDeg -= 90;
              if (rotateDeg < 0) rotateDeg = 270;
              bolRotate = false;
            } else if (rotate < -25 && bolRotate) {
              rotateDeg += 90;
              if (rotateDeg > 360) rotateDeg = 90;
              bolRotate = false;
            }
            // rotateCanavs(rotateDeg);
            if (matrix[0] < 0 || matrix[3] < 0) return;
          } else if (!!e1 && !!e1.pageX && bol) {
            var x = e1.pageX - initX;
            var y = e1.pageY - initY;
            if (Math.abs(x) < 3 && Math.abs(y) < 3) return;
            matrix[4] = matrX + x;
            matrix[5] = matrY + y;
            // odX=e1.pageX;
            // odY=e1.pageY;
          }

          img.css({
            "transform": "matrix(" + matrix.toString() + ")",
            "-webkit-transform": "matrix(" + matrix.toString() + ")"
          });
        });
        $$(".sectioncutImg").off("touchend").on("touchend", function (e) {
          scalOld = matrix[0];
          matrX = matrix[4];
          matrY = matrix[5];
          var e1 = event.targetTouches[0];
          bolRotate = true;
          if (!!e1 && !!e1.pageX) {
            bol = false;
          } else {
            bol = true;
          }
          if (matrix[0] < 1 || matrix[3] < 1) {
            matrix[0] = matrix[3] = 1;
            img.addClass("transitionCanavs");
            img.css({
              "transform": "matrix(" + matrix.toString() + ")",
              "-webkit-transform": "matrix(" + matrix.toString() + ")"
            });
            var timer = window.setTimeout(function () {
              clearTimeout(timer);
              img.removeClass("transitionCanavs");
            }, 250);
          }
        });
      }
      eventScalcDiv();
      /*兼容布局*/
      var bodyWidth = $$(window).width();
      var bodyHeight = $$(window).height();
      var sectionHeight = bodyHeight - 47;
      $$(".sectioncutImg").css("height", sectionHeight + "px");
      $$(".sectioncutImg>.topDiv").css("height", (sectionHeight - bodyWidth) / 2 + "px");
      $$(".sectioncutImg>.centerDiv").css("height", bodyWidth + "px");
      $$(".sectioncutImg>.bottomDiv").css("height", (sectionHeight - bodyWidth) / 2 + "px");
    }
  },
  id: '95647bcced',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=settings><style>.sectioncutImg{\r\n				position: relative;\r\n				-webkit-transform:translate3d(0,0,0);\r\n				transform:translate3d(0,0,0);\r\n				overflow: hidden;\r\n				background-color: #000000;\r\n			}\r\n			#myCanvas{\r\n				position: absolute;\r\n				backface-visibility: hidden;\r\n				transform-style: preserve-3d;\r\n				-webkit-transform-style: preserve-3d;\r\n				-webkit-transform:translate3d(0,0,0);\r\n				transform:translate3d(0,0,0);\r\n				z-index: 1;\r\n			}\r\n			.sectioncutImg>.topDiv{\r\n				width: 100%;\r\n				background-color: rgba(0,0,0,0.8);\r\n				position: fixed;\r\n				top: 0px;\r\n				z-index: 2;\r\n				box-sizing: border-box;\r\n				border-bottom: 1px #FFFFFF solid;\r\n			}\r\n			.sectioncutImg>.centerDiv{\r\n				width: 100%;\r\n				position: fixed;\r\n				margin:auto;\r\n			    left:0;\r\n			    right:0;\r\n			    top:0;\r\n			    bottom:0;\r\n			    z-index: 0;\r\n			}\r\n			.sectioncutImg>.bottomDiv{\r\n				width: 100%;\r\n				background-color: rgba(0,0,0,0.8);\r\n				position: fixed;\r\n				bottom: 0px;\r\n				z-index: 2;\r\n				box-sizing: border-box;\r\n				border-top: 1px #FFFFFF solid;\r\n				transform: scale();\r\n			}\r\n			.transitionCanavs{\r\n				transition:-webkit-transform 0.25s;\r\n				-webkit-transition:-webkit-transform 0.25s;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>设置头像</div><div class=right><a href=javascript:void(0) class="link cutImgSubmit">完成</a></div></div></div><div class=page-content><section class=sectioncutImg><img id=img style="position: fixed;top: -10000px;"><canvas id=myCanvas></canvas><div class=topDiv></div><div class=centerDiv></div><div class=bottomDiv></div></section></div></div>';
      return r;
    }(this);
  },
  style: `
			.sectioncutImg{
				position: relative;
				-webkit-transform:translate3d(0,0,0);
				transform:translate3d(0,0,0);
				overflow: hidden;
				background-color: #000000;
			}
			#myCanvas{
				position: absolute;
				backface-visibility: hidden;
				transform-style: preserve-3d;
				-webkit-transform-style: preserve-3d;
				-webkit-transform:translate3d(0,0,0);
				transform:translate3d(0,0,0);
				z-index: 1;
			}
			.sectioncutImg>.topDiv{
				width: 100%;
				background-color: rgba(0,0,0,0.8);
				position: fixed;
				top: 0px;
				z-index: 2;
				box-sizing: border-box;
				border-bottom: 1px #FFFFFF solid;
			}
			.sectioncutImg>.centerDiv{
				width: 100%;
				position: fixed;
				margin:auto;
			    left:0;
			    right:0;
			    top:0;
			    bottom:0;
			    z-index: 0;
			}
			.sectioncutImg>.bottomDiv{
				width: 100%;
				background-color: rgba(0,0,0,0.8);
				position: fixed;
				bottom: 0px;
				z-index: 2;
				box-sizing: border-box;
				border-top: 1px #FFFFFF solid;
				transform: scale();
			}
			.transitionCanavs{
				transition:-webkit-transform 0.25s;
				-webkit-transition:-webkit-transform 0.25s;
			}
		`,
  styleScoped: false
};