import Framework7 from 'framework7/framework7.esm.bundle.js';
import $$ from 'dom7';
(function(){
	
	function addBack(){
		var first = null;
		plus.key.addEventListener('backbutton', function() {
			if(!window.myApp)
				return;
			if (window.myApp.view.main.router.url=="/") {
				if (!first) {
					first = new Date().getTime();
					$.toast('再按一次退出应用');
					setTimeout(function() {
						first = null;
					}, 1000);
				} else {
					if (new Date().getTime() - first < 1000) {
						plus.runtime.quit();
					}
				}
			} else {
				window.myApp.view.main.router.back();
			}
		}, false);
	}
	
	
	
	
	
	function dataURLtoBlob(dataurl) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], {
			type: mime
		});
	}
	function gallerySelected(resultFn, dom, maximum) {
		if (dom.getAttribute("capture") == "camera") {
			var blobArr = [];
			var cmr = plus.camera.getCamera();
			var res = cmr.supportedImageResolutions[0];
			var fmt = cmr.supportedImageFormats[0];
			console.log("Resolution: " + res + ", Format: " + fmt);
			cmr.captureImage(function(path) {
				plus.io.resolveLocalFileSystemURL( path, function( entry ) {
					entry.file( function(file){
						var fileReader = new plus.io.FileReader();
						fileReader.readAsDataURL(file, 'utf-8');
						fileReader.onloadend = function(evt) {
							var blob=dataURLtoBlob(evt.target.result);
							blobArr.push(blob);
							resultFn && resultFn(blobArr);
						}
					} );
				});
			},
			function(error) {
				
			}, {
				resolution: res,
				format: fmt
			});
		} else {
			var accept=dom.getAttribute("accept");
			plus.gallery.pick(function(e) {
				var blobArr = [];
				if (!dom.multiple)
					e = {
						files: [e]
					}
				function eachFile(i) {
					if (e.files.hasOwnProperty(i)) {
						plus.io.resolveLocalFileSystemURL(e.files[i], function(entry) {
							entry.file(function(file) {
								var fileReader = new plus.io.FileReader();
								fileReader.readAsDataURL(file, 'utf-8');
								fileReader.onloadend = function(evt) {
									var blob = dataURLtoBlob(evt.target.result);
									blobArr.push(blob);
									eachFile(i + 1);
								}
							});
						});
					} else {
						resultFn && resultFn(blobArr);
					}
				}
				eachFile(0);
			}, function(e) {
				console.log("取消选择图片");
			}, {
				filter: accept&&accept.replace(/\/.+/,"")||"image",
				multiple: dom.multiple,
				selected: null,
				maximum: maximum,
				system: false
			});
		}
	}
	
	function updFileInput(){
		$$(document).on("click", "input[type='file']", function (e) { 
		  e.preventDefault();
		  var dom=this;
		  gallerySelected(function(blobArr) {
		  	function setVal(domObj, name) {
		  		Object.defineProperty(domObj.__proto__, name, {
		  			get: function get(v) {
		  				return this["_" + name];
		  			},
		  			set: function set(val) {
		  				this["_" + name] = val;
		  			}
		  		});
		  	}
		  	setVal(dom, "files");
		  	dom.files = blobArr;
		  	var ev = new Event("change", {
		  		"bubbles": false,
		  		"cancelable": false
		  	});
		  	dom.dispatchEvent(ev);
		  }, dom, 12);
		});
	}
	
	function playVideo(){
		$$(document).on("click", "img[videourl]", function (e) {
			window.myApp.preloader.show();
			
			var browser=window.myApp.photoBrowser.get(".popup.photo-browser-popup.modal-in");
			browser.close();
			
			var url=$$(this).attr("videourl");
			window.setTimeout(function(){
				var Intent = plus.android.importClass("android.content.Intent");
				var Uri = plus.android.importClass("android.net.Uri");  
				var main = plus.android.runtimeMainActivity();  
				var intent=new Intent(Intent.ACTION_VIEW);  
				var uri=Uri.parse(url);  
				intent.setDataAndType(uri,"video/*");  
				main.startActivity(intent); 
				window.myApp.preloader.hide();
			},0);
		});
		
		// function playEvent(e){
		// 	e.preventDefault();
		// 	var url=$$(this).children("source").attr("src");
		// 	var Intent = plus.android.importClass("android.content.Intent");
		// 	var Uri = plus.android.importClass("android.net.Uri");  
		// 	var main = plus.android.runtimeMainActivity();  
		// 	var intent=new Intent(Intent.ACTION_VIEW);  
		// 	var uri=Uri.parse(url);  
		// 	intent.setDataAndType(uri,"video/*");  
		// 	main.startActivity(intent); 
		// }
		// $$(document).on("click", "video", playEvent);
		// $$(document).on("play", "video", playEvent);
	}
	
	function savePicture(blob){
		var oReader = new FileReader();
		oReader.onload = function(e) {
			let base64=e.target.result;
			var b=new plus.nativeObj.Bitmap();  
			b.loadBase64Data(base64,function(){
				b.save('_doc/saveImg.jpg',{overwrite:true},function(){
					plus.gallery.save('_doc/saveImg.jpg', function(){
						$.toast("保存成功");
						b.clear();
					}, function(e){
						$.toast("保存失败");
					});
				},function(e){
					console.log("存储失败");
				});  
			},function(e){
				console.log("缓存失败");
			});  
		};
		oReader.readAsDataURL(blob);
	}
	
	function sharePicture(blob){
		var oReader = new FileReader();
		oReader.onload = function(e) {
			let base64=e.target.result;
			var b=new plus.nativeObj.Bitmap();  
			b.loadBase64Data(base64,function(){
				b.save('_doc/shareImg.jpg',{overwrite:true},function(){
					let msg={
						type:"image",
						pictures: ['_doc/shareImg.jpg'],
						content:"分享图片",
						href:window.location.href
					}
					plus.share.sendWithSystem(msg, function(){
						b.clear();
						// $.toast("分享成功");
					}, function(e){
						$.toast("分享失败");
					});
				},function(e){
					$.toast("分享失败");
				}); 
			},function(e){
				$.toast("分享失败");
			});  
		};
		oReader.readAsDataURL(blob);
	}
	
	
	if (Framework7.device.android) {
		window.setTimeout(function() {
			if (!!window["plus"]) {
				addBack();
				updFileInput();
				playVideo();
				window.savePicture=savePicture;
				window.sharePicture=sharePicture;
			}
		}, 2000);
	}
	
})();