import HomePage from '../pages/home.f7.html';
import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';
import ProductPage from '../pages/product.f7.html';

import MessagePage from '../pages/message.f7.html';
import SendMessagePage from '../pages/message/sendMessage.f7.html';
import EmoticonPage from '../pages/message/emoticon.f7.html';
import RoomMessagePage from '../pages/message/roomMessage.f7.html';
import LoginPage from '../pages/login.f7.html';
import RoomPage from '../pages/roomList/room.f7.html';
import RoomUnitPage from '../pages/roomList/roomUnit.f7.html';
import UserInfoPage from '../pages/roomList/userInfo.f7.html';
import RoomInfoPage from '../pages/roomList/roomInfo.f7.html';
import CircleListPage from '../pages/circle/circleList.f7.html';
import AddCirclePage from '../pages/circle/addCircle.f7.html';
import ProjectServicesPage from '../pages/project/projectServices.f7.html';
import ProjectDetailsPage from '../pages/project/projectDetails.f7.html';
import BusinessDetailsPage from '../pages/project/businessDetails.f7.html';
import AddProjectPage from '../pages/project/addProject.f7.html';
import ProjectSuccessPage from '../pages/project/projectSuccess.f7.html';
import ProjectReplyDetailsPage from '../pages/project/projectReplyDetails.f7.html';
import ProblemListPage from '../pages/problem/problemList.f7.html';
import ProblemDetailsPage from '../pages/problem/problemDetails.f7.html';
import AddProblemPage from '../pages/problem/addProblem.f7.html';
import DoorKeyPage from '../pages/doorKey/doorKey.f7.html';
import ShowCodePage from '../pages/doorKey/showCode.f7.html';
import ImgListPage from '../pages/roomImg/imgList.f7.html';
import AddImgPage from '../pages/roomImg/addImg.f7.html';
import PhoneListPage from '../pages/phone/phoneList.f7.html';
import SettingsPage from '../pages/setting/settings.f7.html';
import DisclaimerPage from '../pages/setting/disclaimer.f7.html';
import SetNewPasswordPage from '../pages/setting/setNewPassword.f7.html';
import UpdatePhonePage from '../pages/setting/updatePhone.f7.html';
import NewPhonePage from '../pages/register/newPhone.f7.html';
import NamePwdPage from '../pages/register/namePwd.f7.html';
import RegisterPage from '../pages/register/register.f7.html';
import RegisterSuccessPage from '../pages/register/registerSuccess.f7.html';
import ForgetPasswordPage from '../pages/setting/forgetPassword.f7.html';
import ForgetSetPwdPage from '../pages/setting/forgetSetPwd.f7.html';
import SetPwdSuccessPage from '../pages/setting/setPwdSuccess.f7.html';
import CollectionListPage from '../pages/myInfo/collectionList.f7.html';
import MyPublishListPage from '../pages/myInfo/myPublishList.f7.html';
import CutImgPage from '../pages/myInfo/cutImg.f7.html';
import SetNamePage from '../pages/myInfo/setName.f7.html';
import ProjectMainPage from '../pages/project/projectMain.f7.html';
import ProjectTypePage from '../pages/project/projectType.f7.html';

import CommodityListPage from '../pages/supermarket/commodityList.f7.html';
import CommoditySuccessPage from '../pages/supermarket/commoditySuccess.f7.html';
import MyCommodityPage from '../pages/supermarket/myCommodity.f7.html';
import AllCommodityPage from '../pages/property/allCommodity.f7.html';
import AllCommodityStatePage from '../pages/property/allCommodityState.f7.html';
import UpdateCommodityPage from '../pages/property/updateCommodity.f7.html';


import MyWalletPage from '../pages/wallet/myWallet.f7.html';
import MyProjectPage from '../pages/related/myProject.f7.html';
import MyProjectDetailsPage from '../pages/related/myProjectDetails.f7.html';
import AddProjectReplyPage from '../pages/related/addProjectReply.f7.html';
import ProjectReplySuccessPage from '../pages/related/projectReplySuccess.f7.html';
import AuthenticationPage from '../pages/related/authentication.f7.html';
import AuthenticationSuccessPage from '../pages/related/authenticationSuccess.f7.html';
import ExamDetailsPage from '../pages/related/examDetails.f7.html';


import NameSexRoomPage from '../pages/register/nameSexRoom.f7.html';
import AddComplaintPage from '../pages/complaint/addComplaint.f7.html';

import IntegralMallPage from '../pages/integral/integralMall.f7.html';
import IntegralDetailsPage from '../pages/wallet/integralDetails.f7.html';
import BonusDetailsPage from '../pages/wallet/bonusDetails.f7.html';

import CarpoolingListPage from '../pages/carpooling/carpoolingList.f7.html';
import AddCarpoolingPage from '../pages/carpooling/addCarpooling.f7.html';
import SetStartPosPage from '../pages/carpooling/setStartPos.f7.html';
import SetEndPosPage from '../pages/carpooling/setEndPos.f7.html';
import SubmitCarpoolingPage from '../pages/carpooling/submitCarpooling.f7.html';
import CarpoolingSuccessPage from '../pages/carpooling/carpoolingSuccess.f7.html';
import EditCarpoolingPage from '../pages/carpooling/editCarpooling.f7.html';
import JoinUserListPage from '../pages/carpooling/joinUserList.f7.html';

import EasyRegisterSuccessPage from '../pages/register/easyRegisterSuccess.f7.html';

import JointlyEmailListPage from '../pages/jointlyEmail/jointlyEmailList.f7.html';
import JointlyEmailDetailsPage from '../pages/jointlyEmail/jointlyEmailDetails.f7.html';
import AddJointlyEmailPage from '../pages/jointlyEmail/addJointlyEmail.f7.html';
import JointlyEmailSuccessPage from '../pages/jointlyEmail/jointlyEmailSuccess.f7.html';

import ActivityListPage from '../pages/activity/activityList.f7.html';
import AddActivityPage from '../pages/activity/addActivity.f7.html';
import ActivityDetailsPage from '../pages/activity/activityDetails.f7.html';
import ActivitySuccessPage from '../pages/activity/activitySuccess.f7.html';
import EditActivityPage from '../pages/activity/editActivity.f7.html';


import JoinWXRoomPage from '../pages/weixin/joinWXRoom.f7.html';
import JoinWXRoomSuccessPage from '../pages/weixin/joinWXRoomSuccess.f7.html';

import ShowDevPage from '../pages/setting/showDev.f7.html';
import AddNoticePage from '../pages/property/addNotice.f7.html';
import NoticeSuccessPage from '../pages/property/noticeSuccess.f7.html';

import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [{
		path: '/',
		component: HomePage,
		isOpen:true,
	},
	{
		path: '/message/',
		component: MessagePage,
	},
	{
		path: '/about/',
		component: AboutPage,
		isOpen:true,
	},
	{
		path: '/form/',
		component: FormPage,
	},
	{
		path: '/product/:id/',
		component: ProductPage,
	},
	{
		path: '/settings/',
		component: SettingsPage,
		isOpen:true,
	},
	{
		path: '/sendMessage/:id/',
		component: SendMessagePage,
	},
	{
		path: '/roomMessage/',
		component: RoomMessagePage,
	},
	{
		path: '/emoticon/',
		component: EmoticonPage,
	},
	{
		name: 'login',
		path: '/login/',
		component: LoginPage,
		isOpen:true,
	},
	{
		name: 'room',
		path: '/room/:id/',
		component: RoomPage,
	},
	{
		name: 'userInfo',
		path: '/userInfo/:id/',
		component: UserInfoPage,
	},
	{
		name: 'roomInfo',
		path: '/roomInfo/:id/',
		component: RoomInfoPage,
		isOpen:true,
	},
	{
		name: 'circleList',
		path: '/circleList/',
		component: CircleListPage,
		isOpen:true,
	},
	{
		name: 'circleList',
		path: '/circleList/:id/',
		component: CircleListPage,
		isOpen:true,
	},
	{
		name: 'addCircle',
		path: '/addCircle/',
		component: AddCirclePage,
	},
	{
		name: 'projectServices',
		path: '/projectServices/',
		component: ProjectServicesPage,
		isOpen:true,
	},
	{
		name: 'projectDetails',
		path: '/projectDetails/:id/',
		component: ProjectDetailsPage,
		isOpen:true,
	},
	{
		name: 'businessDetails',
		path: '/businessDetails/:id/',
		component: BusinessDetailsPage,
		isOpen:true,
	},
	{
		name: 'problemList',
		path: '/problemList/:type/',
		component: ProblemListPage,
		isOpen:true,
	},
	{
		name: 'problemDetails',
		path: '/problemDetails/:id/:type/',
		component: ProblemDetailsPage,
		isOpen:true,
	},
	{
		name: 'addProjectPage',
		path: '/addProjectPage/:id/',
		component: AddProjectPage,
	},
	{
		name: 'projectSuccess',
		path: '/projectSuccess/',
		component: ProjectSuccessPage,
	},
	{
		name: 'projectReplyDetails',
		path: '/projectReplyDetails/:id/',
		component: ProjectReplyDetailsPage,
		isOpen:true,
	},
	{
		name: 'addProblem',
		path: '/addProblem/:type/',
		component: AddProblemPage,
	},
	{
		name: 'doorKey',
		path: '/doorKey/',
		component: DoorKeyPage,
	},
	{
		name: 'showCode',
		path: '/showCode/:url/',
		component: ShowCodePage,
	},
	{
		name: 'imgList',
		path: '/imgList/',
		component: ImgListPage,
		isOpen:true,
	},
	{
		name: 'addImg',
		path: '/addImg/',
		component: AddImgPage,
	},
	{
		name: 'phoneList',
		path: '/phoneList/',
		component: PhoneListPage,
		isOpen:true,
	},
	{
		name: 'disclaimer',
		path: '/disclaimer/',
		component: DisclaimerPage,
		isOpen:true,
	},
	{
		name: 'setNewPassword',
		path: '/setNewPassword/',
		component: SetNewPasswordPage,
	},
	{
		name: 'updatePhonePage',
		path: '/updatePhonePage/',
		component: UpdatePhonePage,
	},
	{
		name: 'roomUnit',
		path: '/roomUnit/:stage/:room/',
		component: RoomUnitPage,
		isOpen:true,
	},
	{
		name: 'myWalletPage',
		path: '/myWalletPage/',
		component: MyWalletPage,
	},
	{
		name: 'myProjectPage',
		path: '/myProjectPage/',
		component: MyProjectPage,
	},
	{
		name: 'myProjectDetailsPage',
		path: '/myProjectDetailsPage/:id/',
		component: MyProjectDetailsPage,
	},
	{
		name: 'addProjectReplyPage',
		path: '/addProjectReplyPage/:id/',
		component: AddProjectReplyPage,
	},
	{
		name: 'ProjectReplySuccessPage',
		path: '/ProjectReplySuccessPage/',
		component: ProjectReplySuccessPage,
	},
	{
		name: 'newPhonePage',
		path: '/newPhonePage/',
		component: NewPhonePage,
		isOpen:true,
	},
	{
		name: 'namePwdPage',
		path: '/namePwdPage/:id/',
		component: NamePwdPage,
		isOpen:true,
	},
	{
		name: 'registerPage',
		path: '/registerPage/:id/',
		component: RegisterPage,
		isOpen:true,
	},
	{
		name: 'registerSuccessPage',
		path: '/registerSuccessPage/',
		component: RegisterSuccessPage,
		isOpen:true,
	},
	{
		name: 'forgetPasswordPage',
		path: '/forgetPasswordPage/',
		component: ForgetPasswordPage,
		isOpen:true,
	},
	{
		name: 'forgetSetPwdPage',
		path: '/forgetSetPwdPage/',
		component: ForgetSetPwdPage,
		isOpen:true,
	},
	{
		name: 'setPwdSuccessPage',
		path: '/setPwdSuccessPage/',
		component: SetPwdSuccessPage,
		isOpen:true,
	},
	{
		name: 'collectionListPage',
		path: '/collectionListPage/',
		component: CollectionListPage,
	},
	{
		name: 'myPublishListPage',
		path: '/myPublishListPage/',
		component: MyPublishListPage,
	},
	{
		name: 'cutImgPage',
		path: '/cutImgPage/',
		component: CutImgPage,
	},
	{
		name: 'setNamePage',
		path: '/setNamePage/:name/',
		component: SetNamePage,
	},
	{
		name: 'projectMainPage',
		path: '/projectMainPage/',
		component: ProjectMainPage,
		isOpen:true,
	},
	{
		name: 'projectTypePage',
		path: '/projectTypePage/:type/',
		component: ProjectTypePage,
		isOpen:true,
	},
	{
		name: 'commodityListPage',
		path: '/commodityListPage/',
		component: CommodityListPage,
		isOpen:true,
	},
	{
		name: 'commoditySuccessPage',
		path: '/commoditySuccessPage/',
		component: CommoditySuccessPage,
	},
	{
		name: 'myCommodityPage',
		path: '/myCommodityPage/',
		component: MyCommodityPage,
	},
	{
		name: 'allCommodityPage',
		path: '/allCommodityPage/',
		component: AllCommodityPage,
	},
	{
		name: 'allCommodityStatePage',
		path: '/allCommodityStatePage/',
		component: AllCommodityStatePage,
	},
	{
		name: 'updateCommodityPage',
		path: '/updateCommodityPage/:id/',
		component: UpdateCommodityPage,
	},
	{
		name: 'nameSexRoomPage',
		path: '/nameSexRoomPage/:id/',
		component: NameSexRoomPage,
		isOpen:true,
	},
	{
		name: 'nameSexRoomPage',
		path: '/nameSexRoomPage/:id/select/',
		component: NameSexRoomPage,
		isOpen:true,
	},
	{
		name: 'addComplaintPage',
		path: '/addComplaintPage/:id/',
		component: AddComplaintPage,
	},
	{
		name: 'AuthenticationPage',
		path: '/authenticationPage/',
		component: AuthenticationPage,
	},
	{
		name: 'AuthenticationSuccessPage',
		path: '/authenticationSuccessPage/',
		component: AuthenticationSuccessPage,
	},
	{
		name: 'IntegralMallPage',
		path: '/integralMallPage/',
		component: IntegralMallPage,
	},
	{
		name: 'IntegralDetailsPage',
		path: '/integralDetailsPage/',
		component: IntegralDetailsPage,
	},
	{
		name: 'BonusDetailsPage',
		path: '/bonusDetailsPage/',
		component: BonusDetailsPage,
	},
	{
		name: 'CarpoolingListPage',
		path: '/carpoolingListPage/',
		component: CarpoolingListPage,
	},
	{
		name: 'AddCarpoolingPage',
		path: '/addCarpoolingPage/',
		component: AddCarpoolingPage,
	},
	
	{
		name: 'SetStartPosPage',
		path: '/setStartPosPage/',
		component: SetStartPosPage,
	},
	{
		name: 'SetEndPosPage',
		path: '/setEndPosPage/',
		component: SetEndPosPage,
	},
	{
		name: 'SubmitCarpoolingPage',
		path: '/submitCarpoolingPage/',
		component: SubmitCarpoolingPage,
	},
	{
		name: 'CarpoolingSuccessPage',
		path: '/carpoolingSuccessPage/:text/',
		component: CarpoolingSuccessPage,
	},
	{
		name: 'EditCarpoolingPage',
		path: '/editCarpoolingPage/:id/',
		component: EditCarpoolingPage,
	},
	{
		name: 'JoinUserListPage',
		path: '/joinUserListPage/:id/',
		component: JoinUserListPage,
	},
	{
		name: 'EasyRegisterSuccessPage',
		path: '/easyRegisterSuccessPage/',
		component: EasyRegisterSuccessPage,
	},
	{
		name: 'ExamDetailsPage',
		path: '/examDetailsPage/:id/',
		component: ExamDetailsPage,
	},
	{
		name: 'JointlyEmailListPage',
		path: '/jointlyEmailListPage/',
		component: JointlyEmailListPage,
	},
	{
		name: 'JointlyEmailDetailsPage',
		path: '/jointlyEmailDetailsPage/:id/',
		component: JointlyEmailDetailsPage,
	},
	{
		name: 'AddJointlyEmailPage',
		path: '/addJointlyEmailPage/',
		component: AddJointlyEmailPage,
	},
	{
		name: 'JointlyEmailSuccessPage',
		path: '/jointlyEmailSuccessPage/',
		component: JointlyEmailSuccessPage,
	},
	
	
	{
		name: 'ActivityListPage',
		path: '/activityListPage/',
		component: ActivityListPage,
	},
	{
		name: 'AddActivityPage',
		path: '/addActivityPage/',
		component: AddActivityPage,
	},
	{
		name: 'ActivityDetailsPage',
		path: '/activityDetailsPage/:id/',
		component: ActivityDetailsPage,
	},
	{
		name: 'ActivitySuccessPage',
		path: '/activitySuccessPage/',
		component: ActivitySuccessPage,
	},
	{
		name: 'EditActivityPage',
		path: '/editActivityPage/:id/',
		component: EditActivityPage,
	},
	
	
	
	
	{
		name: 'joinWXRoomPage',
		path: '/joinWXRoomPage/:sign/',
		component: JoinWXRoomPage,
		isOpen:true,
	},
	{
		name: 'joinWXRoomSuccessPage',
		path: '/joinWXRoomSuccessPage/',
		component: JoinWXRoomSuccessPage,
	},
	

	{
		name: 'showDevPage',
		path: '/showDevPage/',
		component: ShowDevPage,
		isOpen:true,
	},
	{
		name: 'addNoticePage',
		path: '/addNoticePage/',
		component: AddNoticePage,
		isOpen:true,
	},
	{
		name: 'noticeSuccessPage',
		path: '/noticeSuccessPage/',
		component: NoticeSuccessPage,
		isOpen:true,
	},




	{
		path: '/dynamic-route/blog/:blogId/post/:postId/',
		component: DynamicRoutePage,
	},
	{
		path: '/request-and-load/user/:userId/',
		async: function(routeTo, routeFrom, resolve, reject) {
			// Router instance
			var router = this;

			// App instance
			var app = router.app;

			// Show Preloader
			app.preloader.show();

			// User ID from request
			var userId = routeTo.params.userId;

			// Simulate Ajax Request
			setTimeout(function() {
				// We got user data from request
				var user = {
					firstName: 'Vladimir',
					lastName: 'Kharlampidi',
					about: 'Hello, i am creator of Framework7! Hope you like it!',
					links: [{
							title: 'Framework7 Website',
							url: 'http://framework7.io',
						},
						{
							title: 'Framework7 Forum',
							url: 'http://forum.framework7.io',
						},
					]
				};
				// Hide Preloader
				app.preloader.hide();

				// Resolve route to load page
				resolve({
					component: RequestAndLoad,
				}, {
					context: {
						user: user,
					}
				});
			}, 1000);
		},
	},
	{
		path: '(.*)/photos/',
		redirect: function(router, resolve, reject){
			resolve('/');
		},
		isOpen:true
	},
	{
		path: '(.*)',
		component: NotFoundPage,
	},
];

function redirectFn(router, resolve, reject) {
	if (!$.isYZ) {
		getHTML("userInfo", function(userInfo) {
			if (!!userInfo) {
				$.user = userInfo;
				$.isYZ = true;
				if(!window.sockWs)
					window.connectWS();
				router.route.redirect=null;
				resolve(router.url);
			} else {
				resolve('/login/');
			}
		});
	}else{
		router.route.redirect=null;
		resolve(router.url);
	}
}

for (var i = 0; i < routes.length; i++) {
	var r = routes[i];
	if (!r.hasOwnProperty("options") || !r.options.hasOwnProperty("transition")) {
		if (!r.hasOwnProperty("options")) {
			r.options = {};
		}
		r.options.transition = 'f7-cover'; //f7-parallax
	}
	if(r.isOpen)
		continue;
	r.redirect=redirectFn;
}
window.loginRedirectFn=redirectFn;

export default routes;
