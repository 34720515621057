
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import Framework7 from 'framework7/framework7.esm.bundle.js';
import $$ from 'dom7';
export default {
  data: function () {
    return {
      isEmpty: false,
      emptyText: "使劲加载中..."
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      function loadDoorCode(fn) {
        var gdInfo = {
          "hdlt": {
            "ownerID": "531620",
            "visitorName": "业主",
            "precinctID": 552,
            "houseName": "",
            "visitorNum": "20",
            "visitDate": $.getDate()
          },
          "dsgc": {
            "contactPhone": "",
            "ownerID": "531620",
            "visitorName": "业主",
            "precinctID": 514,
            "houseName": "",
            "visitorNum": "20",
            "visitDate": $.getDate()
          }
        };
        Framework7.request({
          url: "https://pm03.evergrande.com/property-service/wxservice/wx",
          method: "POST",
          data: JSON.stringify({
            "Request": {
              "Head": {
                "NWVersion": 1,
                "NWCode": 1707058,
                "NWGUID": "2010072115220907818261",
                "NWExID": "o0L7tv73KJ_EnrygUQ7NLXRNPFng"
              },
              "Data": gdInfo[window.projectMark] || gdInfo["dsgc"]
            }
          }),
          processData: false,
          success: function (json) {
            json = JSON.parse(json);
            if (json.Response.Data.NWRespCode == "4000") {
              self.$setState({
                isEmpty: true,
                emptyText: "今日已达到使用上限"
              });
            }
            if (json.Response.Data.NWRespCode == "0000" && json.Response.Data.Record.length > 0) {
              var code = json.Response.Data.Record[0].qrCode;
              if (!!fn) fn(code);
            }
          },
          error: function () {
            $.toast("访客码生成失败!");
          }
        });
      }
      $$(".regTypeDiv").on("click", function () {
        if (["hdlt", "dsgc"].indexOf(window.projectMark) == -1) return $.alt("本小区暂未开通此功能");
        self.$setState({
          isEmpty: true
        });
        function toUrl(code) {
          self.$router.navigate("/showCode/" + encodeURIComponent("https://pm03.evergrande.com/wxfront/visitorsQrcode.html?type=1&code=" + code + "&name=业主" + "&time=" + $.getDate() + "&address=" + proInfoList[projectMark].name + "&visitorNum=20&oname=业主&share=true&from=singlemessage&isappinstalled=0") + "/");
          // window.location.href="https://pm03.evergrande.com/wxfront/visitorsQrcode.html?type=1&code="+code+"&name=业主"+"&time="+$.getDate()+"&address="+proInfoList[projectMark].name+"&visitorNum=20&oname=业主&share=true&from=singlemessage&isappinstalled=0";
        }

        getHTML("doorQRCode" + $.getDate(), function (code) {
          if (!!code) {
            self.$setState({
              isEmpty: false
            });
            toUrl(code);
          } else {
            loadDoorCode(function (code) {
              self.$setState({
                isEmpty: false
              });
              addHTML("doorQRCode" + $.getDate(), code);
              toUrl(code);
            });
          }
        });
      });
    }
  },
  id: '37c308b1bb',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><style>.regTypeDiv{\r\n			    width: 60%;\r\n			    height: 220px;\r\n			    text-align: center;\r\n			    background-color: #FFFFFF;\r\n			    margin: 0 auto;\r\n			    margin-top: 30px;\r\n			    box-shadow: 0px 0px 5px #7a7a7a;\r\n			    position: relative;\r\n				margin-bottom: 50px;\r\n				border-radius: 5px;\r\n			}\r\n			.regTypeDiv>i{\r\n				font-size: 150px;\r\n				margin-top: 20px;\r\n				color: #2196f3;\r\n				pointer-events: none;\r\n			}\r\n			.regTypeDiv>span{\r\n				display: block;\r\n				width: 100%;\r\n				height: 30px;\r\n				font-size: 20px;\r\n				font-weight: bold;\r\n			    position: absolute;\r\n				bottom: 25px;\r\n				pointer-events: none;\r\n			}\r\n			.regTypeDiv.active-state{\r\n				box-shadow:inset 0px 0px 5px #7a7a7a;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>门禁卡</div></div></div><div class=page-content>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isEmpty, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("使劲加载中..."), ctx_2);
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="regTypeDiv show"><i class=f7-icons>qrcode</i><!-- <svg class="icon iconBlue" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M921.6 614.4 921.6 512 1024 512 1024 614.4 921.6 614.4ZM921.6 0 1024 0 1024 409.6 921.6 409.6 921.6 0ZM716.8 204.8 819.2 204.8 819.2 614.4 716.8 614.4 716.8 204.8ZM716.8 0 819.2 0 819.2 102.4 716.8 102.4 716.8 0ZM1024 1024 716.8 1024 716.8 716.8 1024 716.8 1024 1024ZM921.6 819.2 819.2 819.2 819.2 921.6 921.6 921.6 921.6 819.2ZM204.8 921.6 614.4 921.6 614.4 1024 204.8 1024 204.8 921.6ZM512 716.8 614.4 716.8 614.4 819.2 512 819.2 512 716.8ZM0 0 614.4 0 614.4 614.4 0 614.4 0 0ZM102.4 512 512 512 512 102.4 102.4 102.4 102.4 512ZM204.8 204.8 409.6 204.8 409.6 409.6 204.8 409.6 204.8 204.8ZM409.6 819.2 0 819.2 0 716.8 409.6 716.8 409.6 819.2ZM102.4 1024 0 1024 0 921.6 102.4 921.6 102.4 1024Z"></path></svg> --> <span>生成访客码</span></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `
			.regTypeDiv{
			    width: 60%;
			    height: 220px;
			    text-align: center;
			    background-color: #FFFFFF;
			    margin: 0 auto;
			    margin-top: 30px;
			    box-shadow: 0px 0px 5px #7a7a7a;
			    position: relative;
				margin-bottom: 50px;
				border-radius: 5px;
			}
			.regTypeDiv>i{
				font-size: 150px;
				margin-top: 20px;
				color: #2196f3;
				pointer-events: none;
			}
			.regTypeDiv>span{
				display: block;
				width: 100%;
				height: 30px;
				font-size: 20px;
				font-weight: bold;
			    position: absolute;
				bottom: 25px;
				pointer-events: none;
			}
			.regTypeDiv.active-state{
				box-shadow:inset 0px 0px 5px #7a7a7a;
			}
		`,
  styleScoped: false
};
    