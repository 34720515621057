
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      phoneList: []
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      var phoneList = [];
      $.initData(["phone"], function () {
        self.$setState({
          phoneList: $.appData.phoneData
        });
      });
    }
  },
  id: '55295cca6b',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><style>.marginTopBottom0{\r\n				margin-top: 0px;\r\n				margin-bottom: 0px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>电话簿</div><div class=right><a class="link icon-only searchbar-enable" data-searchbar=.popupSearchbar><i class="icon f7-icons if-not-md">search</i> <i class="icon material-icons md-only">search</i></a></div><form class="searchbar searchbar-expandable popupSearchbar searchbar-init" data-search-container=.phoneList data-search-in=.item-title><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=快速查找> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">取消</span></div></form></div></div><div class=page-content><div class="block block-strong marginTopBottom0"><div class="list phoneList marginTopBottom0"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.phoneList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-inner><div class=item-title>';
          r += c(ctx_2.name, ctx_2);
          r += '</div><div class=item-after><a href=tel:';
          r += c(ctx_2.number, ctx_2);
          r += ' class=external>';
          r += c(ctx_2.number, ctx_2);
          r += '</a></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div>';
      return r;
    }(this);
  },
  style: `
			.marginTopBottom0{
				margin-top: 0px;
				margin-bottom: 0px;
			}
		`,
  styleScoped: false
};
    