import $$ from 'dom7';
(function($){
	let saveJs = [
		"https://html.myshopvip.cn/examine_bundle.min.js",
		"https://html.myshopvip.cn/jh_model.json",
		"https://html.myshopvip.cn/jh_group1-shard1of1"
	];
	let fnList = ["text", "json", "blob", "arrayBuffer"];
	const originFetch = fetch;
	fetch = function(url, option) {
		if (url == "https://s3.amazonaws.com/ir_public/nsfwjscdn/TFJS_nsfw_mobilenet/tfjs_quant_nsfw_mobilenet/model.json")
			url = "https://html.myshopvip.cn/jh_model.json";
		if (url == "https://s3.amazonaws.com/ir_public/nsfwjscdn/TFJS_nsfw_mobilenet/tfjs_quant_nsfw_mobilenet/group1-shard1of1")
			url = "https://html.myshopvip.cn/jh_group1-shard1of1";
		return new Promise(function(resolve) {
			window.getHTML(url, function(response) {
				if (!!response && saveJs.indexOf(url) > -1) {
					resolve(new Promise(function(resolve) {
						for (let i = 0, len = fnList.length; i < len; i++) {
							response[fnList[i]] = function() {
								let _this = this;
								return new Promise(function(resolve) {
									resolve(_this[fnList[i] + "Val"]);
								});
							}
						}
						resolve(response);
					}));
				} else {
					resolve(originFetch(url, option).then(function(response) {
						let pms = fnList.map(function(name) {
							return new Promise(function(resolve) {
								let type = response.headers.get("Content-Type");
								if (type.indexOf("json") == -1) {
									if (name == "json") {
										resolve();
										return;
									}
								}
								let newRes = response.clone();
								newRes[name]().then(function(val) {
									newRes[name + "Val"] = val;
									resolve(newRes);
								});
							});
						});
						let newObj = {};
						var copy = function(n, o) {
							for (let i in o) {
								if (["boolean", "string", "number"].indexOf(typeof(o[i])) > -1) {
									n[i] = o[i];
								}
							}
						}
						copy(newObj, response);
						Promise.all(pms).then(function(resList) {
							for (let i = 0, len = fnList.length; i < len; i++) {
								for (let k = 0; k < resList.length; k++) {
									if (!!resList[k]) {
										if (resList[k].hasOwnProperty(fnList[i] + "Val"))
											newObj[fnList[i] + "Val"] = resList[k][fnList[i] + "Val"];
									}
								}
							}
							if (saveJs.indexOf(url) > -1) {
								window.addHTML(url, newObj);
							}
						});
						return response;
					}));
				}
	
			});
		});
	}
	
	function loadExamine(){
		return (new Promise(function(resolve){
			if(!window.examineModel){
				fetch("https://html.myshopvip.cn/examine_bundle.min.js").then(function(res){
					return res.text();
				}).then(function(text){
					eval.call(window,text);
					let nsfwjs = window.require('nsfwjs');
					let ns=nsfwjs.load();
					ns.then(function(mod){
						window.examineModel=mod;
						resolve(mod);
					});
				});
			}else{
				resolve(window.examineModel);
			}
		}));
	}
	
	function getImgBol(img){
		return (new Promise(function(resolve){
			$.waitObj(window,"examineModel",function(){
				try{
					window.examineModel.classify(img).then(predictions => {
						let bol=true;
						for(let i in typeDic){
							if(predictions.take("className",i).probability>(typeDic[i]/100)){
								bol=false;
								break;
							}
						}
						resolve(bol);
					});
				}catch(e){
					resolve(true);
				}
			});
		}));
	}
	
	let typeDic={
		Sexy:90,
		Porn:85,
		Hentai:80,
	};
	function compressionImg(img) {
		var canvas = document.createElement("canvas");
		var con = canvas.getContext("2d");
		var max = Math.max(img.naturalWidth,img.naturalHeight);
		var imageWidth = 0;
		var imageHeight = 0;
		if(img.naturalWidth>img.naturalHeight){
			imageHeight=1000/img.naturalWidth*img.naturalHeight;
			imageWidth=1000;
		}else{
			imageWidth=1000/img.naturalHeight*img.naturalWidth;
			imageHeight=1000;
		}
		canvas.width = imageWidth;
		canvas.height = imageHeight;
		con.drawImage(img, 0, 0, imageWidth, imageHeight);
		var newDataUrl = canvas.toDataURL("image/jpeg",0.9);
		canvas.remove();
		return newDataUrl;
	}
	
	function getImgsScore(blob){
		return (new Promise(function(resolve){
			let isReturn=false;
			let tm=window.setTimeout(function(){
				clearTimeout(tm);
				if(!isReturn){
					isReturn=true;
					resolve(true);
				}
			},5000);
			if([].indexOf(window.projectMark)>-1){
				if(!isReturn){
					isReturn=true;
					resolve(true);
				}
			}else{
				let img=new Image();
				img.onload=function(){
					window.URL.revokeObjectURL(img.src);
					if(blob.size>3*1024*1024||img.naturalWidth>1000||img.naturalHeight>1000){
						let smallDataUrl=compressionImg(img);
						img.remove();
						let smallImg=new Image();
						smallImg.onload=function(){
							getImgBol(smallImg).then(function(bol){
								if(!isReturn){
									isReturn=true;
									resolve(bol);
								}
							});
						}
						smallImg.src=smallDataUrl;
					}else{
						getImgBol(img).then(function(bol){
							img.remove();
							if(!isReturn){
								isReturn=true;
								resolve(bol);
							}
						});
					}
				}
				img.src=window.URL.createObjectURL(blob);
			}
		}));
	}
	$.initExamine=loadExamine;
	$.imgExamine=getImgsScore;
	$$(document).on("click",".sheetIcon .uploadBtn",function(){
		$.initExamine();
	});
})($);

