import $$ from 'dom7';
export default {
  data: function () {
    return {};
  },
  methods: {
    toHome() {
      var self = this;
      self.$router.navigate(`/`);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
    }
  },
  id: '4510c02b93',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page projectDetailsPage" data-name=projectDetails><style>.successListUl>li>a{\r\n				width: auto;\r\n				height: 100%;\r\n				position: absolute;\r\n				right: 10px;\r\n				line-height: 49px;\r\n				color: #3697F7;\r\n			}\r\n			.checkNumLabel{\r\n				width: 280px;\r\n				font-size: 30px;\r\n				text-align: center;\r\n				margin: 0 auto;\r\n			}\r\n			.checkNumLabel.small{\r\n				font-size: 15px;\r\n				margin-top: 15px;\r\n				font-weight: 300;\r\n			}\r\n			.footerInfo{\r\n				width: 100%;\r\n			    font-size: 14px;\r\n			    color: #868686;\r\n			    text-align: center;\r\n			    position: absolute;\r\n			    bottom: 20px;\r\n			}\r\n			.footerInfo>a{\r\n			    color: #5894B9;\r\n			}\r\n			\r\n			.projectReplySuccessIcon{\r\n				margin: auto;\r\n				margin-top: 80px;\r\n				margin-bottom: 80px;\r\n			\r\n				--tick-icon-block-width-height: 120px;\r\n				--tick-icon-border-size: 6px;\r\n				--tick-icon-border-color: #2196f3;\r\n				--tick-icon-line-right-fix:0.6075;\r\n			}\r\n			.md .projectReplySuccessIcon{\r\n				--tick-icon-background-color:#FFFFFF;\r\n			}\r\n			.ios .projectReplySuccessIcon{\r\n				--tick-icon-background-color:#efeff4;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=title>';
      r += c(ctx_1.$route.params.text, ctx_1);
      r += '拼车</div></div></div><div class=page-content><div class="tick-icon projectReplySuccessIcon"><span class=tick-icon-line-left></span> <span class=tick-icon-line-right></span><div class=tick-icon-circular-line-left></div><div class=tick-icon-circular-line-right></div><div class=tick-icon-ring></div><div class=tick-icon-fix></div></div><h1 class="checkNumLabel resultSuccessTitle">';
      r += c(ctx_1.$route.params.text, ctx_1);
      r += '完成</h1><h1 class="checkNumLabel small resultSuccessMessage">您的拼车信息已经';
      r += c(ctx_1.$route.params.text, ctx_1);
      r += '<br>请耐心等待业主们的加入</h1><div class="block no-hairlines"><button class="button button-large button-fill projectSuccessToHomeBtn" @click=toHome>返回首页</button></div><footer class="footerInfo resultSuccessPhone">如有问题请联系业主平台管理员</footer></div></div>';
      return r;
    }(this);
  },
  style: `
			
			.successListUl>li>a{
				width: auto;
				height: 100%;
				position: absolute;
				right: 10px;
				line-height: 49px;
				color: #3697F7;
			}
			.checkNumLabel{
				width: 280px;
				font-size: 30px;
				text-align: center;
				margin: 0 auto;
			}
			.checkNumLabel.small{
				font-size: 15px;
				margin-top: 15px;
				font-weight: 300;
			}
			.footerInfo{
				width: 100%;
			    font-size: 14px;
			    color: #868686;
			    text-align: center;
			    position: absolute;
			    bottom: 20px;
			}
			.footerInfo>a{
			    color: #5894B9;
			}
			
			.projectReplySuccessIcon{
				margin: auto;
				margin-top: 80px;
				margin-bottom: 80px;
			
				--tick-icon-block-width-height: 120px;
				--tick-icon-border-size: 6px;
				--tick-icon-border-color: #2196f3;
				--tick-icon-line-right-fix:0.6075;
			}
			.md .projectReplySuccessIcon{
				--tick-icon-background-color:#FFFFFF;
			}
			.ios .projectReplySuccessIcon{
				--tick-icon-background-color:#efeff4;
			}
		`,
  styleScoped: false
};