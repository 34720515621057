
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      userList: [],
      stateDic: {
        "999": "已封禁",
        "1": "已认证",
        "2": "准业主"
      },
      stateColor: {
        "0": "",
        "1": "color-green",
        "2": "color-blue"
      }
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.initData(["user", "room", "carpooling"], function () {
        var carpooling = $.appData.carpoolingData.take("id", self.$route.params.id);
        if (!!carpooling.userarr) {
          var userarr = carpooling.userarr.split(",");
          var userList = [];
          userarr.each(function (n) {
            var uObj = $.appData.userData.take("id", n);
            var room = $.appData.roomData.take("id", uObj.rid);
            var obj = {
              name: uObj.name,
              img: uObj.img,
              roomno: room.roomno,
              uid: uObj.id,
              stateColor: self.stateColor[uObj.state],
              stateName: self.stateDic[uObj.state]
            };
            userList.push(obj);
          });
          self.$setState({
            userList: userList
          }, function () {
            $.setImg(".joinUserListDiv");
          });
        }
      });
    }
  },
  id: '233128b68f',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page addCarpooling" data-name=addCarpooling><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>已加入的业主</div></div></div><div class=page-content><div class="list media-list joinUserListDiv"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.userList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/userInfo/';
          r += c(ctx_2.uid, ctx_2);
          r += '/ class="item-link item-content"><div class=item-media><img url=';
          r += c(ctx_2.img, ctx_2);
          r += ' width=44></div><div class=item-inner><div class=item-title-row><div class=item-title>';
          r += c(ctx_2.name, ctx_2);
          r += ' <span class="badge ';
          r += c(ctx_2.stateColor, ctx_2);
          r += '">';
          r += c(ctx_2.stateName, ctx_2);
          r += '</span></div></div><div class=item-subtitle>';
          r += c(ctx_2.roomno, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div>';
      return r;
    }(this);
  },
  style: `

`,
  styleScoped: false
};
    