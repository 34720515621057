
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      isLoding: false,
      dw: ""
    };
  },
  methods: {
    updateCommoditySubmit(e) {
      var self = this;
      var app = self.$app;
      self.$setState({
        isLoding: true
      });
      var obj = app.form.convertToData("#updateCommodityForm");
      ($.baseUrl + "/commodityUpdateInfo?_=" + new Date().getTime()).p(function (json) {
        self.$setState({
          isLoding: false
        });
        if (+json.code == 1) {
          $.toast("已修改");
          var commodity = $.appData.commodityData.take("id", self.$route.params.id);
          Object.assign(commodity, {
            price: obj.price,
            introduce: obj.introduce
          });
          self.$router.back();
        }
      }, {
        id: self.$route.params.id,
        price: obj.price,
        introduce: obj.introduce
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.initData(["commodity"], function () {
        var commodity = $.appData.commodityData.take("id", self.$route.params.id);
        app.form.fillFromData("#updateCommodityForm", commodity);
        self.$setState({
          dw: commodity.company
        });
      });
    }
  },
  id: 'ee6ec8bed9',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=updateCommodity><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>编辑信息</div></div></div><div class=page-content><form id=updateCommodityForm><div class=block-title>请填写需要修改的信息</div><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">价格(元)/';
      r += c(ctx_1.dw, ctx_1);
      r += '</div><div class=item-input-wrap><input type=number name=price placeholder=点击此处填写价格> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">说明</div><div class=item-input-wrap><textarea name=introduce placeholder=点击此处填写商品说明></textarea></div></div></li></ul></div><div class="block no-hairlines">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isLoding, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <button class="button button-large button-fill"><span class="preloader color-white"></span></button> ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <button class="button button-large button-fill" @click=updateCommoditySubmit>修改</button> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></form></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    