
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      imgCoverList: [],
      showImgListObj: "",
      skeleton: true
    };
  },
  methods: {
    initData(isNew) {
      var self = this;
      var app = self.$app;
      $.initData(["abodeImg"], function () {
        var typeList = $.distinctCol($.appData.abodeImgData, "type");
        typeList = typeList.sort(function (a, b) {
          var a = parseInt(a);
          a = isNaN(a) ? 0 : a;
          var b = parseInt(b);
          b = isNaN(b) ? 0 : b;
          return a - b;
        });
        var showData = [];
        for (var i in typeList) {
          if (typeList.hasOwnProperty(i)) {
            var typeName = typeList[i];
            var obj = {};
            obj.type = typeName;
            var imgList = $.dataFind($.appData.abodeImgData, {
              "type": typeName
            });
            var timeList = $.distinctCol(imgList, "time");
            var showDataArr = [];
            timeList.each(function (time) {
              showDataArr.push({
                "time": time,
                "list": $.dataFind(imgList, {
                  "time": time
                })
              });
            });
            showDataArr = $.jsonSort(showDataArr, "time", "desc");
            obj.url = imgList[0].url;
            obj.imgList = showDataArr;
            showData.push(obj);
          }
        }
        self.$setState({
          imgCoverList: showData,
          skeleton: false
        }, function () {
          $.setImg(".imgCardHeight .card-header");
          $$(".imgCardHeight").on("card:open", function (e) {
            if (!!isNew) $.setImg(e.currentTarget, null, null, true, null);else $.setImg(e.currentTarget);
          });
          $$(".imgCardHeight .uploadListUl li").off("click").on("click", function () {
            var that = $$(this);
            var imgList = that.parent().find("img");
            var imgArr = [];
            app.preloader.show();
            function eachImgArr(i) {
              if (imgList.hasOwnProperty(i)) {
                $.loadLookImg($$(imgList[i]).attr("url"), function (path) {
                  imgArr.push(path);
                  eachImgArr(i + 1);
                });
              } else {
                self.showImgListObj = app.photoBrowser.create({
                  photos: imgArr,
                  theme: 'dark',
                  type: 'popup',
                  popupCloseLinkText: "关闭"
                });
                self.showImgListObj.activeIndex = +that.index();
                app.preloader.hide();
                self.showImgListObj.open();
              }
            }
            eachImgArr(0);
          });
        });
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      if (!$.appData.hasOwnProperty("abodeImgData")) {
        var tm = window.setTimeout(function () {
          clearTimeout(tm);
          self.initData();
        }, 600);
      } else {
        self.initData();
      }
    },
    pageAfterIn: function () {
      var self = this;
      var app = self.$app;
      if ($.addImg_state) {
        self.initData(true);
      }
      $.addImg_state = false;
    }
  },
  id: '44f097261e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=room><style lang=less scoped>.imgCardHeight {\r\n				height: 150px;\r\n			}\r\n			.imgCardHeight .imgCover{\r\n				width: 100%;\r\n				height: 150px;\r\n				position: absolute;\r\n				left: 0px;\r\n				top: 0px;\r\n			}\r\n			.imgCardHeight .imgSpan{\r\n				position: absolute;\r\n				width: 100%;\r\n				height: 40px;\r\n				line-height: 40px;\r\n				background-color: rgba(0, 0, 0, 0.5);\r\n				left: -40%;\r\n				top: 25%;\r\n				text-align: center;\r\n				transform: rotate(-35deg);\r\n				-webkit-transform: rotate(-35deg);\r\n				font-size: 16px;\r\n			}\r\n			\r\n			.uploadListUl {\r\n				width: 100%;\r\n				height: auto;\r\n				font-size: 0;\r\n				box-sizing: border-box;\r\n				border-bottom: 1px #e2e2e2 solid;\r\n				padding: 0px;\r\n			}\r\n			\r\n			.uploadListUl>li {\r\n				width: 30%;\r\n				display: inline-block;\r\n				box-sizing: border-box;\r\n				height: 28vw;\r\n				border: 1px #D8D8D8 solid;\r\n				position: relative;\r\n				line-height: 80vw;\r\n				cursor: pointer;\r\n				margin-left: 2.5%;\r\n				margin-bottom: 2.5%;\r\n			}\r\n			\r\n			.uploadListUl>li>img {\r\n				width: 100%;\r\n				height: 100%;\r\n				position: absolute;\r\n				top: 0;\r\n				left: 0;\r\n				z-index: 5\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>小区相册</div><div class=right><a href=/addImg/ class=link>上传</a></div></div></div><div class=page-content>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.skeleton, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, "1,2", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div style="width: 92%;\r\n					max-width: 670px;\r\n					height: 150px; \r\n					display: inline-block;\r\n					position: relative;\r\n					border-radius: var(--f7-card-expandable-border-radius);\r\n					margin-left: calc(var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-left));\r\n					margin-right: calc(var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-right));\r\n					margin-top: var(--f7-card-expandable-margin-vertical);" class="skeleton-block skeleton-effect-blink"></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.imgCoverList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="card card-expandable imgCardHeight" data-hide-statusbar-on-open=false><div class=card-content><div class=bg-color-blue style="height: 150px"><div class="card-header text-color-white display-block"><img class=imgCover url=';
              r += c(ctx_3.url, ctx_3);
              r += '> <span class=imgSpan>';
              r += c(ctx_3.type, ctx_3);
              r += '</span></div><a href=# class="link card-close card-opened-fade-in color-white" style="position: absolute; right: 15px; top: 15px"><i class=f7-icons>multiply_circle_fill</i></a></div><div class=card-content-padding><div class="block block-strong">';
              r += Template7Helpers.each.call(ctx_3, ctx_3.imgList, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=block-title>';
                  r += c(ctx_4.time, ctx_4);
                  r += '</div><ul class=uploadListUl>';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.list, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li><img url=';
                      r += c(ctx_5.url, ctx_5);
                      r += '></li>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</ul>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `


`,
  styleScoped: false
};
    