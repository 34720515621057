
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      skeleton: true,
      dataArr: []
    };
  },
  methods: {
    searchSubmit(e) {
      var self = this;
      e.preventDefault();
      var searchValue = e.target.querySelector('input').value;
      self.renderData(searchValue);
    },
    initData(e) {
      var self = this;
      var app = self.$app;
      $.globalInitData(["project"], function () {
        self.renderData && self.renderData();
      });
    },
    renderData(search) {
      var self = this;
      var app = self.$app;
      var dataArr = $.dataFind($.appData.projectData, {
        "state": "1",
        "name": !!search ? "%" + search + "%" : ""
      });
      dataArr = $.jsonSort(dataArr, "weight", "desc");
      var typeArr = $.distinctCol(dataArr, "type");
      var arr = [];
      for (var i = 0, len = typeArr.length; i < len; i++) {
        arr.push({
          type: typeArr[i],
          arr: $.dataFind(dataArr, {
            "type": typeArr[i]
          })
        });
      }
      self.$setState({
        skeleton: false,
        dataArr: arr
      }, function () {
        var listIndex = app.listIndex.create({
          el: '.projectListIndex',
          listEl: '.projectListData',
          indexes: 'auto',
          scrollList: true,
          label: true
        });
        $.setImg(".projectListData");
      });
      var searchbar = app.searchbar.create({
        el: ".popupSearchbar",
        customSearch: true,
        on: {
          searchbarClear: function () {
            self.renderData();
          },
          searchbarSearch: function (sbar) {
            if (!sbar.query) {
              self.renderData();
            }
          }
        }
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      if (!$.appData.hasOwnProperty("projectData")) {
        var tm = window.setTimeout(function () {
          clearTimeout(tm);
          self.initData();
        }, 600);
      } else {
        self.renderData();
      }
      var $ptrContent = $$('.projectListMainContent');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.projectData;
          $.globalInitData(["project"], function () {
            self.renderData();
            $ptrContent[0].f7PullToRefresh.done();
          });
        }, 500);
      });
    }
  },
  id: '27534b1c52',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page projectServicePage" data-name=emoticon><style>.projectServicePage {\r\n			  --f7-list-index-width: 30px;\r\n			  --f7-list-index-font-size: 14px;\r\n			  --f7-list-index-item-height: 25px;\r\n			}\r\n			.projectServicePage .projectSimpleList li{\r\n				white-space: nowrap;\r\n				text-overflow: ellipsis;\r\n				max-width: 100%;\r\n				box-sizing: border-box;\r\n				display: flex;\r\n				justify-content: space-between;\r\n				align-items: center;\r\n				align-content: center;\r\n				height: auto;\r\n				padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));\r\n				padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));\r\n			}\r\n			.projectServicePage .list .item-text{\r\n				white-space: normal;\r\n			}\r\n			.projectServicePage .list .item-content{\r\n				width: 100%;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>社区服务</div><div class=right><a class="link icon-only searchbar-enable" data-searchbar=.popupSearchbar><i class="icon f7-icons if-not-md">search</i> <i class="icon material-icons md-only">search</i></a></div><form class="searchbar searchbar-expandable popupSearchbar" @submit=searchSubmit><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=搜索> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class=searchbar-disable-button>取消</span></div></form></div></div><div class="list-index projectListIndex"></div><div class="page-content projectListMainContent ptr-content" data-ptr-distance=55 data-ptr-mousewheel=true><div class=ptr-preloader><div class="preloader color-multi"></div><div class=ptr-arrow></div></div><div class="list projectSimpleList contacts-list projectListData">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.skeleton, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, '1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="list-group media-list"><ul><li class=list-group-title><div class=item-media><div style="width: 50px;\r\n										height: 20px; \r\n										display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div></li>';
              r += Template7Helpers.each.call(ctx_3, '1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><a href=/projectDetails/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/ class="item-link item-content"><div class=item-media><div style="width: 80px;\r\n												height: 80px; \r\n												display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div><div class=item-inner><div class=item-title-row><div class=item-title><div style="width: 240px;\r\n														height: 24px; \r\n														display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div><div class=item-after></div></div><div class=item-subtitle><div style="width: 180px;\r\n														height: 24px; \r\n														display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div><div class=item-text><div style="width: 280px;\r\n													height: 40px; \r\n													display: inline-block;" class="skeleton-block skeleton-effect-blink"></div></div></div></a></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.dataArr.length > 0, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.dataArr, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="list-group media-list"><ul><li class=list-group-title>';
              r += c(ctx_3.type, ctx_3);
              r += '</li>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.arr, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><a href=/projectDetails/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/ class="item-link item-content"><div class=item-media><img url="';
                  r += c(ctx_4.img.split('|')[0], ctx_4);
                  r += '" width=80></div><div class=item-inner><div class=item-title-row><div class=item-title>';
                  r += c(ctx_4.name, ctx_4);
                  r += '</div><div class=item-after></div></div><div class=item-subtitle>&yen;';
                  r += Template7Helpers.js.call(ctx_4, "!!this.price?this.price:'免费'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div><div class=item-text>';
                  r += c(ctx_4.introduce, ctx_4);
                  r += '</div></div></a></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },
  style: `
			.projectServicePage {
			  --f7-list-index-width: 30px;
			  --f7-list-index-font-size: 14px;
			  --f7-list-index-item-height: 25px;
			}
			.projectServicePage .projectSimpleList li{
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-content: center;
				height: auto;
				padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));
				padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
			}
			.projectServicePage .list .item-text{
				white-space: normal;
			}
			.projectServicePage .list .item-content{
				width: 100%;
			}
		`,
  styleScoped: false
};
    