
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      checkImgList: [],
      showImgListObj: "",
      isImgBlockShow: true,
      isVideoBlockShow: true,
      videoPath: "",
      videoFile: ""
    };
  },
  methods: {
    fileImgCheck(e) {
      var self = this;
      var input = e.currentTarget;
      self.$setState({
        isVideoBlockShow: false
      });
      $.fileImgAdd(input, self.checkImgList, function () {
        self.$setState({
          isVideoBlockShow: true
        });
      });
    },
    fileVideoCheck(e) {
      var self = this;
      var app = self.$app;
      self.$setState({
        isImgBlockShow: false
      });
      var input = e.currentTarget;
      var files = input.files;
      if (files.length > 0) {
        var videoBlob = window.URL.createObjectURL(files[0]);
        self.$setState({
          videoPath: videoBlob
        });
        self.videoFile = files[0];
      }
    },
    addCircleInfo() {
      var self = this;
      var app = self.$app;
      var val = self.textEditor.getValue();
      if (!val) {
        return $.toast("说点什么吧...");
      }
      if (self.checkImgList.length > 9) return $.toast("最多可上传9张照片");
      if (val.length > 1200) return $.toast("评价最多1200个字!");
      var obj = {
        content: val
      };
      function uploadVideo() {
        var dialog = app.dialog.progress('上传', 0);
        dialog.setText('视频正在上传中..');
        uploadFile(self.videoFile, function (url, imgUrl) {
          dialog.setText('视频上传完成,发布中...');
          obj.img = url;
          if (!!imgUrl) obj.img += "|" + imgUrl;
          obj.type = 4;
          obj.support = "";
          obj.cuser = $.user.id;
          obj.ctime = $.getTime();
          obj.utime = $.getTime();
          $.appData.circleData.add(obj, function () {
            dialog.close();
            self.$router.back();
            $.addCircle_state = true;
          });
        }, function (e) {
          var num = e.loaded / e.total;
          var numval = parseInt(num * 100);
          if (numval >= 100) numval = 100;
          dialog.setProgress(numval);
          if (numval == 100) dialog.setText('视频封面生成中..');
        }, "mp4");
      }
      $.initData(["circle"], function () {
        if (self.isImgBlockShow) {
          $.uploadImgList(self.checkImgList, function (list) {
            obj.type = 1;
            obj.support = "";
            obj.img = list.join("|");
            obj.cuser = $.user.id;
            obj.ctime = $.getTime();
            obj.utime = $.getTime();
            app.dialog.preloader("发布中...");
            $.appData.circleData.add(obj, function () {
              app.dialog.close();
              self.$router.back();
              $.addCircle_state = true;
            });
          });
        } else {
          uploadVideo();
        }
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      self.textEditor = app.textEditor.create({
        el: '#circleReplayEditor',
        placeholder: `说点什么吧...`,
        buttons: [["bold", "italic", "underline", "strikeThrough"], ["orderedList", "unorderedList"]]
      });
    }
  },
  id: '7826759dfa',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=catalog><style>#circleReplayEditor>.text-editor-content{\r\n			  min-height: 120px;\r\n		  }</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>发布动态</div></div></div><div class=page-content><div id=circleReplayEditor class="text-editor text-editor-resizable"><div class=text-editor-content contenteditable></div></div><div class=block style="width: 100%;"><div class=row>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isImgBlockShow, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo</i> <i class="f7-icons if-not-md">photo_fill</i> <input class=uploadBtn @change=fileImgCheck type=file multiple=multiple accept=image/*></div></div><div class=sheetIconLabel>相册</div></div><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo_camera</i> <i class="f7-icons if-not-md">camera_fill</i> <input class=uploadBtn @change=fileImgCheck type=file capture=camera accept=image/*></div></div><div class=sheetIconLabel>拍照</div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=col-25>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isVideoBlockShow, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=sheetIcon><div><i class="material-icons md-only">videocam</i> <i class="f7-icons if-not-md" style="font-size: 35px;line-height: 36px;">videocam_fill</i> <input class=uploadBtn @change=fileVideoCheck type=file accept=video/*></div></div><div class=sheetIconLabel>视频</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><div class="block block-strong checkImgListDiv" style="display: none;"><div class="list checkImgList media-list sortable sortable-enabled"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.checkImgList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.videoPath, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="block block-strong videoBlock"><video controls preload=none><source src=';
          r += c(ctx_2.videoPath, ctx_2);
          r += ' type=video/mp4></video></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="padding-horizontal padding-bottom"><a class="button button-large button-fill" @click=addCircleInfo>发布</a></div></div></div>';
      return r;
    }(this);
  },
  style: `
		  #circleReplayEditor>.text-editor-content{
			  min-height: 120px;
		  }
	  `,
  styleScoped: false
};
    