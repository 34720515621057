
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      commodityList: [],
      menuList: [{
        id: 3,
        name: "待配送",
        num: 0
      }, {
        id: 6,
        name: "已送达",
        num: 0
      }],
      cartInfo: [],
      sumPrice: 0,
      showStateType: 3,
      myCommoditySheetModal: "",
      remark: ""
    };
  },
  methods: {
    initData() {
      var self = this;
      var app = self.$app;
      Promise.all([new Promise(function (resolve, reject) {
        if (!!$.appData && !!$.appData.allCommodityOrderData) {
          resolve();
        } else {
          ($.baseUrl + "/allCommodityOrder?_=" + new Date().getTime()).g(function (json) {
            if (+json.code == 1) {
              $.appData.allCommodityOrderData = json.data;
            }
            resolve();
          });
        }
      }), new Promise(function (resolve, reject) {
        $.initData(["user", "commodity"], function () {
          resolve();
        });
      })]).then(function () {
        var stateDic = {
          "3": "待配送",
          "6": "已送达"
        };
        var stateColor = {
          "3": "color-red",
          "6": "color-green"
        };
        var arr = [];
        $.appData.allCommodityOrderData.each(function (fObj) {
          if (fObj.state != self.showStateType) return;
          var obj = {};
          obj.id = fObj.id;
          var content = JSON.parse(fObj.content);
          var idList = Object.keys(content);
          if (idList.length > 0) {
            var commodity = $.appData.commodityData.take("id", idList[0]);
            obj.title = commodity.name + "等共计" + idList.length + "件商品";
          }
          obj.phone = fObj.phone;
          obj.img = fObj.img;
          obj.realname = fObj.realname;
          obj.sex = fObj.sex;
          obj.roomno = fObj.roomno;
          obj.jName = obj.realname.substring(0, 1) + (obj.sex == "男" ? "先生" : "女士");
          obj.state = fObj.state;
          obj.remark = fObj.remark;
          obj.actualPrice = fObj.actualPrice;
          obj.ctime = fObj.ctime.replace(/:\d{2}$/, "");
          arr.push(obj);
        });
        self.menuList.each(function (obj) {
          if (+obj.id > 0) obj.num = $.dataFind($.appData.allCommodityOrderData, {
            state: obj.id
          }).length;else obj.num = $.appData.allCommodityOrderData.length;
        });
        self.$setState({
          commodityList: arr
        }, function () {
          $.setImg(".myCommodityList");
        });
      });
    },
    tabClick(e) {
      var self = this;
      var a = e.currentTarget;
      var tInner = $$(a).parent();
      var inner = tInner[0];
      $$(".myCommodityPage .toolbar-inner>a.active").removeClass("active");
      $$(a).addClass("active");
      self.showStateType = $$(a).attr("val");
      self.initData();
    },
    showDetailsInfo(id) {
      var self = this;
      var commodityOrder = $.appData.allCommodityOrderData.take("id", id);
      var content = JSON.parse(commodityOrder.content);
      var cartInfo = [];
      var sumPrice = 0;
      for (var i in content) {
        if (content.hasOwnProperty(i)) {
          var commodity = $.appData.commodityData.take("id", i);
          cartInfo.push({
            name: commodity.name,
            price: commodity.price,
            company: commodity.company,
            count: content[i]
          });
          sumPrice += commodity.price * content[i];
        }
      }
      self.$setState({
        cartInfo: cartInfo,
        sumPrice: sumPrice,
        remark: commodityOrder.remark
      }, function () {
        self.myCommoditySheetModal.open();
      });
    },
    serviceRoom(id) {
      var self = this;
      var commodityOrder = $.appData.allCommodityOrderData.take("id", id);
      ($.baseUrl + "/serviceOrder?_=" + new Date().getTime()).p(function (json) {
        if (+json.code == 1) {
          $.toast("该订单已送达");
          commodityOrder.state = 6;
          self.initData();
        }
      }, {
        id: id
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $$(".myCommodityPage .toolbar-inner>a:nth-of-type(1)").addClass("active");
      if (!!$.appData) {
        delete $.appData.allCommodityOrderData;
      }
      self.initData();
      var $ptrContent = $$('.myCommodityPageContent');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.allCommodityOrderData;
          self.initData();
          $ptrContent[0].f7PullToRefresh.done();
        }, 500);
      });
      self.myCommoditySheetModal = app.sheet.create({
        el: '.myCommoditySheetModal',
        swipeToClose: true,
        backdrop: true
      });
    },
    pageBeforeIn: function () {
      var self = this;
      var app = self.$app;
      // var refreshPage = sessionStorage.getItem("refreshPage");
      // if (!!refreshPage && +refreshPage == 1) {
      // 	sessionStorage.removeItem("refreshPage");
      // 	self.initData();
      // }
    }
  },
  id: 'd4b533808e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page myCommodityPage" data-name=myCommodity><style>.myCommodityList .item-after {\r\n				font-size: 16px;\r\n			}\r\n\r\n			.myCommodityPage .toolbar-inner a.active {\r\n				color: #2196F3;\r\n			}\r\n			.swipeout-actions-right .ripple{\r\n				overflow: hidden;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class=link></a></div><div class=title>果蔬订单</div><div class=right><a href=/allCommodityStatePage/ class=link><i class="material-icons md-only">library_books</i> <i class="f7-icons if-not-md">square_favorites</i></a></div></div></div><div class="toolbar tabbar toolbar-top"><div class=toolbar-inner>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.menuList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# @click=tabClick val=';
          r += c(ctx_2.id, ctx_2);
          r += ' class=link>';
          r += c(ctx_2.name, ctx_2);
          r += '(';
          r += c(ctx_2.num, ctx_2);
          r += ')</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class="page-content myCommodityPageContent ptr-content"><div class=ptr-preloader><div class="preloader color-multi"></div><div class=ptr-arrow></div></div><div class="list media-list myCommodityList">';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.commodityList&&this.commodityList.length>0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.commodityList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=swipeout @swipeout:deleted=serviceRoom(';
              r += c(ctx_3.id, ctx_3);
              r += ')><div class="item-content swipeout-content"><div class=item-inner><div class=item-title-row><div class=item-title>';
              r += c(ctx_3.jName, ctx_3);
              r += '(';
              r += c(ctx_3.roomno, ctx_3);
              r += ')</div><div class=item-after>&yen;';
              r += c(ctx_3.actualPrice, ctx_3);
              r += '元</div></div><div class=item-subtitle>';
              r += c(ctx_3.ctime, ctx_3);
              r += '&emsp;';
              r += c(ctx_3.title, ctx_3);
              r += '</div><div class=item-text>备注：';
              r += c(ctx_3.remark || "-", ctx_3);
              r += '</div></div></div><div class=swipeout-actions-right><a href=# @click=showDetailsInfo(';
              r += c(ctx_3.id, ctx_3);
              r += ') class="open-more-actions ripple color-blue">详情</a> <a href=tel:';
              r += c(ctx_3.phone, ctx_3);
              r += ' class="open-more-actions ripple color-green external">电话</a> ';
              r += Template7Helpers.js_if.call(ctx_3, "+this.state==3", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <a href=# class="swipeout-delete ripple color-red" data-confirm=确定已送达?>送达</a> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul><div class=baseline>没有更多了</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.commodityList&&this.commodityList.length==0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("暂无订单"), ctx_2);
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="sheet-modal myCommoditySheetModal" style="height:auto; --f7-sheet-bg-color: #fff;"><div class=swipe-handler></div><div class=sheet-modal-inner><div class=page-content><div class="block-title block-title-medium margin-top">订单商品详情:</div><div class="list no-hairlines"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.cartInfo, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li class=item-content><div class=item-inner><div class=item-title>';
          r += c(ctx_2.name, ctx_2);
          r += '(';
          r += c(ctx_2.company, ctx_2);
          r += ')/&yen;';
          r += c(ctx_2.price, ctx_2);
          r += '*';
          r += c(ctx_2.count, ctx_2);
          r += '</div><div class="item-after text-color-black"><b>&yen;';
          r += Template7Helpers.js.call(ctx_2, "this.price*this.count", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '元</b></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="display-flex padding justify-content-space-between align-items-center"><div style="font-size: 18px"><b>总计:</b></div><div style="font-size: 22px"><b>&yen;';
      r += c(ctx_1.sumPrice, ctx_1);
      r += '元</b></div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.remark, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=block>备注：';
          r += c(ctx_2.remark, ctx_2);
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },
  style: `

			.myCommodityList .item-after {
				font-size: 16px;
			}

			.myCommodityPage .toolbar-inner a.active {
				color: #2196F3;
			}
			.swipeout-actions-right .ripple{
				overflow: hidden;
			}
		`,
  styleScoped: false
};
    