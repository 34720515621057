
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      menuList: ["小黄脸", "哈哈哈", "熊猫头", "嗷大喵", "张学友", "蘑菇头", "鹦鹉兄弟", "萌娃", "猫咪", "装逼", "仓鼠", "熊本熊", "权律", "看戏", "666", "牛逼"],
      doutuList: [],
      checkImg: "",
      checkImgSheet: null
    };
  },
  methods: {
    searchSubmit(e) {
      var self = this;
      e.preventDefault();
      var searchValue = e.target.querySelector('input').value;
      self.queryImg(searchValue);
    },
    queryImg(keyword) {
      var self = this;
      var app = self.$app;
      keyword = keyword || "小黄脸";
      app.request({
        url: "https://pic.sogou.com/pics/json.jsp?query=" + keyword + " 表情&st=5&start=0&xml_len=100&reqFrom=wap_result&callback=?",
        method: "GET",
        dataType: 'json',
        crossDomain: true,
        success: function (json) {
          var imgList = [];
          for (var i in json.items) {
            if (json.items.hasOwnProperty(i)) {
              if (json.items[i].ch_site_name != "全网资源") imgList.push(json.items[i].locImageLink);
            }
          }
          self.$setState({
            doutuList: imgList
          });
        },
        error: function (xhr) {
          app.toast.create({
            text: '表情包加载失败',
            position: 'center',
            closeTimeout: 2000
          }).open();
        }
      });
    },
    tagTap(idx) {
      var self = this;
      var app = self.$app;
      var tInner = $$(".roolMenuDom .toolbar-inner");
      var aInner = tInner.children("a");
      var a = aInner[idx];
      var inner = tInner[0];
      $$(".roolMenuDom .toolbar-inner>a.active").removeClass("active");
      $$(a).addClass("active");
      var bodyWidth = inner.clientWidth;
      var left = a.getBoundingClientRect().left + a.offsetWidth / 2;
      if (left > bodyWidth / 2) {
        var cValue = left - bodyWidth / 2 + inner.scrollLeft;
        tInner.scrollTo(cValue, 0, 200);
      } else {
        var cValue = inner.scrollLeft - (bodyWidth / 2 - left);
        if (cValue < 0) cValue = 0;
        tInner.scrollTo(cValue, 0, 200);
      }
      self.queryImg(self.menuList[idx]);
    },
    checkListImg(e) {
      var self = this;
      var app = self.$app;
      var src = $$(e.currentTarget).find("img").attr("src");
      self.$setState({
        checkImg: src
      });
      self.checkImgSheet.open();
    },
    sendDouTuBtn() {
      var self = this;
      var app = self.$app;
      sessionStorage.setItem("sendImg", self.checkImg);
      self.checkImgSheet.close();
      self.$router.back();
    }
  },
  on: {
    pageBeforeOut: function (e, page) {
      var self = this;
      if (self.checkImgSheet) self.checkImgSheet.close();
    },
    pageInit: function () {
      var self = this;
      var app = self.$app;
      self.queryImg();
      $$(".roolMenuDom .toolbar-inner>a:nth-of-type(1)").addClass("active");
      var searchbar = app.searchbar.create({
        el: ".popupSearchbar",
        customSearch: true
      });
      self.checkImgSheet = app.sheet.create({
        el: '.emoticonSheetSwipe',
        swipeToClose: true,
        backdrop: true
      });
    }
  },
  id: 'b0060cba1c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page emoticon" data-name=emoticon><style>.actionsModal{\r\n				width: 100%;\r\n				max-height: 100%;\r\n			}\r\n			.actions-grid .actions-button-media.dtli{\r\n				width: 100%;\r\n				height: auto;\r\n			}\r\n			.actions-grid .actions-button-media.dtli>img{\r\n				width: 100%;\r\n				height: auto;\r\n			}\r\n			.sheetCheckImgDiv{\r\n				text-align: center;\r\n			}\r\n			.sheetCheckImgDiv>img{\r\n				width: 120px;\r\n				margin-bottom: 20px;\r\n			}\r\n			.emoticon .toolbar-inner a.active{\r\n				color: #2196F3;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="left back"><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>表情包</div><div class=right><a class="link icon-only searchbar-enable" data-searchbar=.popupSearchbar><i class="icon f7-icons if-not-md">search</i> <i class="icon material-icons md-only">search</i></a></div><form class="searchbar searchbar-expandable popupSearchbar" @submit=searchSubmit><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=搜索表情> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class=searchbar-disable-button>取消</span></div></form></div></div><div class="toolbar tabbar toolbar-top tabbar-scrollable roolMenuDom"><div class=toolbar-inner>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.menuList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# @click=tagTap(';
          r += c(data_2 && data_2.index, ctx_2);
          r += ') class=link>';
          r += c(ctx_2, ctx_2);
          r += '</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=page-content><div class="actionsModal actions-grid"><div class=actions-group>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.doutuList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=actions-button @click=checkListImg><div class="actions-button-media dtli"><img src=';
          r += c(ctx_2, ctx_2);
          r += '></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><div class="sheet-modal my-sheet-swipe-to-close emoticonSheetSwipe" style="height:auto; --f7-sheet-bg-color: #fff;"><div class=sheet-modal-inner><div class=swipe-handler></div><div class=page-content><div class=block><div class=sheetCheckImgDiv><img src=';
      r += c(ctx_1.checkImg, ctx_1);
      r += '></div><div class="padding-horizontal padding-bottom"><a class="button button-large button-fill" @click=sendDouTuBtn>发送</a></div></div></div></div></div></div>';
      return r;
    }(this);
  },
  style: `
			.actionsModal{
				width: 100%;
				max-height: 100%;
			}
			.actions-grid .actions-button-media.dtli{
				width: 100%;
				height: auto;
			}
			.actions-grid .actions-button-media.dtli>img{
				width: 100%;
				height: auto;
			}
			.sheetCheckImgDiv{
				text-align: center;
			}
			.sheetCheckImgDiv>img{
				width: 120px;
				margin-bottom: 20px;
			}
			.emoticon .toolbar-inner a.active{
				color: #2196F3;
			}
		`,
  styleScoped: false
};
    