
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      collectionList: []
    };
  },
  methods: {
    initData() {
      var self = this;
      var app = self.$app;
      $.initData(["collection", "user"], function () {
        var arr = [];
        $.appData.collectionData.each(function (obj) {
          var cObj = JSON.parse(obj.content);
          var user = {};
          if (cObj.ctype == 1) {
            user = $.appData.userData.take("id", cObj.cuser);
          } else {
            user = {
              name: cObj.cuser,
              img: ""
            };
          }
          arr.push({
            id: obj.id,
            link: obj.link,
            img: cObj.img,
            title: cObj.title,
            type: cObj.type,
            uImg: user.img,
            uName: user.name,
            ctime: $.formatBeforeTime(obj.ctime)
          });
        });
        self.$setState({
          collectionList: $.jsonSort(arr, "id", "desc")
        }, function () {
          $.setImg(".collectionList", null, null, true, null);
          function popoverClick(e) {
            e.preventDefault();
            var that = $$(this);
            var no = that.attr("no");
            if (!no) return;
            var collection = $.appData.collectionData.take("id", no);
            if (!collection) return;
            var btnList = [];
            btnList.push(`<li><a class="list-button item-link deleteCollection" href="#">删除</a></li>`);
            var dynamicPopover = app.popover.create({
              targetEl: that[0],
              content: `,
								<div class="popover popover-links">
									<div class="popover-inner">
									  <div class="list">
										<ul>
										  ${btnList.join("")}
										</ul>
									  </div>
									</div>
								</div>
								`,
              on: {
                open: function (popover) {
                  console.log('Popover open');
                },
                opened: function (popover) {
                  console.log('Popover opened');
                }
              }
            });
            dynamicPopover.open();
            $$(".deleteCollection").on("click", function () {
              app.dialog.confirm("确定删除这条收藏吗?", function () {
                $.appData.collectionData.del(collection);
                dynamicPopover.close();
                var li = $$(".collectionList>ul>li[no='" + collection.id + "']");
                li.addClass("removeLi");
                var tm = window.setTimeout(function () {
                  li.remove();
                  if ($.appData.collectionData.length == 0) {
                    self.initData();
                  }
                }, 500);
              });
            });
          }
          if (app.device.desktop) {
            $$(".collectionList").on("contextmenu", "ul>li", popoverClick);
          } else {
            if (app.device.ios) {
              $$(".collectionList").on("taphold", "ul>li", popoverClick);
            } else {
              if ($.isWX) {
                $$(".collectionList").on("contextmenu", "ul>li", popoverClick);
              } else {
                $$(".collectionList").on("taphold", "ul>li", popoverClick);
              }
            }
          }
        });
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.initData();
      var $ptrContent = $$('.collectionListPageContent');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.collectionData;
          self.initData();
          $ptrContent[0].f7PullToRefresh.done();
        }, 500);
      });
    }
  },
  id: 'f4192ca8bd',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=collectionList><style>.collectionList .item-text {\r\n				text-align: right;\r\n				height: 35px;\r\n				line-height: 35px;\r\n				display: flex;\r\n				justify-content: space-between;\r\n			}\r\n\r\n			.collectionList .item-text .item-userInfo,.collectionList .item-subtitle.item-userInfo {\r\n				font-size: 16px;\r\n				display: flex;\r\n				align-items: center;\r\n			}\r\n\r\n			.collectionList .item-text .item-userInfo img,.collectionList .item-subtitle.item-userInfo img{\r\n				width: 20px;\r\n				height: 20px;\r\n				margin-right: 10px;\r\n			}\r\n			.collectionList>ul>li{\r\n				transform: translateX(0%);\r\n				transition: transform 0.5s;\r\n				-webkit-transition: -webkit-transform 0.5s;\r\n			}\r\n			.collectionList>ul>li.removeLi{\r\n				transform: translateX(100%);\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>我的收藏</div></div></div><div class="page-content collectionListPageContent ptr-content"><div class=ptr-preloader><div class="preloader color-multi"></div><div class=ptr-arrow></div></div><div class="list media-list collectionList">';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.collectionList&&this.collectionList.length>0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.collectionList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li no=';
              r += c(ctx_3.id, ctx_3);
              r += '><a href="';
              r += c(ctx_3.link.replace('#!', ''), ctx_3);
              r += '" class="item-link item-content">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.img, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-media><img url=';
                  r += c(ctx_4.img, ctx_4);
                  r += ' width=80></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<div class=item-inner><div class=item-title-row><div class=item-title>';
              r += c(ctx_3.title, ctx_3);
              r += '</div><div class=item-after></div></div><div class=item-subtitle><span class="badge color-blue">';
              r += c(ctx_3.type, ctx_3);
              r += '</span></div><div class=item-text><span class=item-userInfo>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.uImg, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img url=';
                  r += c(ctx_4.uImg, ctx_4);
                  r += '> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += c(ctx_3.uName, ctx_3);
              r += ' </span><span>';
              r += c(ctx_3.ctime, ctx_3);
              r += '</span></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.js_if.call(ctx_1, "!!this.collectionList&&this.collectionList.length==0", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.$root.emptyTip("暂无收藏"), ctx_2);
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `
			.collectionList .item-text {
				text-align: right;
				height: 35px;
				line-height: 35px;
				display: flex;
				justify-content: space-between;
			}

			.collectionList .item-text .item-userInfo,.collectionList .item-subtitle.item-userInfo {
				font-size: 16px;
				display: flex;
				align-items: center;
			}

			.collectionList .item-text .item-userInfo img,.collectionList .item-subtitle.item-userInfo img{
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}
			.collectionList>ul>li{
				transform: translateX(0%);
				transition: transform 0.5s;
				-webkit-transition: -webkit-transform 0.5s;
			}
			.collectionList>ul>li.removeLi{
				transform: translateX(100%);
			}
		`,
  styleScoped: false
};
    