
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      checkImgList: [],
      showImgListObj: ""
    };
  },
  methods: {
    fileImgCheck(e) {
      var self = this;
      var input = e.currentTarget;
      $.fileImgAdd(input, self.checkImgList);
    },
    addProblemInfo() {
      var self = this;
      var app = self.$app;
      var val = self.textEditor.getValue();
      if (!val) {
        return $.toast("说点什么吧...");
      }
      if (self.checkImgList.length > 9) return $.toast("最多可上传9张照片");
      if (val.length > 80000) return $.toast("评价最多80000个字!");
      var obj = {
        content: val
      };
      $.initData(["problem"], function () {
        $.uploadImgList(self.checkImgList, function (imgList) {
          obj.type = self.$route.params.type;
          // obj.support = "";
          obj.img = imgList.join("|");
          obj.cuser = $.user.id;
          obj.ctime = $.getTime();
          obj.utime = $.getTime();
          app.dialog.preloader("发布中...");
          ($.baseUrl + "/addProblem?_=" + new Date().getTime()).p(function (json) {
            app.dialog.close();
            if (+json.code == 1) {
              delete $.appData.problemData;
              $.addProblem_state = true;
              ($.baseUrl + "/readProblemUnread?name=" + self.$route.params.type + "&val=" + json.id).g(function (rJson) {
                if (+rJson.code == 1) {
                  if (!!$.problemUnreadList.take("name", obj.type)) $.problemUnreadList.rem("name", obj.type);
                }
              });
              self.$router.back();
            } else {
              $.toast(json.data);
            }
          }, obj);
        });
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      self.textEditor = app.textEditor.create({
        el: '#problemReplayEditor',
        placeholder: `说点什么吧...`,
        buttons: [["bold", "italic", "underline", "strikeThrough"], ["orderedList", "unorderedList"]]
      });
    }
  },
  id: '891ad33a90',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=catalog><style>#problemReplayEditor>.text-editor-content{\r\n			  min-height: 120px;\r\n		  }</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>发帖</div></div></div><div class=page-content><div id=problemReplayEditor class="text-editor text-editor-resizable"><div class=text-editor-content contenteditable></div></div><div class=block style="width: 100%;"><div class=row><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo</i> <i class="f7-icons if-not-md">photo_fill</i> <input class=uploadBtn @change=fileImgCheck type=file multiple=multiple type=file accept=image/*></div></div><div class=sheetIconLabel>相册</div></div><div class=col-25><div class=sheetIcon><div><i class="material-icons md-only">photo_camera</i> <i class="f7-icons if-not-md">camera_fill</i> <input class=uploadBtn @change=fileImgCheck type=file capture=camera type=file accept=image/*></div></div><div class=sheetIconLabel>拍照</div></div><div class=col-25></div></div></div><div class="block block-strong checkImgListDiv" style="display: none;"><div class="list checkImgList media-list sortable sortable-enabled"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.checkImgList, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class="padding-horizontal padding-bottom"><a class="button button-large button-fill" @click=addProblemInfo>发布</a></div></div></div>';
      return r;
    }(this);
  },
  style: `
		  #problemReplayEditor>.text-editor-content{
			  min-height: 120px;
		  }
		  
	  `,
  styleScoped: false
};
    