import SparkMD5 from './spark-md5.js';
function Base64(){var _keyStr="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/\x3d";this.encode=function(b){var d="",c,a,f,g,h,e,k=0;for(b=_utf8_encode(b);k<b.length;)c=b.charCodeAt(k++),a=b.charCodeAt(k++),f=b.charCodeAt(k++),g=c>>2,c=(c&3)<<4|a>>4,h=(a&15)<<2|f>>6,e=f&63,isNaN(a)?h=e=64:isNaN(f)&&(e=64),d=d+_keyStr.charAt(g)+_keyStr.charAt(c)+_keyStr.charAt(h)+_keyStr.charAt(e);return d};this.decode=function(b){var d="",c,a,f,g,h,e=0;for(b=b.replace(/[^A-Za-z0-9\+\/\=]/g,"");e<b.length;)c=_keyStr.indexOf(b.charAt(e++)),a=_keyStr.indexOf(b.charAt(e++)),g=_keyStr.indexOf(b.charAt(e++)),h=_keyStr.indexOf(b.charAt(e++)),c=c<<2|a>>4,a=(a&15)<<4|g>>2,f=(g&3)<<6|h,d+=String.fromCharCode(c),64!=g&&(d+=String.fromCharCode(a)),64!=h&&(d+=String.fromCharCode(f));return d=_utf8_decode(d)};var _utf8_encode=function(b){b=b.replace(/\r\n/g,"\n");for(var d="",c=0;c<b.length;c++){var a=b.charCodeAt(c);128>a?d+=String.fromCharCode(a):(127<a&&2048>a?d+=String.fromCharCode(a>>6|192):(d+=String.fromCharCode(a>>12|224),d+=String.fromCharCode(a>>6&63|128)),d+=String.fromCharCode(a&63|128))}return d};var _utf8_decode=function(b){var d="",c=0,a;for(c1=c2=0;c<b.length;)a=b.charCodeAt(c),128>a?(d+=String.fromCharCode(a),c++):191<a&&224>a?(c2=b.charCodeAt(c+1),d+=String.fromCharCode((a&31)<<6|c2&63),c+=2):(c2=b.charCodeAt(c+1),c3=b.charCodeAt(c+2),d+=String.fromCharCode((a&15)<<12|(c2&63)<<6|c3&63),c+=3);return d}};
function b64_hmac_sha1(k,d,_p,_z){if(!_p){_p='=';}if(!_z){_z=8;}function _f(t,b,c,d){if(t<20){return(b&c)|((~b)&d);}if(t<40){return b^c^d;}if(t<60){return(b&c)|(b&d)|(c&d);}return b^c^d;}function _k(t){return(t<20)?1518500249:(t<40)?1859775393:(t<60)?-1894007588:-899497514;}function _s(x,y){var l=(x&0xFFFF)+(y&0xFFFF),m=(x>>16)+(y>>16)+(l>>16);return(m<<16)|(l&0xFFFF);}function _r(n,c){return(n<<c)|(n>>>(32-c));}function _c(x,l){x[l>>5]|=0x80<<(24-l%32);x[((l+64>>9)<<4)+15]=l;var w=[80],a=1732584193,b=-271733879,c=-1732584194,d=271733878,e=-1009589776;for(var i=0;i<x.length;i+=16){var o=a,p=b,q=c,r=d,s=e;for(var j=0;j<80;j++){if(j<16){w[j]=x[i+j];}else{w[j]=_r(w[j-3]^w[j-8]^w[j-14]^w[j-16],1);}var t=_s(_s(_r(a,5),_f(j,b,c,d)),_s(_s(e,w[j]),_k(j)));e=d;d=c;c=_r(b,30);b=a;a=t;}a=_s(a,o);b=_s(b,p);c=_s(c,q);d=_s(d,r);e=_s(e,s);}return[a,b,c,d,e];}function _b(s){var b=[],m=(1<<_z)-1;for(var i=0;i<s.length*_z;i+=_z){b[i>>5]|=(s.charCodeAt(i/8)&m)<<(32-_z-i%32);}return b;}function _h(k,d){var b=_b(k);if(b.length>16){b=_c(b,k.length*_z);}var p=[16],o=[16];for(var i=0;i<16;i++){p[i]=b[i]^0x36363636;o[i]=b[i]^0x5C5C5C5C;}var h=_c(p.concat(_b(d)),512+d.length*_z);return _c(o.concat(h),512+160);}function _n(b){var t="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",s='';for(var i=0;i<b.length*4;i+=3){var r=(((b[i>>2]>>8*(3-i%4))&0xFF)<<16)|(((b[i+1>>2]>>8*(3-(i+1)%4))&0xFF)<<8)|((b[i+2>>2]>>8*(3-(i+2)%4))&0xFF);for(var j=0;j<4;j++){if(i*8+j*6>b.length*32){s+=_p;}else{s+=t.charAt((r>>6*(3-j))&0x3F);}}}return s;}function _x(k,d){return _n(_h(k,d));}return _x(k,d);}
function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
(function(win){
	win.uploadFile=function(file,fn,progressFn,fileType){
		if(!file)
			return console.log("无上传文件");
		if(typeof(file)=="string"){
			if(file.indexOf("data")==0){
				file=dataURLtoBlob(file);
			}
			if(file.indexOf("http")==0){
				if(!!fn)
					fn(file);
				return;
			}
		}
		if(!fileType)
			fileType="jpg";
		var reader = new FileReader();
		reader.onload = function (e){
			var spark = new SparkMD5.ArrayBuffer();
        	spark.append(reader.result);
        	var fileMD5=spark.end();
        	var info={
				"scope":"hdlt",
				"deadline":~~(new Date().getTime()/1000)+3600,
			};
			if(fileType=="mp4")
				info.persistentOps="avthumb/mp4/vcodec/libx264;vframe/jpg/offset/1/w/300/h/300";
			var encodedPutPolicy=(new Base64()).encode(JSON.stringify(info)).replace(/\//g, '_').replace(/\+/g, '-');
			var encodedSign = b64_hmac_sha1("NcIz2jzRiLETxvcus2W9MghIl6WIxs9C5_LUQ2tS",encodedPutPolicy);
			encodedSign=encodedSign.replace(/\//g, '_').replace(/\+/g, '-');
			var uploadToken = "HzBw7kQTbki_xAhh9D07BhH-zX0mctNWlolPEhjT" + ':' + encodedSign + ':' + encodedPutPolicy;
			uploadToken=uploadToken.replace(/\//g, '_').replace(/\+/g, '-');
			var formData=new FormData();
			formData.append("key",fileMD5+"."+fileType);
			formData.append("token",uploadToken);
			formData.append("file",file);
			var xmlhttp=new XMLHttpRequest();
			xmlhttp.onreadystatechange=function(){
				if (xmlhttp.readyState==4 && xmlhttp.status==200){
					var json=JSON.parse(xmlhttp.responseText);
					if(fileType=="jpg"){
						if(!!fn)
							fn("https://static.myshopvip.cn/"+json.key);
					}else if(fileType=="mp4"){
						queryImgState(json.persistentId,function(videoKey,imgKey){
							if(!!fn)
								fn("https://static.myshopvip.cn/"+videoKey,"https://static.myshopvip.cn/"+imgKey);
						});
					}
					
				}
			}
			xmlhttp.upload.onprogress = function(e){
				if(!!progressFn)
					progressFn(e);
			};
			
			xmlhttp.open("POST","https://upload-z1.qiniup.com/",true);
			// xmlhttp.open("POST","http://up-z1.qiniu.com/",true);
			xmlhttp.send(formData);
		}
		reader.readAsArrayBuffer(file);
		
		var queryImgState=function(id,fn){
			var xmlhttp=new XMLHttpRequest();
			xmlhttp.onreadystatechange=function(){
				if (xmlhttp.readyState==4 && xmlhttp.status==200){
					var json=JSON.parse(xmlhttp.responseText);
					if(json.items[0].code==0&&json.items[1].code==0){
						if(!!fn)
							fn(json.items[0].key,json.items[1].key);
					}else if(json.items[0].code==1||json.items[0].code==2||json.items[1].code==1||json.items[1].code==2){
						var timer=win.setTimeout(function(){
							clearTimeout(timer);
							queryImgState(id,fn);
						},1000);
					}else{
						if(!!fn)
							fn(null);
					}
				}
			}
			// "+proInfoList[projectMark].ip+"
			var v1Url=proInfoList[projectMark].ip;
			if (window.location.hostname == "localhost") {
				v1Url="yzzjapp.cn";
			}
			// xmlhttp.open("GET","http://39.106.18.35:9999/v1?url=https://api.qiniu.com/status/get/prefop?id="+id,true);
			xmlhttp.open("GET","https://"+v1Url+":9999/v1?url=https://api.qiniu.com/status/get/prefop?id="+id,true);
			xmlhttp.send();
		}
	}
})(window);