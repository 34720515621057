import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import Routes
import routes from './routes.js';
import './main.js';
import '../static/js/db.js';
// import '../static/js/imgLoad.js';
import '../static/js/common.js';
import '../static/js/initData.js';
import '../static/js/areaData.js';
import '../static/js/socket.js';
import '../static/js/globalServices.js';
import '../static/js/h5plus.js';
import '../static/js/examine.js';
import '../static/js/img.js';
import '../static/js/loadJs.js';
// import '../static/js/wxsdk.js';

// let refreshing = false
// navigator.serviceWorker.addEventListener('controllerchange', () => {
//   if (refreshing) {
//     return
//   }
//   refreshing = true;
//   debugger;
//   alert("新版本已就绪,点击确定更新");
//   alert(window.hasOwnProperty("myApp"));
//   var notificationCallbackOnClose = window.myApp.notification.create({
//     icon: `<img class="notificationPhoto" />`,
//     title: "系统提示",
//     titleRightText: "现在",
//     subtitle: "新版本已加载完毕",
//     text: '点击此处更新',
//     on: {
//   	click: function () {
//   		if(Framework7.device.desktop){
//   			notificationCallbackOnClose.$el.attr("style","display: block;");
//   		}
//   		notificationCallbackOnClose.close();
// 		setTimeout(function(){
// 			location.reload();
// 		},300);
//   	},
//     },
//   });
//   setTimeout(function(){
//   	notificationCallbackOnClose.open();
//   	// $.setImg(".notification");
//   },0);


// });

window.myApp = new Framework7({
	root: '#app', // App root element

	name: 'yzzj', // App name
	theme: 'md', // Automatic theme detection
	// App root data
	// view:{
	//  pushState:true,
	// },
	touch: {
		tapHold: true,
		fastClicks: true,
		disableContextMenu: false,
	},

	dialog: {
		title: '提示',
		buttonOk: '确定',
		buttonCancel: '取消',
	},
	photoBrowser: {
		toolbar: false,
		routableModals: true,
		navbarOfText:"/"
	},

	data: function() {
		return {
			emptyTip: function(txt) {
				return `
				<div class="emptyDataTip">
					<svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12847">
						<path d="M513.15 641.988c168.465 0 327.328 73.28 435.855 201.055 17.151 20.15 14.863 49.97-5.208 67.902-8.204 7.333-19.016 11.374-30.437 11.374-14.296 0-27.943-6.08-37.444-16.681C784.2 798.782 651.521 737.444 511.991 737.444c-140.53 0-273.215 61.351-364.031 168.317-9.165 10.763-22.292 16.942-36.014 16.942-11.251 0-22.12-4.175-30.606-11.759-10.123-8.6-16.111-20.257-16.981-32.942-0.847-12.384 3.298-24.35 11.671-33.693 110.402-128.613 269.695-202.321 437.12-202.321z m273.223-246.564c-43.766 0-80.734-37.845-80.734-82.638V192.105c0-44.798 36.969-82.646 80.734-82.646 43.763 0 80.734 37.848 80.734 82.646v120.683c0 45.566-36.216 82.636-80.734 82.636z m-547.906 0c-43.761 0-80.731-37.845-80.731-82.638V192.105c0-44.798 36.97-82.646 80.731-82.646 43.771 0 80.746 37.848 80.746 82.646v120.683c0 44.791-36.975 82.636-80.746 82.636z"></path>
					</svg>
					<label class="emptyDataLabel">${txt}</label>
				</div>
				`;
			},

		};
	},
	on: {
		// routeChange:function(newRouter,oldRouter,router){
		// 	// debugger;
		// 	// return;
		// 	// router.navigate('/login/',{reloadCurrent:true});
			
		// 	var self = this;
		// 	var publicRouter=[
		// 		"/login",
		// 		"/projectServices",
		// 		"/projectDetails",
		// 		"/addNoticePage",
		// 		"/noticeSuccessPage",
		// 		"/newPhonePage",
		// 		"/namePwdPage",
		// 		"/registerPage",
		// 		"/registerSuccessPage",
		// 		"/forgetPasswordPage",
				
		// 	];
		// 	if (!$.isYZ) {
		// 		getHTML("userInfo", function(userInfo) {
		// 			if (!!userInfo) {
		// 				$.user = userInfo;
		// 				$.isYZ = true;
		// 				window.connectWS();
		// 			} else {
		// 				var isToLogin=true;
		// 				for(var i=0,len=publicRouter.length;i<len;i++){
		// 					if(self.view.main.router.url.indexOf(publicRouter[i])==0||self.view.main.router.url=="/")
		// 						isToLogin=false
		// 				}
		// 				if(!isToLogin)
		// 					return;
		// 				window.setTimeout(function(){
		// 					self.view.main.router.navigate('/login/',{reloadCurrent:true});
		// 				},480);
						
		// 			}
		// 		});
		// 	}
		// },
		pageInit: function() {
			var self = this;
			
			
			$$("head").append("<meta name=\"referrer\" content=\"no-referrer\" />");
			$$("body").removeClass("bodyLoading");
			$$("#my-login-screen").removeAttr("style");
			
			if(self.view.main.router.url=="/addNoticePage/"){
				$.user = JSON.parse(`{"id":876,"phone":"18091176789","pwd":"111111","realname":"物业通知专用","docno":"","idcard":"","roomno":"4幢2单元22404","size":"124.67㎡","price":"8250","btime":"2017-01-15","sex":"男","birthday":"","address":"","pos":1,"img":"https://static.myshopvip.cn/78afacc65889438a39122999d3b32a14.jpg","name":"物业通知专用","state":1,"ctime":"2020-02-25 16:14:25","rid":1}`);
				if (window.location.hostname == "localhost") {
					$.user = JSON.parse(`{"id":811,"phone":"18091176789","pwd":"111111","realname":"物业通知专用","docno":"","idcard":"","roomno":"4幢2单元22404","size":"124.67㎡","price":"8250","btime":"2017-01-15","sex":"男","birthday":"","address":"","pos":1,"img":"https://static.myshopvip.cn/da4f333254fb5e90c10d512d8e4b82d0.jpg","name":"物业通知专用","state":1,"ctime":"2020-02-25 15:34:06","rid":1}`);
				}
				$.isYZ = true;
				return;
			}
			
			if (!$.isYZ) {
				getHTML("userInfo", function(userInfo) {
					if (!!userInfo) {
						$.user = userInfo;
						$.user.community=$.user.community||"";
						$.isYZ = true;
						window.connectWS();
					}
				});
			}
			
		},
		online: function() {
			$.toast("网络已重新链接");
			if (window.hasOwnProperty("connectWS"))
				window.connectWS();
		},
		offline: function() {
			$.toast("当前网络已断开");
		},
		// serviceWorkerRegisterSuccess:function(registration){
		// 	console.log(555);
		// 	registration.update();
		// 	registration.addEventListener('install', event => {
				
		// 	// 	registration.skipWaiting();

		// 		var notificationCallbackOnClose = window.myApp.notification.create({
		// 		  icon: `<img class="notificationPhoto" />`,
		// 		  title: "系统提示",
		// 		  titleRightText: "现在",
		// 		  subtitle: "新版本已加载完毕",
		// 		  text: '点击此处更新',
		// 		  on: {
		// 			click: function () {
		// 				if(Framework7.device.desktop){
		// 					notificationCallbackOnClose.$el.attr("style","display: block;");
		// 				}
		// 				notificationCallbackOnClose.close();
		// 				window.setTimeout(function(){
		// 					registration.skipWaiting();
		// 				},300);
		// 			},
		// 		  },
		// 		});
		// 		window.setTimeout(function(){
		// 			notificationCallbackOnClose.open();
		// 			// $.setImg(".notification");
		// 		},0);
		// 	});

		// }
	},
	// App root methods
	methods: {
		helloWorld: function() {
			app.dialog.alert('Hello World!');
		},
	},
	// App routes
	routes: routes,
	// Register service worker
	serviceWorker: {
		path: '/service-worker.js',
		// path: (function(){
		// 	alert(1);
		// 	if(Framework7.device.ios){
		// 		alert(2);
		// 		return "";
		// 	}else{
		// 		alert(3);
		// 		return '/service-worker.js';
		// 	}
		// })(),
	},
});
// myApp.preloader.show();

document.addEventListener('contextmenu', function(e) {
	var dom = e.target;
	if (["textarea", "input", "img"].indexOf(dom.localName) == -1) {
		if (!(dom.localName == "div" && (dom.contentEditable == "true" || dom.contentEditable == true))) {
			e.preventDefault();
		}
	}
});



