
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      projectMark: window.projectMark,
      thisUser: {
        phone: ""
      },
      telReg: /^(\d{3})\d{4}(\d{4})$/
    };
  },
  methods: {
    reloadPage() {
      window.location.reload(true);
    },
    clearAll() {
      var self = this;
      var app = self.$app;
      app.dialog.confirm("确定清空缓存吗?", function () {
        deleteHTML(null, function () {
          addHTML("userInfo", $.user);
        });
      });
    },
    outlogin() {
      var self = this;
      var app = self.$app;
      app.dialog.confirm("确定退出登录吗?", function () {
        deleteHTML("userInfo");
        $.user = null;
        self.$setState({
          thisUser: {
            phone: ""
          }
        });
        $.isYZ = false;
        window.sockWs.close();
        for (var i = 0; i < self.$router.routes.length; i++) {
          var r = self.$router.routes[i];
          if (r.isOpen) continue;
          r.redirect = window.loginRedirectFn;
        }
        self.$router.navigate('/login/');
      });
    },
    toLogin() {
      var self = this;
      self.$router.navigate('/login/');
    },
    updateVersion() {
      caches.keys().then(function (keyList) {
        if (keyList.length > 0) {
          return Promise.all(keyList.map(function (key) {
            caches.delete(key);
            $.alt("已更新到最新版,将在重启后启用");
          }));
        } else {
          $.alt("已更新到最新版,将在重启后启用");
        }
      });
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      $.waitObj($, "user", function () {
        self.$setState({
          thisUser: $.user
        });
      });
      $$(".showDevBtn").on("taphold", function () {
        self.$router.navigate('/showDevPage/');
      });
    }
  },
  id: 'c802dcfa98',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=settings><style>.list .myInfoListIconDiv,\r\n			.md .list .myInfoListIconDiv {\r\n				min-width: 28px;\r\n				width: 28px;\r\n				height: 28px;\r\n				background-color: #2196f3;\r\n				display: flex;\r\n				justify-content: center;\r\n				align-items: center;\r\n				border-radius: 6px;\r\n				color: #FFFFFF;\r\n			}\r\n\r\n			.list .myInfoListIconDiv i {\r\n				font-size: 20px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>设置</div></div></div><div class=page-content><div class=block-title @click=reloadPage()>个人设置</div><div class=list><ul><!-- <li>\r\n						<a href="#" class="item-link item-content">\r\n							<div class="item-media myInfoListIconDiv">\r\n								<i class="material-icons md-only">phone</i>\r\n								<i class="f7-icons if-not-md">phone</i>\r\n							</div>\r\n							<div class="item-inner">\r\n								<div class="item-title">修改手机号</div>\r\n								<div class="item-after">18091120860</div>\r\n							</div>\r\n						</a>\r\n					</li> --><li><a href=/updatePhonePage/ class="item-link item-content"><div class="item-media myInfoListIconDiv"><i class="material-icons md-only">phone</i> <i class="f7-icons if-not-md">phone</i></div><div class=item-inner><div class=item-title>变更手机号</div><div class=item-after>';
      r += Template7Helpers.js.call(ctx_1, "this.thisUser.phone.replace(this.telReg, '$1****$2')", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></a></li><li><a href=/setNewPassword/ class="item-link item-content"><div class="item-media myInfoListIconDiv"><i class="material-icons md-only">lock</i> <i class="f7-icons if-not-md">lock_fill</i></div><div class=item-inner><div class=item-title>修改密码</div><div class=item-after></div></div></a></li></ul></div><div class=block-title>系统设置</div><div class=list><ul><li><a href=# class="item-link item-content" @click=clearAll><div class="item-media myInfoListIconDiv"><i class="material-icons md-only">delete_forever</i> <i class="f7-icons if-not-md">trash_fill</i></div><div class=item-inner><div class=item-title>清除缓存</div><div class=item-after></div></div></a></li><li><a href=/disclaimer/ class="item-link item-content"><div class="item-media myInfoListIconDiv"><i class="material-icons md-only">perm_device_information</i> <i class="f7-icons if-not-md">doc_text</i></div><div class=item-inner><div class=item-title>用户须知</div><div class=item-after></div></div></a></li><li><a href=# class="item-link item-content" @click=updateVersion><div class="item-media myInfoListIconDiv"><i class="material-icons md-only">update</i> <i class="f7-icons if-not-md">arrow_2_circlepath_circle_fill</i></div><div class=item-inner><div class=item-title>检查更新</div><div class=item-after></div></div></a></li><!-- <li>\r\n						<a href="#" class="item-link item-content">\r\n							<div class="item-media myInfoListIconDiv">\r\n								<i class="material-icons md-only">create</i>\r\n								<i class="f7-icons if-not-md">pencil</i>\r\n							</div>\r\n							<div class="item-inner">\r\n								<div class="item-title">问题反馈</div>\r\n								<div class="item-after"></div>\r\n							</div>\r\n						</a>\r\n					</li> --></ul></div><div class="block-title showDevBtn">软件信息</div><div class=list><ul><li><a href="https://html.myshopvip.cn/wap/index.html?mark=';
      r += c(ctx_1.projectMark, ctx_1);
      r += '" class="item-link item-content external"><div class="item-media myInfoListIconDiv"><i class="material-icons md-only">error</i> <i class="f7-icons if-not-md">exclamationmark_circle</i></div><div class=item-inner><div class=item-title>关于</div><div class=item-after></div></div></a></li><!-- <li>\r\n						<a href="javascript:location.reload();" class="item-link item-content external">\r\n							<div class="item-media myInfoListIconDiv">\r\n								<i class="material-icons md-only">error</i>\r\n								<i class="f7-icons">arrow_counterclockwise</i>\r\n							</div>\r\n							<div class="item-inner">\r\n								<div class="item-title">刷新</div>\r\n								<div class="item-after"></div>\r\n							</div>\r\n						</a>\r\n					</li> --></ul></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.thisUser.phone, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list inset if-not-md"><ul><li><a href=# class="list-button color-red" @click=outlogin>退出登陆</a></li></ul></div><div class="block md-only"><button class="button button-raised color-red" @click=outlogin>退出登陆</button></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  style: `
			.list .myInfoListIconDiv,
			.md .list .myInfoListIconDiv {
				min-width: 28px;
				width: 28px;
				height: 28px;
				background-color: #2196f3;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 6px;
				color: #FFFFFF;
			}

			.list .myInfoListIconDiv i {
				font-size: 20px;
			}
		`,
  styleScoped: false
};
    