
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      business: {},
      qualificationsList: []
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      $.globalInitData(["business"], function () {
        var business = $.appData.businessData.take("id", self.$route.params.id);
        if (!!business.qualifications) {
          var obj = JSON.parse(JSON.stringify(business));
          self.$setState({
            qualificationsList: obj.qualifications.split("|")
          }, function () {
            $.setImg(".businessImgDiv");
            $$(".businessImgDiv>img").on("click", function () {
              self.showImgListObj.activeIndex = $$(this).index();
              self.showImgListObj.open();
            });
          });
          self.showImgListObj = app.photoBrowser.create({
            photos: self.qualificationsList,
            theme: 'dark',
            popupCloseLinkText: "关闭"
          });
        }
        self.$setState({
          business: business
        });
      });
    }
  },
  id: '3758da9208',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=userInfo><style>.businessImgDiv{\r\n				display: flex;\r\n				justify-content: start;\r\n				flex-wrap: wrap;\r\n				flex-direction: row;\r\n			}\r\n			.businessImgDiv>img{\r\n				width: 28%;\r\n				height: 25vw;\r\n				margin-top: 5px;\r\n				margin-bottom: 5px;\r\n				margin-right: 10px;\r\n				border-radius: 5px;\r\n			}\r\n			.marginTop0{\r\n				margin-top: 0px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>商家信息</div></div></div><div class=page-content><div class="block block-strong inset no-hairlines"><div class="list marginTop0"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">商家名称</div><div class=item-input-wrap>';
      r += c(ctx_1.business.name, ctx_1);
      r += '</div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">商家类别</div><div class=item-input-wrap>';
      r += c(ctx_1.business.type, ctx_1);
      r += '</div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">联系电话</div><div class=item-input-wrap>';
      r += c(ctx_1.business.phone, ctx_1);
      r += '</div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">门店地址</div><div class=item-input-wrap>';
      r += c(ctx_1.business.address, ctx_1);
      r += '</div></div></li>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.qualificationsList.length > 0, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li class="item-content item-input"><div class=item-inner><div class="item-title item-label">商户资质</div><div class=item-input-wrap><div class=businessImgDiv>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.qualificationsList, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img url=';
              r += c(ctx_3, ctx_3);
              r += '> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="padding-horizontal padding-bottom"><a href=tel:';
      r += c(ctx_1.business.phone, ctx_1);
      r += ' class="button button-large button-fill external">电话联系</a></div></div></div></div>';
      return r;
    }(this);
  },
  style: `
			.businessImgDiv{
				display: flex;
				justify-content: start;
				flex-wrap: wrap;
				flex-direction: row;
			}
			.businessImgDiv>img{
				width: 28%;
				height: 25vw;
				margin-top: 5px;
				margin-bottom: 5px;
				margin-right: 10px;
				border-radius: 5px;
			}
			.marginTop0{
				margin-top: 0px;
			}
		`,
  styleScoped: false
};
    