
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      projectDetails: {}
    };
  },
  methods: {
    cancelFormData() {
      var self = this;
      var app = self.$app;
      app.dialog.confirm("确定取消预约吗?", function () {
        ($.globalBaseUrl + "/cancelGlobalOrder").globalP(function (json) {
          if (json.code + "" == "1") {
            var fObj = $.appData.formDataData.take("id", self.projectDetails.fid);
            fObj.state = 50;
            sessionStorage.setItem("refreshPage", "1");
            self.$router.back();
          } else {
            $.alt(json.data);
          }
        }, {
          fid: self.projectDetails.fid
        });
      });
    },
    replayFormData() {
      var self = this;
      var app = self.$app;
      self.$router.navigate('/addProjectReplyPage/' + self.projectDetails.fid + '/');
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      $.globalSignToken(function () {
        $.globalInitData(["formData", "project", "business"], function () {
          var stateDic = {
            "1": "已预约",
            "50": "已取消",
            "75": "已删除",
            "100": "已接单",
            "200": "已确认",
            "300": "已发货",
            "400": "已收货",
            "500": "待评价",
            "550": "已评价",
            "600": "已结单",
            "700": "已关闭"
          };
          var stateColor = {
            "1": "color-blue",
            "50": "",
            "100": "color-orange",
            "500": "color-red",
            "550": "color-green"
          };
          var arr = [];
          var fObj = $.appData.formDataData.take("id", self.$route.params.id);
          var obj = {};
          var pro = $.appData.projectData.take("id", fObj.pid);
          var bObj = $.appData.businessData.take("id", pro.bid);
          obj.no = +fObj.id + 100000000;
          obj.fid = fObj.id;
          obj.pid = pro.id;
          obj.pName = pro.name;
          obj.bid = bObj.id;
          obj.bName = bObj.name;
          obj.bPhone = bObj.phone;
          obj.state = fObj.state;
          obj.stateName = stateDic[fObj.state];
          obj.stateColor = stateColor[fObj.state];
          obj.pImg = pro.img.split("|")[0];
          var tArr = fObj.ctime.split(" ");
          obj.date = tArr[0].replace("-", "年").replace("-", "月") + "日";
          obj.time = tArr[1].replace(/:\d{2}$/, "");
          var uJson = JSON.parse(fObj.data);
          obj.user_name = uJson.user_name;
          obj.user_phone = uJson.user_phone;
          self.$setState({
            projectDetails: obj
          }, function () {
            $.setImg(".myProjectInfoImgDiv");
          });
        });
      });
    }
  },
  id: 'f0f3142ea1',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=myProjectDetails><style>.myProjectInfoImg{\r\n				border-radius: 10px;\r\n				width: 100px;\r\n				height: 100px;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>预约详情</div></div></div><div class=page-content><div class="block myProjectInfoImgDiv"><div class="item-content text-align-center"><div class=item-media>';
      r += Template7Helpers.js_if.call(ctx_1, "!this.projectDetails.pImg", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="skeleton-block skeleton-effect-blink" style="width: 100px; height: 100px; border-radius: 10px;display: inline-block;"></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <img class=myProjectInfoImg url=';
          r += c(ctx_2.projectDetails.pImg, ctx_2);
          r += '> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>预约编号</div><div class=item-after>';
      r += c(ctx_1.projectDetails.no, ctx_1);
      r += '</div></div></div></li></ul></div></div><div class=block><div class=list><ul><li><a href=/projectDetails/';
      r += c(ctx_1.projectDetails.pid, ctx_1);
      r += '/ class="item-link item-content"><div class=item-inner><div class=item-title>预约服务</div><div class=item-after>';
      r += c(ctx_1.projectDetails.pName, ctx_1);
      r += '</div></div></a></li><li><div class=item-content><div class=item-inner><div class=item-title>预约日期</div><div class=item-after>';
      r += c(ctx_1.projectDetails.date, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>预约时间</div><div class=item-after>';
      r += c(ctx_1.projectDetails.time, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>预约状态</div><div class=item-after><span class="badge ';
      r += c(ctx_1.projectDetails.stateColor, ctx_1);
      r += '">';
      r += c(ctx_1.projectDetails.stateName, ctx_1);
      r += '</span></div></div></div></li></ul></div></div><div class=block><div class=list><ul><li><a href=/businessDetails/';
      r += c(ctx_1.projectDetails.bid, ctx_1);
      r += '/ class="item-link item-content"><div class=item-inner><div class=item-title>预约商家</div><div class=item-after>';
      r += c(ctx_1.projectDetails.bName, ctx_1);
      r += '</div></div></a></li><li><div class=item-content><div class=item-inner><div class=item-title>商家电话</div><div class=item-after><a href=tel:';
      r += c(ctx_1.projectDetails.bPhone, ctx_1);
      r += ' class=external>';
      r += c(ctx_1.projectDetails.bPhone, ctx_1);
      r += '</a></div></div></div></li></ul></div></div><div class=block><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>预约人</div><div class=item-after>';
      r += c(ctx_1.projectDetails.user_name, ctx_1);
      r += '</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>联系电话</div><div class=item-after>';
      r += c(ctx_1.projectDetails.user_phone, ctx_1);
      r += '</div></div></div></li></ul></div></div><div class="padding-horizontal padding-bottom">';
      r += Template7Helpers.js_if.call(ctx_1, "this.projectDetails.state==1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <button @click=cancelFormData class="button button-large button-raised">取消预约</button> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "this.projectDetails.state==500", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <button @click=replayFormData class="button button-large button-fill">评价服务</button> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },
  style: `
			.myProjectInfoImg{
				border-radius: 10px;
				width: 100px;
				height: 100px;
			}
		`,
  styleScoped: false
};
    