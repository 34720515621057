
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      skeleton: true,
      activityList: [],
      pageDataArr: {},
      showData: [],
      pageNo: 1,
      pageSize: 10,
      allowInfinite: true,
      sortType: 1,
      searchText: ""
    };
  },
  methods: {
    initData(e) {
      var self = this;
      var app = self.$app;
      $.initData(["user", "activity"], function () {
        self.renderData && self.renderData();
      });
    },
    renderData(sType) {
      var self = this;
      var app = self.$app;
      self.activityList = [];
      self.pageNo = 1;
      if (!!sType) {
        self.$setState({
          sortType: sType
        });
        var tInner = $$(".activityListTypeContent");
        tInner.scrollTo(0, 0, 200);
      }
      var arr = $.dataFind($.appData.activityData, {
        "status": "200"
      });
      arr.each(function (obj) {
        obj.uCount = obj.userarr.split(",").length;
        obj.formatTime = $.formatBeforeTime(obj.ctime);
      });
      var dic = {
        1: function () {
          arr = $.jsonSort(arr, "id", "desc");
        },
        2: function () {
          arr = $.jsonSort(arr, "uCount", "desc");
        }
      };
      dic[self.sortType]();
      if (!!self.searchText) {
        arr = $.dataFind(arr, {
          content: "%" + self.searchText + "%"
        });
      }
      // arr=[];
      self.$setState({
        showData: arr
      });
      if (self.showData.length == 0) {
        self.$setState({
          activityList: [],
          skeleton: false
        });
        return;
      }
      self.pageDataArr = $.sliceArrListData(self.showData, self.pageSize);
      var newArr = self.pageDataArr[self.pageNo + ""];
      var activityList = self.activityList.concat(newArr);
      self.$setState({
        activityList: activityList,
        skeleton: false
      }, function () {
        $.setImg(".activityListTypeContent", null, null, true, null);
      });

      // $.appData.activityData.each(function(obj){
      //     obj.img=obj.img.replace(/http:/g,"https:");
      //     $.appData.activityData.upd({
      //         id:obj.id,
      //         img:obj.img
      //     });
      // });
    }
  },

  on: {
    pageAfterIn: function () {
      var self = this;
      var app = self.$app;
      if (!$.appData.hasOwnProperty("activityData")) {
        var tm = window.setTimeout(function () {
          clearTimeout(tm);
          self.initData();
        }, 600);
      } else {
        self.renderData();
      }
      function clearSearch() {
        self.searchText = "";
        self.renderData();
      }
      $$(".activityTypeSearchbar").on("searchbar:clear", clearSearch).on("searchbar:disable", clearSearch);
      var $ptrContent = $$('.activityListTypeContent');
      $ptrContent.off("ptr:refresh").on("ptr:refresh", function (e) {
        setTimeout(function () {
          delete $.appData.activityData;
          delete $.appData.activityReplyData;
          $.initData(["activity"], function () {
            self.initData();
            $ptrContent[0].f7PullToRefresh.done();
          });
        }, 500);
      });
      $$('.activityListTypeContent').on('infinite', function () {
        if (!self.allowInfinite) return;
        self.allowInfinite = false;
        setTimeout(function () {
          self.allowInfinite = true;
          self.pageNo++;
          if (self.pageDataArr.hasOwnProperty(self.pageNo + "")) {
            var arr = self.pageDataArr[self.pageNo + ""];
            var activityList = self.activityList.concat(arr);
            self.$setState({
              activityList: activityList
            }, function () {
              $.setImg(".activityListTypeContent");
            });
          }
        }, 200);
      });
    }
  },
  id: 'c7c8b39ca9',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n	<div class="page activityMainPage" data-name="activityMain">\r\n		<div class="navbar">\r\n			<div class="navbar-bg"></div>\r\n			<div class="navbar-inner sliding">\r\n				<div class="left back">\r\n					<a href="#" class="link back">\r\n						<i class="icon icon-back"></i>\r\n					</a>\r\n				</div>\r\n				<div class="title">社区活动</div>\r\n				<div class="right">\r\n					<a class="link icon-only searchbar-enable" data-searchbar=".activityTypeSearchbar">\r\n						<i class="icon f7-icons if-not-md">search</i>\r\n						<i class="icon material-icons md-only">search</i>\r\n					</a>\r\n				</div>\r\n				<form class="searchbar searchbar-expandable activityTypeSearchbar searchbar-init" data-search-container=".activityListTypeContent" data-search-in=".item-title">\r\n					<div class="searchbar-inner">\r\n						<div class="searchbar-input-wrap">\r\n							<input type="search" placeholder="搜索" />\r\n							<i class="searchbar-icon"></i>\r\n							<span class="input-clear-button"></span>\r\n						</div>\r\n						<span class="searchbar-disable-button">取消</span>\r\n					</div>\r\n				</form>\r\n			</div>\r\n		</div>\r\n\r\n		<div class="fab fab-extended fab-center-bottom">\r\n			<a href="/addActivityPage/">\r\n				<i class="icon f7-icons">plus</i>\r\n				<div class="fab-text">发起活动</div>\r\n			</a>\r\n		</div>\r\n\r\n		<div class="activityListTypeMenuDiv elevation-3">\r\n			<div class="toolbar tabbar">\r\n				<div class="toolbar-inner">\r\n					<a @click="renderData(1)" class="link';
      r += Template7Helpers.js.call(ctx_1, 'this.sortType==1?" tab-link-active":""', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">最新发布</a>\r\n					<a @click="renderData(2)" class="link';
      r += Template7Helpers.js.call(ctx_1, 'this.sortType==2?" tab-link-active":""', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">最多参与</a>\r\n				</div>\r\n			</div>\r\n		</div>\r\n		<div class="page-content activityListTypeContent infinite-scroll-content ptr-content" data-ptr-distance="55"\r\n		 data-ptr-mousewheel="true">\r\n			<div class="ptr-preloader">\r\n				<div class="preloader color-multi"></div>\r\n				<div class="ptr-arrow"></div>\r\n			</div>\r\n			<div class="block block-strong no-hairlines">\r\n				';
      r += Template7Helpers.if.call(ctx_1, ctx_1.skeleton, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n					<div class="list media-list no-hairlines" style="margin-bottom: 0px;">\r\n						<ul>\r\n							';
          r += Template7Helpers.each.call(ctx_2, '1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n								<li>\r\n									<a href="#" class="item-link item-content">\r\n										<div class="item-media">\r\n											<div style="width: 80px;\r\n											height: 80px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n										</div>\r\n										<div class="item-inner">\r\n											<div class="item-title-row">\r\n												<div class="item-title">\r\n													<div style="width: 240px;\r\n											height: 24px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n												</div>\r\n												<div class="item-after"></div>\r\n											</div>\r\n											<div class="item-subtitle">\r\n												<div style="width: 180px;\r\n											height: 24px; \r\n											display: inline-block;" class="skeleton-block skeleton-effect-blink"></div>\r\n											</div>\r\n											<div class="item-text">\r\n												<div style="width: 280px;\r\n												height: 20px; \r\n												display: inline-block;margin-top: 6px;" class="skeleton-block skeleton-effect-blink"></div>\r\n											</div>\r\n										</div>\r\n									</a>\r\n								</li>\r\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n						</ul>\r\n					</div>\r\n				';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n					';
          r += Template7Helpers.js_if.call(ctx_2, "!!this.activityList&&this.showData.length==0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n						';
              r += c(ctx_3.$root.emptyTip("暂无活动"), ctx_3);
              r += '\r\n					';
              r += Template7Helpers.js_if.call(ctx_3, "this.img", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, "!this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, "this.showData.length>this.pageSize&&this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n						<div class="list media-list no-hairlines">\r\n							<ul>\r\n								';
              r += Template7Helpers.each.call(ctx_3, ctx_3.activityList, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n									<li>\r\n										<a href="/activityDetailsPage/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/" class="item-link item-content">\r\n											';
                  r += Template7Helpers.js_if.call(ctx_4, "this.img", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\r\n												<div class="item-media"><img url="';
                      r += c(ctx_5.img.split('|')[0], ctx_5);
                      r += '" width="80" /></div>\r\n											';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\r\n											<div class="item-inner">\r\n												<div class="item-title-row">\r\n													<div class="item-title">';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div>\r\n													<div class="item-after"></div>\r\n												</div>\r\n												<div class="item-text">';
                  r += c(ctx_4.content, ctx_4);
                  r += '</div>\r\n												<div class="item-subtitle">\r\n													<i class="material-icons">&#xe7fd;</i>';
                  r += c(ctx_4.uCount, ctx_4);
                  r += '\r\n													<span>\r\n														';
                  r += c(ctx_4.formatTime, ctx_4);
                  r += '\r\n													</span>\r\n												</div>\r\n												\r\n											</div>\r\n										</a>\r\n									</li>\r\n								';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n							</ul>\r\n							';
              r += Template7Helpers.js_if.call(ctx_3, "!this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n								<div class="baseline">没有更多了</div>\r\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n						</div>\r\n						';
              r += Template7Helpers.js_if.call(ctx_3, "this.showData.length>this.pageSize&&this.pageDataArr.hasOwnProperty((this.pageNo+1)+'')", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n							<div class="preloader infinite-scroll-preloader">\r\n						';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\r\n					';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n				';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n			</div>\r\n		</div>\r\n	</div>\r\n';
      return r;
    }(this);
  },
  style: `

	.activityMainPage .media-list .item-media img{
			border-radius: 10px;
		}
		
		.activityListMainMenuBlock{
			margin-top: 0px;
			margin-bottom: 0px;
			padding-top: 20px;
			padding-bottom: 20px;
			background-color: white;
		}
		.activityListTypeMenuDiv{
			width: 100%;
			position: relative;
			position: -webkit-sticky;
			position: sticky;
			left: 0;
			top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
			z-index: 999;
		}
		.activityListTypeMenuDiv .toolbar{
			background-color: white;
		}
		.activityMainPage .list{
			margin-top: 10px;
			margin-bottom: 0px;
		}
		
		.activityListTypeContent>.block{
			margin-top: 0px;
			padding-top: 0px;
		}
		
		.activityListTypeContent .list ul{
			background: initial;
		}
		
		.activityListTypeContent .item-subtitle{
			display: flex;
			align-items: center;
			position: relative;
		}
		
		.activityListTypeContent .item-subtitle i{
			color: #9fa1a2;
		}
		
		.activityListTypeContent .item-subtitle span{
			position: absolute;
			right: 0px;
		}
		
	`,
  styleScoped: false
};
    