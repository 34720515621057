export default {
  data: function () {
    return {
      userWallet: {
        money: "加载中...",
        integral: "加载中...",
        bonus: "加载中..."
      }
    };
  },
  methods: {},
  on: {
    pageInit: function () {
      var self = this;
      $.globalSignToken(function () {
        $.globalInitData(["userWallet"], function () {
          if ($.appData.userWalletData.length == 1) {
            self.$setState({
              userWallet: $.appData.userWalletData[0]
            });
          } else {
            self.$setState({
              userWallet: {
                money: "0",
                integral: "0",
                bonus: "0"
              }
            });
          }
        });
      });
    }
  },
  id: '7caf096c29',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=settings><style>.myMoneyDiv {\r\n				width: 100%;\r\n				height: 150px;\r\n			}\r\n\r\n			.myMoneyDiv>label {\r\n				font-size: 14px;\r\n				width: 100%;\r\n				display: block;\r\n				height: 60px;\r\n				line-height: 95px;\r\n				color: #333333;\r\n				text-align: center;\r\n			}\r\n\r\n			.myMoneyDiv>span {\r\n				font-size: 40px;\r\n				width: 100%;\r\n				display: block;\r\n				height: 60px;\r\n				line-height: 60px;\r\n				color: #4b4b4b;\r\n				text-align: center;\r\n				font-weight: bold;\r\n				font-family: monospace;\r\n			}</style><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i></a></div><div class=title>我的账户</div></div></div><div class=page-content><div class=myMoneyDiv><label>余额(元)</label> <span class=myWallet_money>';
      r += c(ctx_1.userWallet.money, ctx_1);
      r += '</span></div><div class=card><div class=card-content><div class="list inset"><ul><li style="color: #cdcdcd;"><a href=# class="item-link item-content"><!-- <div class="item-media myInfoListIconDiv">\r\n				  					<i class="material-icons md-only">error</i>\r\n				  					<i class="f7-icons if-not-md">exclamationmark_circle</i>\r\n				  				</div> --><div class=item-inner><div class=item-title>充值</div><div class=item-after></div></div></a></li><li style="color: #cdcdcd;"><a href=# class="item-link item-content"><div class=item-inner><div class=item-title>提现</div><div class=item-after></div></div></a></li><li><a href=/integralDetailsPage/ class="item-link item-content"><div class=item-inner><div class=item-title>积分</div><div class=item-after>';
      r += c(ctx_1.userWallet.integral, ctx_1);
      r += '</div></div></a></li><li><a href=/bonusDetailsPage/ class="item-link item-content"><div class=item-inner><div class=item-title>奖励金</div><div class=item-after>';
      r += c(ctx_1.userWallet.bonus, ctx_1);
      r += '</div></div></a></li><li style="color: #cdcdcd;"><a href=# class="item-link item-content"><div class=item-inner><div class=item-title>代金券</div><div class=item-after>0</div></div></a></li><li style="color: #cdcdcd;"><a href=# class="item-link item-content"><div class=item-inner><div class=item-title>会员卡</div><div class=item-after>0</div></div></a></li></ul></div></div></div></div></div>';
      return r;
    }(this);
  },
  style: `
			.myMoneyDiv {
				width: 100%;
				height: 150px;
			}

			.myMoneyDiv>label {
				font-size: 14px;
				width: 100%;
				display: block;
				height: 60px;
				line-height: 95px;
				color: #333333;
				text-align: center;
			}

			.myMoneyDiv>span {
				font-size: 40px;
				width: 100%;
				display: block;
				height: 60px;
				line-height: 60px;
				color: #4b4b4b;
				text-align: center;
				font-weight: bold;
				font-family: monospace;
			}
		`,
  styleScoped: false
};