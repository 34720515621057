import Framework7 from 'framework7/framework7.esm.bundle.js';
import $$ from 'dom7';
import ClipboardJS from '../../static/js/copy.js';
(function() {
	function isWX() {
		var ua = navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == "micromessenger") {
			return true;
		} else {
			return false;
		}
	}
	$.isWX = isWX();
	Array.prototype.indexOf = function(val) {
		for (var i = 0; i < this.length; i++) {
			if (this[i] == val) return i;
		}
		return -1;
	};
	Array.prototype.remove = function(dx) {
		if (isNaN(dx) || dx > this.length) {
			return false;
		}
		for (var i = 0, n = 0; i < this.length; i++) {
			if (this[i] != this[dx]) {
				this[n++] = this[i];
			}
		}
		if (this.length > 0) this.length -= 1;
	};

	Array.prototype.rem = function(k, v) {
		for (var i = 0; i < this.length; i++)
			if (this[i][k] == v)
				this.remove(i);
	};
	Array.prototype.take = function(k, v) {
		for (var i = 0; i < this.length; i++)
			if (this[i][k] == v) return this[i];
	};

	Array.prototype.takeIdx = function(k, v) {
		for (var i = 0; i < this.length; i++)
			if (this[i][k] == v) return i;
	};

	Array.prototype.each = function(fn) {
		for (var i = 0; i < this.length; i++)
			if (!!fn)
				fn.call(this, this[i], i);
	};
	Array.prototype.maxCol = function(col) {
		var arr = [];
		for (var i = 0; i < this.length; i++) {
			arr.push(parseInt(this[i][col]));
		}
		return Math.max.apply(null, arr);
	};
	Array.prototype.append = function(arr, mainCol) {
		var resultArr = this;
		arr.each(function(obj) {
			if (!resultArr.take(mainCol, obj[mainCol])) {
				resultArr.push(obj);
			}
		});
	};
	$.getTime = function(h) {
		var date = new Date();
		if (!!h)
			date.setTime(date.getTime() + h);
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		var d = date.getDate();
		var h = date.getHours();
		var mi = date.getMinutes();
		var s = date.getSeconds();
		return y + "-" + (m > 9 ? m : "0" + m) + "-" + (d > 9 ? d : "0" + d) + " " + (h > 9 ? h : "0" + h) + ":" + (mi > 9 ?
			mi : "0" + mi) + ":" + (s > 9 ? s : "0" + s);
	};
	$.getDate = function(h) {
		var date = new Date();
		if (!!h)
			date.setTime(date.getTime() + h);
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		var d = date.getDate();
		return y + "-" + (m > 9 ? m : "0" + m) + "-" + (d > 9 ? d : "0" + d);
	};

	function jsonSort(arr, attr, order) {
		var exeFn = null;
		if (arr.length == 0) return arr;
		var val = "";
		for (var i = 0; i < arr.length; i++) {
			var obj = arr[i];
			val = obj[attr];
			if (!!val || val == 0) break;
		}
		if (!val && val != 0) return;
		if (!isNaN(val)) {
			exeFn = function exeFn(a, b) {
				if (!a[attr]) return 1;
				if (!!order && order == "desc") return b[attr] - a[attr];
				else return a[attr] - b[attr];
			};
		} else if (testDate.test(val)) {
			exeFn = function exeFn(a, b) {
				if (!a[attr]) return 1;
				if (!!order && order == "desc") return timeBetween(b[attr], a[attr]);
				else return timeBetween(a[attr], b[attr]);
			};
		} else {
			exeFn = function exeFn(a, b) {
				if (!a[attr]) return 1;
				if (!!order && order == "desc") return a[attr].localeCompare(b[attr]) * -1;
				else return a[attr].localeCompare(b[attr]);
			};
		}
		var newArr = [].concat(arr).sort(exeFn);
		if (arr.hasOwnProperty("add")) newArr.add = arr.add;
		if (arr.hasOwnProperty("upd")) newArr.upd = arr.upd;
		if (arr.hasOwnProperty("del")) newArr.del = arr.del;
		return newArr;
	}

	function timeBetween(time1, time2) {
		var date1 = new Date(Date.parse(time1.replace(/-/g, "/")));
		date1 = date1.getTime();
		var date2 = new Date(Date.parse(time2.replace(/-/g, "/")));
		date2 = date2.getTime();
		return date1 - date2;
	}
	$.jsonSort = jsonSort;
	$.timeBetween = timeBetween;
	var testDate = /^\d{4}-(?:0\d|1[0-2])-(?:[0-2]\d|3[01])( (?:[01]\d|2[0-3])\:[0-5]\d\:[0-5]\d)?$/;

	function dataFind(arr, option) {
		var newList = [];
		for (var i in arr) {
			if (!arr.hasOwnProperty(i)) continue;
			var obj = arr[i];
			var bol = true;
			for (var k in option) {
				var key = k;
				var val = option[k] + "";
				var isToUpperCase = false;
				var isReverse = false;
				if (!!val)
					if (val.indexOf("!") == 0) {
						isReverse = true;
						val = val.substring(1);
					}
				if (!!val)
					if (val.indexOf("@") == 0) {
						isToUpperCase = true;
						val = val.substring(1);
					}
				if (!!val && val.indexOf("&") > 0) {
					var dateArr = val.split("&");
					if (dateArr.length == 2) {
						var startDate = dateArr[0];
						var endDate = dateArr[1];
						if (testDate.test(startDate) && testDate.test(endDate) && !!obj[key] && testDate.test(obj[key])) {
							if (timeBetween(startDate, obj[key]) > 0 || timeBetween(endDate, obj[key]) < 0) bol = false;
							else continue;
						} else {
							if (!obj[key]) bol = false;
						}
					}
				}
				if (!!val && val.indexOf("-") > 0) {
					var dataArr = val.split("-");
					if (dataArr.length == 2) {
						var startNum = dataArr[0];
						var endNum = dataArr[1];
						if (!isNaN(startNum) && !isNaN(endNum) && !!obj[key] && !isNaN(obj[key])) {
							try {
								var objNum = parseFloat(obj[key]);
								startNum = parseFloat(startNum);
								endNum = parseFloat(endNum);
								if (startNum <= objNum && objNum <= endNum) continue;
								else bol = false;
							} catch (e) {}
						} else {
							if (!obj[key]) bol = false;
						}
					}
				}
				if (isToUpperCase && isReverse) {
					if (!!val && val.indexOf("%") == 0 && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(1, val.length - 1);
						if (!obj[key] || obj[key].toUpperCase().indexOf(val.toUpperCase()) != -1) bol = false;
					} else if (!!val && val.indexOf("%") == 0) {
						val = val.substring(1);
						if (!obj[key] || obj[key].toUpperCase().indexOf(val.toUpperCase()) == 0) bol = false;
					} else if (!!val && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(0, val.length - 1);
						if (!obj[key] || obj[key].toUpperCase().lastIndexOf(val.toUpperCase()) == obj[key].length - 1) bol = false;
					} else {
						if (!!val)
							if (!obj[key] || obj[key].toUpperCase() == val.toUpperCase()) bol = false;
					}
				} else if (isToUpperCase) {
					if (!!val && val.indexOf("%") == 0 && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(1, val.length - 1);
						if (!obj[key] || obj[key].toUpperCase().indexOf(val.toUpperCase()) == -1) bol = false;
					} else if (!!val && val.indexOf("%") == 0) {
						val = val.substring(1);
						if (!obj[key] || obj[key].toUpperCase().indexOf(val.toUpperCase()) != 0) bol = false;
					} else if (!!val && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(0, val.length - 1);
						if (!obj[key] || obj[key].toUpperCase().lastIndexOf(val.toUpperCase()) != obj[key].length - val.length) bol =
							false;
					} else {
						if (!!val)
							if (!obj[key] || obj[key].toUpperCase() != val.toUpperCase()) bol = false;
					}
				} else if (isReverse) {
					if (!!val && val.indexOf("%") == 0 && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(1, val.length - 1);
						if (!obj[key] || obj[key].indexOf(val) != -1) bol = false;
					} else if (!!val && val.indexOf("%") == 0) {
						val = val.substring(1);
						if (!obj[key] || obj[key].indexOf(val) == 0) bol = false;
					} else if (!!val && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(0, val.length - 1);
						if (!obj[key] || obj[key].lastIndexOf(val) == obj[key].length - 1) bol = false;
					} else {
						if (!!val)
							if (!obj[key] || obj[key] == val) bol = false;
					}
				} else {
					if (!!val && val.indexOf("%") == 0 && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(1, val.length - 1);
						if (!obj[key] || obj[key].indexOf(val) == -1) bol = false;
					} else if (!!val && val.indexOf("%") == 0) {
						val = val.substring(1);
						if (!obj[key] || obj[key].indexOf(val) != 0) bol = false;
					} else if (!!val && val.lastIndexOf("%") == val.length - 1) {
						val = val.substring(0, val.length - 1);
						if (!obj[key] || obj[key].lastIndexOf(val) != obj[key].length - val.length) bol = false;
					} else {
						if (!!val)
							if (!obj[key] && obj[key] != "0" || obj[key] != val) bol = false;
					}
				}
			}
			if (bol) newList.push(obj);
		}
		return newList;
	}
	$.dataFind = dataFind;

	function sliceArrListData(arr, count) {
		var pageDataList = {};
		var pageIdx = 1;
		var start = 0;
		for (var i = 0; i < arr.length; i++) {
			if (i % count == 0 && i != 0) {
				pageDataList[pageIdx] = arr.slice(start, i);
				start = i;
				pageIdx++;
			}
			if (arr.length - 1 == i) {
				pageDataList[pageIdx] = arr.slice(start, arr.length);
				start = i;
				pageIdx++;
			}
		}
		return pageDataList;
	}
	$.sliceArrListData = sliceArrListData;
	
	$.dataFindArr = function (arr, col, cArr) {
		var newArr = [];
		for (var i in arr) {
			if (arr.hasOwnProperty(i) && !isNaN(i)) {
				var obj = arr[i];
				if (cArr.indexOf(obj[col]) > -1) {
					newArr.push(obj);
				}
			}
		}
		return newArr;
	};

	function loadImg(src, fn, w, h, type) { //七牛缩放
		if (w != undefined) {
			if (+w > 0 || +h > 0) {
				src = src + "?imageView2/" + (type || 1) + "/";
				if (+w > 0) {
					src = src + "w/" + (~~w * 2) + "/";
				}
				if (+h > 0) {
					src = src + "h/" + (~~h * 2) + "/";
				}
				src = src + "q/90";
			} else {
				src = src + "?imageView2/0/format/jpg|imageslim";
			}
		}
		let xhr = new XMLHttpRequest();
		xhr.open("GET", src, true);
		xhr.responseType = 'blob';
		xhr.onload = function(e) {
			if (this.status == 200) {
				if (!!fn) fn(this.response);
			}
		};
		xhr.send();
	}

	$.setImg = function(selector, fn, isLoadYT, qzRefresh, mode) {
		$$(selector).find("img").each(function() {
			var _this = $$(this);
			if (!qzRefresh) {
				if (!!_this.attr("src")) return;
			}
			var url = _this.attr("url");
			if (!url) return;
			if (url.indexOf("static.myshopvip.cn") == -1)
				return _this.attr("src", url);
			if (!!url && url.indexOf("{{") > -1) return;
			if (!!url) {
				var hzMat = url.match(/[^\.](\w+)$/);
				var hz = "jpg";
				if (!!hzMat)
					hz = hzMat[0];
				var w = 0;
				var h = 0;
				if (!isLoadYT) {
					w = _this.width();
					h = _this.height();
				}
				var urlName = url;
				if (+w > 0 || ~~h > 0) {
					var u = "?";
					var ar = [];
					if (+w > 0) {
						ar.push("w=" + w);
					}
					if (+h > 0) {
						ar.push("h=" + h);
					}
					urlName = url + u + ar.join("&");
				}
				getHTML(urlName, function(src) {
					if (!!src) {
						if (!!fn) fn(_this);
						if (!_this.attr("src") || !!qzRefresh)
							_this.attr("src", window.URL.createObjectURL(src));
					} else {
						loadImg(url, function(data) {
							if (!!fn) fn(_this);
							if (!qzRefresh) {
								if (!!_this.attr("src")) {
									addHTML(urlName, data);
									return;
								}
							}
							_this.attr("src", window.URL.createObjectURL(data));
							addHTML(urlName, data);
						}, w, h, mode);
					}
				});
			}
		});
	};

	$.waitObj = function(obj, val, fn) {
		if (obj[val] == undefined || obj[val] == null) {
			if (obj.hasOwnProperty("__waitObj__fnArr")) {
				obj.__waitObj__fnArr.push(fn);
			} else {
				obj.__waitObj__fnArr = [fn];
			}
			Object.defineProperty(obj, val, {
				configurable: true,
				get: function() {
					return this["__" + val];
				},
				set: function(value) {
					this["__" + val] = value;
					Object.defineProperty(obj, val, {
						get: function() {
							return this["__" + val];
						},
						set: function(value) {
							this["__" + val] = value;
						}
					});
					var fnArr = this.__waitObj__fnArr;
					for (var i = 0; i < fnArr.length; i++) {
						fnArr[i]();
					}
					delete this.__waitObj__fnArr;
				}
			});
		} else {
			if (!!fn) fn();
		}



		// var tm=setTimeout(function(){
		// 	clearTimeout(tm);
		// 	
		// 	if(typeof(val)=="function"){
		// 		fn=val;
		// 		if(obj==undefined||obj==null)
		// 			$.waitObj(obj,fn);
		// 		else
		// 			if(!!fn) fn();
		// 	}else{
		// 		console.log(val);
		// 		if(obj[val]==undefined||obj[val]==null)
		// 			$.waitObj(obj,val,fn);
		// 		else
		// 			if(!!fn) fn();
		// 	}
		// },0);
	}

	$.loadOneImg = function(url, fn) {
		if (url.indexOf("static.myshopvip.cn") == -1) {
			if (!!fn)
				fn(url);
			return;
		}
		getHTML(url, function(imgData) {
			if (!!imgData) {
				if (!!fn)
					fn(window.URL.createObjectURL(imgData));
			} else {
				loadImg(url, function(imgData) {
					var tm = setTimeout(function() {
						clearTimeout(tm);
						addHTML(url, imgData);
					}, 0);
					if (!!fn)
						fn(window.URL.createObjectURL(imgData));
				});
			}
		});
	}

	$.loadLookImg = function(url, fn) {
		getHTML(url, function(imgData) {
			if (!!imgData) {
				if (!!fn)
					fn(window.URL.createObjectURL(imgData));
			} else {
				if (!!fn)
					fn(url);
				loadImg(url, function(imgData) {
					var tm = setTimeout(function() {
						clearTimeout(tm);
						addHTML(url, imgData);
					}, 0);
				});
			}
		});
	}
	$.copyText = function(className, text, fn, event) {
		if (!!ClipboardJS) {
			if (ClipboardJS.isSupported()) {
				var btn = document.querySelector(className);
				if (!!btn) {
					btn.setAttribute("data-clipboard-action", "copy");
					btn.setAttribute("data-clipboard-target", "#copyFoo");
					text=text.replace(/<br *\/*>/g,"\n").replace(/<\/div>/g,"").replace(/<div>/g,"\n").replace(/&nbsp;/g," ");
					document.getElementById("copyFoo").value = text;
					var clipboard = new ClipboardJS(className);
					clipboard.on('success', function(e) {
						if (!!fn)
							fn();
						clipboard.off("success");
						clipboard.off("error");
					});
					clipboard.on('error', function(e) {
						console.log("复制失败！");
						clipboard.off("success");
						clipboard.off("error");
					});
					if (!!event)
						clipboard.onClick(event);
				}

			}
		}
	}

	$.imgRepairIOS = function(file, fn) {
		var orientation = null;
		var ctime = null;
		function rotateImg(img, direction, canvas ,fn) {
			var min_step = 0;
			var max_step = 3;
			if (img == null) return;
			var height = img.height;
			var width = img.width;
			var step = 2;
			if (step == null) {
				step = min_step;
			}
			if (direction == 'right') {
				step=3;
			} else if(direction == 'left') {
				step=1;
			} else {
				step=0;
			}
			var degree = step * 90 * Math.PI / 180;
			var ctx = canvas.getContext('2d');
			switch (step) {
				case 0:
					canvas.width = width;
					canvas.height = height;
					ctx.drawImage(img, 0, 0);
					break;
				case 1:
					canvas.width = height;
					canvas.height = width;
					ctx.rotate(degree);
					ctx.drawImage(img, 0, -height);
					break;
				case 2:
					canvas.width = width;
					canvas.height = height;
					ctx.rotate(degree);
					ctx.drawImage(img, -width, -height);
					break;
				case 3:
					canvas.width = height;
					canvas.height = width;
					ctx.rotate(degree);
					ctx.drawImage(img, -width, 0);
					break;
			}
			if(!!fn)
				fn();
		}
		if (file) {
			getOrientation(file,function(orientation){
				var oReader = new FileReader();
				oReader.onload = function(e) {
					var image = new Image();
					image.src = e.target.result;
					image.onload = function() {
						var that = this;
						var expectWidth = this.naturalWidth;
						var expectHeight = this.naturalHeight;
						var canvas = document.createElement("canvas");
						var ctx = canvas.getContext("2d");
						canvas.width = expectWidth;
						canvas.height = expectHeight;
						ctx.drawImage(this, 0, 0, expectWidth, expectHeight);
						var blobData = null;
						if (orientation != "" && orientation != 1) {
							switch (orientation) {
								case 6:
									//需要顺时针（向左）90度旋转   
									rotateImg(this, 'left', canvas);
									break;
								case 8:
									//需要逆时针（向右）90度旋转   
									rotateImg(this, 'right', canvas);
									break;
								case 3:
									//需要180度旋转  
									rotateImg(this, 'bottom', canvas);
									break;
							}
						}
						canvas.toBlob(fn, "image/jpeg");
					};
				};
				oReader.readAsDataURL(file);
			});
			
		}
	};
	
	function getOrientation(file, callback) {
		var reader = new FileReader()
		reader.onload = function(e) {
			var view = new DataView(e.target.result)
			if (view.getUint16(0, false) != 0xFFD8) return callback(-2)
			var length = view.byteLength, offset = 2
			while (offset < length) {
				var marker = view.getUint16(offset, false)
				offset += 2
				if (marker == 0xFFE1) {
					if (view.getUint32(offset += 2, false) != 0x45786966) return callback(-1)
					var little = view.getUint16(offset += 6, false) == 0x4949
					offset += view.getUint32(offset + 4, little)
					var tags = view.getUint16(offset, little)
					offset += 2
					for (var i = 0; i < tags; i++)
						if (view.getUint16(offset + (i * 12), little) == 0x0112)
							return callback(view.getUint16(offset + (i * 12) + 8, little))
				}
				else if ((marker & 0xFF00) != 0xFF00) break
				else offset += view.getUint16(offset, false)
			}
			return callback(-1)
		};
		reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
	}

	function distinctCol(arr, col) {
		var dic = {};
		for (var i in arr) {
			if (arr.hasOwnProperty(i) && !isNaN(i)) dic[arr[i][col]] = "";
		}
		var res = [];
		for (var i in dic) {
			if (dic.hasOwnProperty(i)) res.push(i);
		}
		return res;
	}
	$.distinctCol = distinctCol;

	$.filterHTMLContent = function(str) {
		var strArr = str.match(/<[a-zA-Z] .+<\/[a-zA-Z]*>|<[a-zA-Z]* [^>]+[\/]?>/g);
		var strDic = {};
		if (!!strArr) {
			for (var i = 0; i < strArr.length; i++) {
				strDic["@#$%" + i + "%$#@"] = strArr[i];
				str = str.replace(strArr[i], "@#$%" + i + "%$#@");
			}
		}
		str = str.replace(/^\n/g, "").replace(/\n$/g, "").replace(/ /g, "&nbsp;").replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
			.replace(/\n/g, "<br />");
		for (var i in strDic) {
			if (strDic.hasOwnProperty(i)) {
				str = str.replace(i, strDic[i]);
			}
		}
		return str;
	}

	$.formatBeforeTime = function(argsDate) {
		var dateTime = "";
		var dTime = "";
		var hTime = "";
		var mTime = "";
		var time = "";
		var timec = $.timeBetween($.getTime(), argsDate);
		var mTime = Math.floor((timec / 1000) / 60);
		if (mTime > 60)
			hTime = Math.floor(mTime / 60);
		if (hTime > 24)
			dTime = Math.floor(hTime / 24);
		if (dTime > 3)
			dateTime = argsDate.split(" ")[0].replace("-", "年").replace("-", "月") + "日";
		if (!!dateTime)
			time = dateTime;
		else if (!!dTime && dTime == 1)
			time = "昨天";
		else if (!!dTime)
			time = dTime + "天前";
		else if (!!hTime)
			time = hTime + "小时前";
		else if (!!mTime)
			time = mTime + "分钟前";
		if (!time && mTime == 0) {
			time = "刚刚";
		}
		return time;
	}

})();
